<script setup>
import store from "@/store";
import { computed } from "vue";

const props = defineProps({
  deviceType: {
    type: String,
    default: "ios",
  },
  imgName: {
    type: String,
    required: true,
  },
  instructions: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(["updateDeviceType"]);

const isDarkMode = computed(() => {
  return store.getters["colorScheme/isDarkMode"];
});

const imgSuffix = computed(() => {
  return isDarkMode.value ? "-dark" : "-light";
});
</script>
<template>
  <div class="device-settings">
    <div class="selection-row">
      <div
        class="selection"
        :class="{ selected: props.deviceType === 'ios' }"
        @click="emit('updateDeviceType', 'ios')"
      >
        iOS
      </div>
      <div
        class="selection"
        :class="{ selected: props.deviceType === 'android' }"
        @click="emit('updateDeviceType', 'android')"
      >
        Android
      </div>
    </div>
    <div v-if="props.instructions" class="instructions">
      {{ props.instructions }}
    </div>
    <img
      class="phone-image"
      :src="require(`@/assets/images/esim/${props.imgName}${imgSuffix}.png`)"
      :key="props.imgName"
    />
  </div>
</template>
<style lang="scss" scoped>
.device-settings {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  background-color: rgba($color-primary-100-light, 0.2);
  @at-root .theme-dark & {
    background-color: rgba($color-primary-100-dark, 0.2);
  }
  width: 100%;
  max-width: 450px;
  transition: all 0.3s ease;
  color: $color-primary-50;
  margin-top: 24px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.2px;
  .selection-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-bottom: 1px solid transparent;
    transition: all 0.3s ease;
    width: 100%;
    height: 40px;
    cursor: pointer;
    .selection {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      &.selected {
        color: $color-primary-100;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.2px;
        border-bottom: 1px solid $color-primary-100;
        transition: all 0.3s ease;
      }
    }
  }
  .instructions {
    color: $color-primary-100;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.1px;
    padding: 18px 24px 0 24px;
    white-space: pre-wrap;
  }
  .phone-image {
    margin-top: 24px;
  }
}
</style>
