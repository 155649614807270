<script setup>
import {
  computed,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import moment from "moment/moment";
import DataDeleteService from "@/api/actions/data-delete-service.js";
import router from "@/routes/router";
import inlineSvg from "@/components/InlineSvg.vue";
import store from "@/store";
import { PH_EVENT_USER_CLICKED_DATA_DELETION_STATUS_IN_HOME_PAGE_TILE } from "@/scripts/posthogEvents";
import { phone } from "@/scripts/validation";
import AtomHomeTitle from "@/components/Atoms/AtomHomePageBanner/AtomHomeTitle.vue";
import AtomHomeBannerBody from "@/components/Atoms/AtomHomePageBanner/AtomHomeBannerBody.vue";
import HomeDataRemovalSubtitle from "@/components/home/HomeDataRemovalSubtitle.vue";
import HomeDataRemovalFooter from "@/components/home/HomeDataRemovalFooter.vue";
import HomeDataRemovalBox from "@/components/home/HomeDataRemovalBox.vue";
import {
  DATA_DELETE_REQUESTED,
  DATA_DELETE_IN_APP_REQUESTED,
  DATA_DELETE_SEARCHED_EXPOSURES,
} from "@/scripts/userFlags";
import UserService from "@/api/actions/user-service";
import { posthogCapture } from "@/scripts/posthog.js";
import { MONTHS } from "@/scripts/constants";
import DataRemovalSkeletonLoader from "@/routes/DataDeletion/components/DataRemovalSkeletonLoader.vue";
import { format } from "@/scripts";

const inputRef = ref();
const topSectionRef = ref();

const hasSearchedExposures = computed(() => {
  return store.getters.getFlag(DATA_DELETE_SEARCHED_EXPOSURES);
});

const enrollmentDataFetched = computed(() => {
  return store.getters["dataDelete/enrollmentData"]?.enrollmentDataFetched;
});

const state = reactive({
  input: "",
  sending: false,
  loading: enrollmentDataFetched.value ? false : true,
});

onMounted(() => {
  DataDeleteService.getEnrollmentData()
    .then(() => {
      state.loading = false;
    })
    .catch(() => {
      state.loading = false;
    });
  window.addEventListener("focus-data-deletion-input", focusInput);
});

onBeforeUnmount(() => {
  window.removeEventListener("focus-data-deletion-input", focusInput);
});

const brokersInProgress = computed(() => {
  return store.getters["dataDelete/enrollmentData"]?.brokersInProgress ?? 0;
});

const totalItemsRemoved = computed(() => {
  const itemsRemoved =
    store.getters["dataDelete/enrollmentData"]?.totalItemsRemoved ?? 0;
  return format.formatNumbersBasedonLocale(itemsRemoved);
});

const totalItemsRemovedToday = computed(() => {
  const itemsRemovedToday =
    store.getters["dataDelete/enrollmentData"]?.totalItemsRemovedToday ?? 0;
  return format.formatNumbersBasedonLocale(itemsRemovedToday);
});

const brokersCompleted = computed(() => {
  return store.getters["dataDelete/enrollmentData"]?.brokersCompleted ?? 0;
});

const scanUpdatedDate = computed(() => {
  return (
    store.getters["dataDelete/enrollmentData"]?.scanUpdatedDate ?? new Date()
  );
});
const isComplete = computed(() => {
  return store.getters["dataDelete/enrollmentData"]?.isComplete ?? false;
});
const scanningSites = computed(() => {
  return store.getters["dataDelete/enrollmentData"]?.scanningSites ?? 0;
});

const daysProtected = computed(() => {
  return store.getters["dataDelete/enrollmentData"]?.daysProtected ?? 0;
});
const hasScan = computed(() => {
  return (
    store.getters["dataDelete/enrollmentData"]?.enrollmentDataFetched ?? false
  );
});

const nextScanDate = computed(() => {
  const nextScanDateTime =
    store.getters["dataDelete/enrollmentData"]?.nextScanDate;
  if (nextScanDateTime) {
    const nextScan = new Date(nextScanDateTime);
    return `${MONTHS[nextScan.getMonth()]} ${nextScan.getDate()}`;
  }

  return null;
});

const hasAllScanData = computed(() => {
  return [
    hasScan.value,
    brokersInProgress.value,
    brokersCompleted.value,
    scanUpdatedDate.value,
    scanningSites.value,
    totalItemsRemoved.value,
    totalItemsRemovedToday.value,
    nextScanDate.value,
    daysProtected.value,
  ].every((data) => data === 0 || !!data);
});

const statusText = computed(() => {
  return isComplete.value ? "Data monitoring is on" : "In progress";
});

const formattedDate = computed(() => {
  return moment(scanUpdatedDate.value).format("dddd, MMMM Do");
});

const formattedTime = computed(() => {
  return moment(scanUpdatedDate.value).format("LT");
});

const subheaderText = computed(() => {
  return isComplete.value
    ? `Cloaked automatically monitors all ${scanningSites.value} data broker sites for any new records and actively requests to have them removed.`
    : `Status updated ${formattedDate.value} at ${formattedTime.value}.`;
});

const validPhone = computed(() => {
  if (state.input) {
    return phone(state.input);
  }
  return false;
});

function navToStatusPage() {
  posthogCapture(PH_EVENT_USER_CLICKED_DATA_DELETION_STATUS_IN_HOME_PAGE_TILE);
  router.push({ name: "DataRemove" });
}

function submitPhone() {
  UserService.setFlag({
    name: DATA_DELETE_IN_APP_REQUESTED,
    value: true,
  });
  if (validPhone.value) {
    router.push({ name: "DataDeleteUser", query: { phone: state.input } });
  }
}

function submitPromptUserToPay() {
  const goToDataRemoval = () => {
    UserService.setFlag({
      name: DATA_DELETE_REQUESTED,
      value: true,
    });
  };

  if (store.getters["settings/isSubscribed"]) {
    goToDataRemoval();
  } else {
    store.dispatch("subscription/openSubscriptionModal");

    const unwatch = watch(
      () => store.getters["settings/isSubscribed"],
      (isSubscribed) => {
        if (isSubscribed) {
          goToDataRemoval();
          unwatch();
        }
      }
    );
  }
}

function focusInput() {
  topSectionRef?.value?.scrollIntoView({
    behavior: "smooth",
    inline: "start",
    block: "end",
  });
  setTimeout(() => {
    inputRef?.value?.focus();
  }, 1000);
}
</script>
<template>
  <section ref="topSectionRef">
    <DataRemovalSkeletonLoader v-if="state.loading" small />
    <div class="widget-wrapper" v-else-if="hasAllScanData">
      <AtomHomeTitle title="Data Removal">
        <div @click="navToStatusPage" class="title-link">
          View full analytics
          <inlineSvg name="data-delete/graph" />
        </div>
      </AtomHomeTitle>
      <div class="completed-body-section" @click="navToStatusPage">
        <h2 class="header">
          {{ statusText
          }}<inlineSvg
            :name="isComplete ? 'fuzzy-green-dot' : 'fuzzy-orange-dot'"
            :key="isComplete ? 'fuzzy-green-dot' : 'fuzzy-orange-dot'"
          />
        </h2>
        <p class="subheader">{{ subheaderText }}</p>
        <div class="flex-row progress-section">
          <HomeDataRemovalBox :status="isComplete ? 'complete' : 'in-progress'">
            <template #number
              ><div class="color-success">
                {{ totalItemsRemoved }}
              </div></template
            >
            <template #bubble v-if="totalItemsRemovedToday > 0"
              ><div>{{ `+ ${totalItemsRemovedToday} Today` }}</div>
            </template>
            <template #label><div>Records removed</div></template>
          </HomeDataRemovalBox>
          <HomeDataRemovalBox>
            <template #number
              ><div>
                {{ nextScanDate }}
              </div></template
            >
            <template #label><div>Next Scan</div></template>
          </HomeDataRemovalBox>
        </div>
      </div>
    </div>

    <div class="widget-wrapper" v-else>
      <AtomHomeTitle title="Data Removal">
        <div class="bubble">New!</div>
      </AtomHomeTitle>

      <AtomHomeBannerBody>
        <div>
          <HomeDataRemovalSubtitle
            header="Uncover your identity risk"
            subheader="Data brokers sell your personal info to anyone for a price. Input your
          number to check your threat level."
          />

          <button
            class="enroll-button"
            @click="submitPromptUserToPay"
            v-if="hasSearchedExposures"
          >
            Enroll in data removal >
          </button>

          <HomeDataRemovalFooter
            :disabled="!validPhone || state.sending"
            @submit="submitPhone"
          >
            <div class="input-wrapper">
              <inlineSvg name="search" />
              <input
                type="phone"
                ref="inputRef"
                v-model="state.input"
                @keyup.enter="submitPhone"
                placeholder="Scan your phone number"
              />
            </div>
          </HomeDataRemovalFooter>
        </div>
      </AtomHomeBannerBody>
    </div>
  </section>
</template>

<style scoped lang="scss">
.input-wrapper {
  position: relative;
  width: auto;
  flex-grow: 1;
  color: $color-primary-100;

  svg {
    position: absolute;
    height: 19px;
    width: 19px;
    top: 50%;
    left: 24px;
    transform: translate(-50%, -50%);
  }
  input {
    padding: 16px;
    padding-left: 42px;
    width: 100%;
    border-radius: 56px;
    border: 2px solid $color-primary-10;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.2px;
    outline-color: $color-primary-100;
    background-color: $color-surface;
    caret-color: $color-primary-100;
    color: $color-primary-100;
  }
}

.enroll-button {
  background-color: transparent;
  border: none;
  color: $color-primary-100;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.1px;
  text-decoration-line: underline;
  margin-top: 8px;
  padding: 0;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.8;
    transition: all 0.3s ease;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.title {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.5px;
  color: $color-primary-100;
}
.title-link {
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.1px;
  text-decoration-line: underline;
  color: $color-primary-100;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);

  svg {
    color: $color-primary-100;
    height: 15px;
    width: auto;
  }

  &:hover {
    transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
    opacity: 0.7;
    transform: scale(1.01);
  }
}
.completed-body-section {
  color: $color-primary-100;
  cursor: pointer;
  margin-top: 16px;
  border-radius: 12px;
  padding: 24px;
  border: 1px solid $color-primary-10;
  transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
  &:hover {
    transform: scale(1.007) translate3d(0, 0, 0);
    transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
  }
  &:active {
    transform: scale(1) translate3d(0, 0, 0);
    transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
  }
  .header {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    justify-content: flex-start;
  }
  .subheader {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .progress-section {
    gap: 12px;
    margin-top: 16px;
  }
}

.in-progress {
  color: $color-in-progress;
}

.color-success {
  color: $color-success;
}

.blue {
  color: $color-brand-3-100;
}

.bubble {
  display: flex;
  padding: 1px 6px;
  align-items: center;
  background-color: $color-success;
  color: $color-primary-100-light;
  border-radius: 41px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.disabled {
  opacity: 0.4;
}

.personal-details {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.4px;
  color: $color-primary-100;
  margin-top: 16px;
}

.widget-wrapper {
  animation: fade-in 0.5s ease-in-out forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
