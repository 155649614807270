<script setup>
import CloaksList from "@/routes/your-cloaks/CloaksList";
import { reactive, computed } from "vue";
import store from "@/store";
import NoIdentities from "@/routes/your-cloaks/NoIdentities.vue";
import { cloakHelpers, search } from "@/scripts";
import { useRoute } from "@/hooks";
const route = useRoute();

const PAGE_SIZE = 30;

const state = reactive({
  page: 0,
  loading: false,
  filter: cloakHelpers.getFilterFromRoute(route),
});

const cloaks = computed(() => {
  return store.state.localdb.db_cloaks;
});

const allCloaks = computed(() => {
  if (cloaks.value) {
    return cloaks.value.filter((cloak) => cloak.favorited);
  }
  return [];
});

const identitySearch = computed(() => {
  return store.getters.getIdentitySearch;
});

const cloaksToShow = computed(() => {
  const cloaksFilteredByFilters = cloakHelpers.getAvailableCloakFilters({
    allCloaks: allCloaks.value,
    sort: sorted.value,
    filter: state.filter,
  });
  const cloaksFilteredBySearch = search.searchLocalCloaksSingleList(
    cloaksFilteredByFilters,
    identitySearch.value
  );

  return cloaksFilteredBySearch;
});

const identityList = computed(() => {
  const numToShow = state.page * PAGE_SIZE;
  let slicedCloakList = cloaksToShow.value.slice(0, numToShow);
  return slicedCloakList;
});

const allCloaksLoaded = computed(() => {
  return state.page * PAGE_SIZE >= cloaksToShow.value.length;
});

const availabileFilters = computed(() => {
  const matchingIdentityFilters = cloakHelpers.getAvailableIdentityFilters(
    allCloaks.value
  );
  return matchingIdentityFilters;
});

const sorted = computed(() => {
  return store.state?.ui?.identitySortType;
});

function loadNextPage() {
  state.loading = true;
  const newPage = state.page + 1;
  state.page = newPage;
  state.loading = false;
}

function setFilter(filter) {
  if (filter !== state.filter) {
    state.filter = filter;
  } else {
    state.filter = "";
  }
}
</script>

<template>
  <div>
    <NoIdentities
      v-if="!identityList.length && allCloaksLoaded"
      identity-type="favorite"
    />
    <CloaksList
      v-else
      ref="cloaklist"
      title="Favorites"
      :identityList="identityList"
      :allCloaksLoaded="allCloaksLoaded"
      :filterOptions="availabileFilters"
      @loadNextPage="loadNextPage"
      @filter="(e) => setFilter(e)"
    />
  </div>
</template>

<style lang="scss" scoped>
.container {
  padding-top: $ui-toolbar-height;
}
.empty-state {
  width: 100%;
  padding: 100px 20px 120px 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.full {
    height: 100vh;
    padding: 0;
    position: absolute;
    top: 0;
    left: 240px;
    width: calc(100% - 240px);
  }

  .content {
    max-width: 634px;
    text-align: center;
    display: inline-block;

    h1 {
      font-weight: 300;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: -0.5px;
      color: $color-primary-100-60;
      margin-top: 20px;
    }
  }
}

.title {
  padding: 0 36px;
  margin-bottom: 7px;
  margin-top: 24px;

  h1 {
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    letter-spacing: -0.5px;
    color: $color-primary-100;
  }
}
</style>
