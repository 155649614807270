<script setup>
import UiHeader from "@/components/feature/onboarding-new/UiHeader.vue";
import UiButton from "@/components/feature/onboarding-new/UiButton.vue";
import UiButtonRow from "@/components/feature/onboarding-new/UiButtonRow.vue";
import UiPageWrapper from "@/components/feature/onboarding-new/UiPageWrapper.vue";
import AtomInputInternalLabels from "@/components/Atoms/AtomInputInternalLabels.vue";
import InlineSvg from "@/components/InlineSvg";
import { PH_SCREEN_EVENT_ESIM_ACTIVATING_ESIM_SCREEN } from "@/scripts/posthogEvents";
import { format } from "@/scripts";
import { computed } from "vue";

const props = defineProps({
  newNumber: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["next"]);

const phoneFormatted = computed(() => {
  return format.phone_format(props.newNumber);
});
</script>
<template>
  <UiPageWrapper
    showLogo
    logo="cloaked-esim-logo"
    :screenEvent="PH_SCREEN_EVENT_ESIM_ACTIVATING_ESIM_SCREEN"
    :esimStep="5"
  >
    <UiHeader>
      <h1>Your eSIM has been sent to your device</h1>
      <h5>Tap the button below to continue.</h5>
      <AtomInputInternalLabels
        :value="phoneFormatted"
        label="Your eSim Number"
        type="text"
        :placeholder="phoneFormatted"
        readonly
        center
      />
      <InlineSvg name="esim-simcard-variant" />
    </UiHeader>
    <UiButtonRow>
      <UiButton gradient @click="emit('next')">Continue</UiButton>
    </UiButtonRow>
  </UiPageWrapper>
</template>
<style lang="scss" scoped></style>
