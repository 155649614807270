<script setup>
import { computed, onBeforeMount } from "vue";
import store from "@/store";
import { SubscriptionService } from "@/api";
import MenuItem from "@/components/Navigation/NavigationLeftPanel/MenuItem.vue";
import { isMobileDevice } from "@/scripts/regex";

onBeforeMount(() => {
  // NOTE: purposefully querying backend every time in case users subscription changes
  SubscriptionService.getSubscription();
});

const hasDDScan = computed(() => {
  return (
    store.getters["dataDelete/enrollmentData"]?.enrollmentDataFetched ?? false
  );
});

const items = computed(() => {
  let items = [
    {
      name: "Account",
      to: { name: "settings.account" },
    },
    {
      name: "Personal information",
      to: { name: "settings.personal-information" },
    },
    {
      name: "Forwarding",
      to: { name: "settings.forwarding" },
    },
  ];

  if (hasDDScan.value) {
    items.push({
      name: "Data Removal",
      to: { name: "settings.dataRemoval" },
    });
  }

  if (store.state.settings.subscription !== null) {
    items.push({
      name: "Subscription",
      to: { name: "settings.subscription" },
    });
  }
  if (
    store.state.authentication?.user?.encryption_status === 1 ||
    store.getters.isV2User
  ) {
    items.push({
      name: "Recovery kit",
      to: { name: "settings.recovery" },
    });
  }
  if (store.state.authentication?.user?.cloaked_card_kyc_configured === true) {
    items.push({
      name: "Cloaked Pay",
      to: { name: "settings.cloaked-cards" },
    });
  }
  if (store.state.authentication?.user?.flags?.email_custom_domain) {
    items.push({
      name: "Email",
      to: { name: "settings.email" },
    });
  }
  return items;
});
</script>
<template>
  <nav class="settings-navigation" :class="{ 'desktop-only': !isMobileDevice }">
    <div class="settings-navigation__items">
      <MenuItem
        v-for="(item, index) in items"
        :aria-id="`${item?.to?.name || ''}`"
        :key="index"
        :link="item.to"
        :name="item.name"
      />
    </div>
  </nav>
</template>

<style lang="scss">
.settings-navigation {
  background: $color-surface;
  border-right: 1px solid $color-primary-10;
  display: flex;
  flex-direction: column;
  max-width: 240px;
  flex: 1 0 0;
  position: absolute;
  top: -9999px;
  left: -9999px;
  visibility: hidden;
  opacity: 0;

  &.desktop-only {
    top: auto;
    left: auto;
    visibility: visible;
    opacity: 1;
    position: static;
  }

  &__items {
    flex: 1 1 0;
    padding: 40px 20px;
    gap: 6px;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
}
</style>
