<script setup>
import Button from "@/components/Button.vue";
import inlineSvg from "@/components/InlineSvg.vue";
import { ref, computed, onMounted, reactive, watch, nextTick } from "vue";
import store from "@/store";
import {
  PH_EVENT_USER_CLICKED_DATA_DELETION_VIEW_STATUS_IN_CLOAKED_APP_BUTTON,
  PH_FEATURE_FLAG_DD_SURVEY_VS_VIDEO,
  PH_SURVEY_DD_ONBOARDING_DEV,
  PH_SURVEY_DD_ONBOARDING_STG,
  PH_SURVEY_DD_ONBOARDING_PRD,
} from "@/scripts/posthogEvents";
import { useRoute } from "@/hooks";
import { isMobileDevice } from "@/scripts/regex";
import { DATA_DELETE_IN_APP_REQUESTED } from "@/scripts/userFlags";
import DataDeleteScheduleCallModal from "@/components/feature/data-delete/DataDeleteScheduleCallModal.vue";
import DataRemoveNotifyFamily from "@/components/feature/data-delete/DataRemoveNotifyFamily.vue";
import { useRelativesParsing } from "@/components/feature/data-delete/composables";
import { posthogCapture, getPosthog } from "@/scripts/posthog.js";
import OnboardingSurveyModal from "@/components/modals/data-delete/OnboardingSurveyModal.vue";

const props = defineProps({
  relatives: {
    type: Array,
    required: false,
    default: () => [],
  },
});

const survey = ref(null);
const showSurvey = ref(false);
const showVideo = ref(false);
const isVideoEnabled = ref(false);
const isSurveyEnabled = ref(false);

let posthog;

onMounted(async () => {
  posthog = await getPosthog();
  await posthog.getSurveys((surveys) => {
    survey.value = surveys.find((survey) =>
      [
        PH_SURVEY_DD_ONBOARDING_DEV,
        PH_SURVEY_DD_ONBOARDING_STG,
        PH_SURVEY_DD_ONBOARDING_PRD,
      ].includes(survey.id)
    );
  }, true);

  isVideoEnabled.value =
    posthog?.getFeatureFlag(PH_FEATURE_FLAG_DD_SURVEY_VS_VIDEO) ===
    "onboarding_video";
  isSurveyEnabled.value =
    posthog?.getFeatureFlag(PH_FEATURE_FLAG_DD_SURVEY_VS_VIDEO) ===
    "user_question";
});

const { partners, relatives } = useRelativesParsing(props.relatives);
const familyMembers = computed(() => [...partners.value, ...relatives.value]);

const route = useRoute();

const emit = defineEmits(["done"]);

const state = reactive({
  showScheduleModal: false,
});

const localData = sessionStorage.getItem("data-delete");
let local = null;
if (localData) {
  try {
    local = JSON.parse(localData);
  } catch (e) {
    local = null;
  }
}
const currentRequest = ref({
  name: local?.firstName ? `${local?.firstName} ${local?.lastName}` : "",
  age: local?.age || "",
  email: local?.email || "",
  phone: local?.phone || "",
  city: (local?.locations && local?.locations[0]?.city) || "",
  state: (local?.locations && local?.locations[0]?.state?.abbreviation) || "",
  searchDate: local?.searchDate || new Date(),
});

const isInsideDashboard = computed(() => {
  return route?.meta?.inside;
});

const formattedName = computed(() => {
  if (currentRequest.value.name) {
    return currentRequest.value.name?.trim().toLowerCase();
  }
  return "";
});

const ddScheduleCallEnabled = computed(
  () => store.getters["dataDelete/getDdScheduleCallEnabled"]
);

const isInAppSearch = computed(() =>
  store.getters.getFlag(DATA_DELETE_IN_APP_REQUESTED)
);

function linkToMobile() {
  emit("done");
  if (isMobileDevice) {
    posthogCapture(
      PH_EVENT_USER_CLICKED_DATA_DELETION_VIEW_STATUS_IN_CLOAKED_APP_BUTTON
    );
    window.open("https://try.cloaked.app/vAk1/sgws5hsy", "_blank");
  }
}

async function openModal() {
  if (!isInAppSearch.value && ddScheduleCallEnabled.value) {
    state.showScheduleModal = true;
  } else {
    linkToMobile();
  }
}

function linkToSupport() {
  window.open("https://help.cloaked.app/hc/en-us", "_blank");
}

function closeSurvey() {
  showSurvey.value = false;
}

watch(
  () => survey.value,
  (value) => {
    if (value && !isInAppSearch.value && isSurveyEnabled.value) {
      showSurvey.value = true;
    }
  },
  { immediate: true, deep: true }
);

const unwatchVideoDisplay = watch(
  () => isVideoEnabled.value,
  (value) => {
    if (value && !isInAppSearch.value) {
      showVideo.value = true;
      nextTick(() => unwatchVideoDisplay());
    }
  },
  { immediate: true, deep: true }
);
</script>

<template>
  <div class="data-remove-submitted">
    <DataDeleteScheduleCallModal
      v-show="state.showScheduleModal"
      @done="linkToMobile"
      @closeModal="state.showScheduleModal = false"
    />
    <OnboardingSurveyModal
      v-if="showSurvey"
      :survey="survey"
      @close="closeSurvey"
    />
    <h1 class="data-remove__title">Request submitted</h1>
    <p class="data-remove__text">
      We've received your removal request. You'll be notified when it's
      complete. Most removals are completed within 2 weeks.
    </p>

    <div class="data-remove-card-box">
      <div class="data-remove-card-box__content">
        <div class="data-remove-card-box__name" v-if="!!formattedName">
          <span>{{ formattedName }}</span>
        </div>
        <div
          class="data-remove-card-box__status"
          :class="`data-remove-card-box__status--submitted`"
        >
          Successfully submitted
        </div>
      </div>
    </div>

    <div
      :class="{ mobile: isMobileDevice }"
      class="width-100"
      v-if="!isInsideDashboard"
    >
      <Button
        type="primary"
        size="2xl"
        class="data-remove-button"
        @click="openModal"
      >
        {{ isMobileDevice ? "Open in the Cloaked app" : "Continue to Cloaked" }}
        <inlineSvg name="arrow-ne" />
      </Button>
    </div>

    <h1 class="data-remove__title extra-margin" v-if="showVideo">
      What's next
    </h1>
    <p class="data-remove__text" v-if="showVideo">
      Data removal is just the first step on your journey to improved online
      privacy and threat protection. Watch this quick video introduction to
      learn about all Cloaked has to offer.
    </p>

    <iframe
      v-if="showVideo"
      class="dd-cloaked-intro-video"
      src="https://www.youtube.com/embed/yED5dpZmRuo"
      title="The Cloaked Experience: More than just data removal!"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
      referrerpolicy="strict-origin-when-cross-origin"
      allowfullscreen
    ></iframe>

    <DataRemoveNotifyFamily
      v-if="familyMembers?.length"
      :familyMembers="familyMembers"
    />

    <h1 class="data-remove__title extra-margin" v-if="!showVideo">
      Data removal is just the beginning
    </h1>
    <p class="data-remove__text" v-if="!showVideo">
      Download the Cloaked app today to get access to the following benefits and
      features:
    </p>

    <div class="feature-wrapper" v-if="!showVideo">
      <div class="feature-row">
        <ul>
          <div class="title">24/7 Monitoring & Removal</div>
          <li class="bullet-text">Ongoing data deletion</li>
          <li class="bullet-text">Password breach detection</li>
        </ul>
        <img
          src="@/assets/icons/data-delete/monitoring.png"
          alt="1Password Logo"
          width="150"
          height="86"
        />
      </div>

      <div class="feature-row">
        <ul>
          <div class="title">Hide My Identity & Inbox</div>
          <li class="bullet-text">
            Unlimited aliases for your phone, email, & credit card
          </li>
        </ul>
        <img
          src="@/assets/icons/data-delete/identity.png"
          alt="1Password Logo"
          width="150"
          height="86"
        />
      </div>

      <div class="feature-row">
        <ul>
          <div class="title">$1M Identity Theft Insurance</div>
          <li class="bullet-text">File a loss claim 24/7</li>
        </ul>
        <img
          src="@/assets/icons/data-delete/theft.png"
          alt="1Password Logo"
          width="150"
          height="86"
        />
      </div>

      <div class="feature-row">
        <ul>
          <div class="title">Encrypted Password Manager</div>
          <li class="bullet-text">
            Auto-create, auto-fill, and auto-save logins
          </li>
        </ul>
        <img
          src="@/assets/icons/data-delete/passwords.png"
          alt="1Password Logo"
          width="150"
          height="86"
        />
      </div>
    </div>

    <h1 class="data-remove__title extra-margin">We're here to help</h1>
    <p class="data-remove__text">
      Our online support is available 24/7 if you have any issues or questions.
    </p>
    <Button
      type="secondary"
      size="2xl"
      @click="linkToSupport"
      class="data-remove-button"
      :class="{ 'extra-space': isMobileDevice }"
    >
      Contact support
    </Button>
  </div>
</template>

<style lang="scss" scoped>
.data-remove-submitted {
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 548px;

  .data-remove__title {
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px; /* 125% */
    letter-spacing: -0.5px;
    margin-top: 24px;
    &.extra-margin {
      margin-top: 64px;
    }
  }
  .data-remove__text {
    margin-top: 4px;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.1px;
  }

  &__icon {
    width: 44px;
    height: 44px;
  }

  &__features {
    margin-top: 16px;

    @media all and (min-width: $screen-lg) {
      margin-top: 24px;
    }
  }
}

.data-remove-submitted__status {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  width: 100%;

  .widget {
    margin-top: 10px;
    border: 1px solid $color-primary-20;
    border-radius: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: $color-primary-100;
    height: 80px;
    padding: 12px 8px;
    .in-progress {
      color: #f8b500;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.5px;
    }
    .completed {
      color: $color-success;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.5px;
      span {
        svg {
          height: 17px;
          width: auto;
          margin-left: 5px;
        }
      }
    }
  }
}

.data-remove-card-box {
  border-radius: 16px;
  border: 1px solid $color-primary-20;
  background: rgba(255, 255, 255, 0.1);
  background-blend-mode: screen;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.1);
  padding: 24px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
  gap: 16px;
  flex-direction: column;
  width: 100%;
  margin-top: 26px;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  &__status {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &--info-required {
      color: $color-info;
    }
    &--review-required {
      color: $color-info;
    }

    &--submitting,
    &--submitted {
      color: $color-success;
    }
  }

  &__name {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.2px;
    color: $color-primary-100;
  }

  &__date {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: $color-primary-100;
    margin-top: 6px;

    &.success {
      color: $color-success;
    }
  }
}

.feature-wrapper {
  border: 1.5px solid $color-primary-20;
  display: flex;
  flex-direction: column;
  padding: 35px 23px 0 23px;
  border-radius: 23.5px;
  width: 100%;
  margin-top: 24px;
  .feature-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    img {
      margin-top: -10px;
    }
  }
}

.title {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.4px;
}
.bullet-text {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.1px;
  display: list-item;
  list-style-type: disc;
  list-style-position: outside;
  color: $color-primary-70;
  margin-left: 24px;
}

.data-remove-button {
  width: 100%;
  margin-top: 32px;
  &.extra-space {
    margin-bottom: 100px;
  }
}

.mobile {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 16px 24px;
  min-width: 100%;
  max-width: 100%;
  background-color: $color-surface;
  border-top: 1px solid $color-primary-20;
  button {
    margin: 0;
  }
}
.width-100 {
  width: 100%;
}

.dd-cloaked-intro-video {
  border-radius: 12.6px;
  margin-top: 20px;
  object-fit: fill;
  width: 100%;
  height: auto;
  min-height: 263px;
}
</style>
