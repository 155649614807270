<script setup>
import InlineSvg from "@/components/InlineSvg.vue";
const props = defineProps({
  icon: {
    type: String,
    required: false,
    default: null,
  },
  textTransform: {
    type: String,
    required: false,
    default: "capitalize",
  },
});
</script>
<template>
  <div
    class="atom-tag-button"
    :class="{
      'atom-tag-button--uppercase': props.textTransform === 'uppercase',
      'atom-tag-button--lowercase': props.textTransform === 'lowercase',
      'atom-tag-button--capitalize': props.textTransform === 'capitalize',
    }"
  >
    <slot />
    <InlineSvg
      v-if="props.icon"
      :name="props.icon"
      class="atom-tag-button-icon"
    />
  </div>
</template>
<style scoped lang="scss">
.atom-tag-button {
  padding: 8px;
  color: $color-primary-100;
  background: $color-primary-10;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.1px;
  border-radius: 9px;
  border: 1px solid $color-primary-10;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  cursor: pointer;
  word-break: break-word;
  &--uppercase {
    text-transform: uppercase;
  }
  &--lowercase {
    text-transform: lowercase;
  }
  &--capitalize {
    text-transform: capitalize;
  }
  &:hover {
    transform: scale(1.02);
    transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  .atom-tag-button-icon {
    height: 18px;
    width: auto;
    flex-shrink: 0;
  }
}
</style>
