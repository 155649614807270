<script setup>
import { onMounted, computed, ref } from "vue";
import EsimService from "@/api/actions/esim-service.js";
import store from "@/store";
import InlineSvg from "@/components/InlineSvg.vue";
import { tools, format } from "@/scripts";
import { useToast } from "@/hooks";
import AtomProgressBar from "@/components/Atoms/AtomProgressBar.vue";
import { ESIM_STATE_DISPLAY } from "@/scripts/constants";

const toast = useToast();

onMounted(() => {
  EsimService.getSims({ number: true }).then((response) => {
    EsimService.getSimUsage(response?.data?.results[0]?.id);
  });
});

const esims = computed(() => {
  // NOTE: only show 1 esim for now
  return store.getters["esim/getEsims"]?.slice(0, 1);
});

const firstSim = computed(() => {
  // NOTE: only show 1 esim for now
  return esims.value[0];
});

const usageData = computed(() => {
  return store.getters["esim/getUsageData"](firstSim.value?.id);
});

function copyText(text) {
  tools.copyToClipboard(text);
  toast.success("Copied to clipboard");
}

function calculatePercentage(remaining, used) {
  remaining = parseInt(remaining) || 0;
  used = parseInt(used) || 0;
  return (remaining / (remaining + used)) * 100;
}
</script>
<template>
  <div class="page-wrapper">
    <InlineSvg name="esim-simcard-variant" class="background-icon" />
    <section
      class="section-wrapper pointer"
      v-for="(esim, index) in esims"
      :class="{
        first: index === 0,
      }"
      :key="esim.id"
      @click="copyText(esim.msisdn)"
    >
      <div class="flex-row">
        <div class="flex-col">
          <div class="title flex-row-center-start">
            <span>{{ format.phone_format(esim.msisdn) }}</span
            ><InlineSvg
              :name="
                esim.state === 'activated'
                  ? 'fuzzy-green-dot'
                  : 'fuzzy-orange-dot'
              "
            />
          </div>
          <div class="subtitle">
            Your eSIM is {{ ESIM_STATE_DISPLAY[esim.state] }}.
          </div>
        </div>
        <InlineSvg name="copy" />
      </div>
    </section>

    <section class="section-wrapper">
      <div>
        <div class="title">Cloaked eSIM best practices</div>
        <div class="subtitle">
          Your Cloaked eSIM is separate from your cellular carrier and has its
          own limits for calls and data.
        </div>
      </div>
      <div class="flex-row-center-start">
        <InlineSvg name="check-active" class="list-icon success" />
        <div class="paragraph">Use your eSIM for secure calls and texts</div>
      </div>
      <div class="flex-row-center-start">
        <InlineSvg name="x-circle" class="list-icon brand-one" />
        <div class="paragraph">Don't use your eSIM for cellular data</div>
      </div>
      <div class="flex-row-center-start">
        <InlineSvg name="lock-shield-filled" class="list-icon brand-three" />
        <div class="paragraph">
          Keep your eSIM secure by only sharing it when necessary
        </div>
      </div>
    </section>

    <section class="section-wrapper">
      <div>
        <div class="title">When to use your eSIM number</div>
        <div class="subtitle">
          Your Cloaked eSIM is an ultra-secure number that you should only share
          when sensitive information is at stake.
        </div>
      </div>
      <div class="flex-row-center-start">
        <InlineSvg name="bank-filled" class="list-icon brand-six" />
        <div class="paragraph">To secure financial accounts</div>
      </div>
      <div class="flex-row-center-start">
        <InlineSvg name="hospital-plus" class="list-icon brand-one" />
        <div class="paragraph">To protect medical data</div>
      </div>
      <div class="flex-row-center-start">
        <InlineSvg name="chat-imessage" class="list-icon brand-three" />
        <div class="paragraph">When iMessage is required</div>
      </div>
      <div class="flex-row-center-start">
        <InlineSvg name="phone-check" class="list-icon lt-green" />
        <div class="paragraph">When standard Cloaked numbers are rejected</div>
      </div>
    </section>

    <div class="flex-row data-wrapper" v-if="firstSim">
      <section class="section-wrapper no-gap">
        <div class="avail-header">Available Data</div>
        <div class="avail-data">
          {{
            `${format.formatNumbersBasedonLocale(
              usageData?.data?.remaining_data_in_mb || 0
            )}mb`
          }}
        </div>

        <AtomProgressBar
          class="progress-bar"
          :percent="
            (usageData?.data?.remaining_data_in_mb /
              usageData?.data?.total_data_allowed_in_mb) *
            100
          "
          topColor="linear-gradient(100deg, #DD0EA3 20%, #FF4163 70%)"
          bottomColor="#FF416330"
          height="6px"
        />
      </section>

      <section class="section-wrapper no-gap">
        <div class="avail-header">Available call time</div>
        <div class="avail-data">
          {{
            `${format.formatNumbersBasedonLocale(
              usageData?.voice?.remaining || 0
            )}min`
          }}
        </div>
        <AtomProgressBar
          class="progress-bar"
          :percent="
            calculatePercentage(
              usageData?.voice?.remaining,
              usageData?.voice?.used
            )
          "
          topColor="linear-gradient(100deg, #0CC4A0 20%, #11C3E9 70%)"
          bottomColor="#11C3E930"
          height="6px"
        />
      </section>
    </div>
    <div class="footer" v-if="firstSim">
      Call and data limits are refreshed on a monthly basis. Text messages are
      unlimited. To increase your data or call time,
      <a class="link" href="https://help.cloaked.app/hc/en-us" target="_blank"
        >contact Cloaked support.</a
      >
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  padding: 24px;
  .background-icon {
    width: 450px;
    height: auto;
    z-index: 0;
  }

  .section-wrapper {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 24px;
    border: 1px solid $color-primary-10;
    border-radius: 12px;
    width: 100%;
    max-width: 640px;
    gap: 14px;
    background-color: $color-surface;
    color: $color-primary-100;
    &.first {
      margin-top: -80px;
    }
    &.no-gap {
      gap: 0;
    }
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
}
.flex-row-center-start {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.flex-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
}

.pointer {
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    transform: scale(1.01);
    transition: all 0.3s ease;
  }
}

.avail-header {
  color: $color-primary-100;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.avail-data {
  color: $color-primary-100;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.5px;
}

.title {
  color: $color-primary-100;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.4px;
}

.subtitle {
  color: $color-primary-70;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.paragraph {
  color: $color-primary-100;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.2px;
}
.data-wrapper {
  width: 100%;
  max-width: 640px;
}

.progress-bar {
  margin-top: 8px;
}

.footer {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: $color-primary-70;
  text-align: left;
  width: 100%;
  max-width: 640px;
  .link {
    text-decoration-line: underline;
    transition: all 0.3s ease;
    &:hover {
      color: $color-primary-100;
      transform: scale(1.05);
      transition: all 0.3s ease;
    }
  }
}

.list-icon {
  width: 24px;
  height: 24px;
  color: $color-primary-100;
  &.success {
    color: $color-success;
  }
  &.brand-one {
    color: $color-brand-1-100;
  }
  &.brand-three {
    color: $color-brand-3-100;
  }
  &.brand-six {
    color: $color-brand-6-100;
  }
  &.lt-green {
    color: $color-lt-green;
  }
}
</style>
