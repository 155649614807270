<script setup>
import { computed, ref, defineEmits } from "vue";
import Button from "@/components/Button.vue";
import store from "@/store";
import inlineSvg from "@/components/InlineSvg.vue";
import CardsServices from "@/api/actions/cards-services";
import UserService from "@/api/actions/user-service";
import { useToast } from "@/hooks";

const toast = useToast();

const emit = defineEmits(["update-collections-active"]);

const collection = computed(() => {
  return store.state.authentication?.user?.card_collections;
});

const outstandingBalance = computed(() => {
  return store.state.authentication?.user?.outstanding_balance;
});

const collectionsActive = computed(() => {
  if (
    collection.value?.status === "active" ||
    collection.value?.status === "pending"
  ) {
    return true;
  } else {
    return false;
  }
});

const sources = computed(() => {
  return store.state.cards?.fundingSources.results;
});

const primarySource = computed(() => {
  return sources?.value.find((source) => source.primary).id;
});

const fundingSource = ref(null);

const selectedSource = computed(() => {
  return fundingSource.value || primarySource.value;
});

function selectSource(source) {
  fundingSource.value = source.id;
}

function convertToDollar(amount) {
  return (amount / 100)
    .toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    })
    .replace(/\.0+$/, "");
}

function payCollection() {
  collectionsButtonDisabled.value = true;
  CardsServices.payAll().then(() => {
    UserService.getUserDetails().then(() => {
      collectionsButtonDisabled.value = false;
      mockOutstandingBalance.value = 0;
      collectionsActive.value = false;
      emit("update-collections-active", false);
      toast.success("Payment successful");
    });
  });
}

const collectionsButtonDisabled = ref(false);

const mockOutstandingBalance = ref(15000);
</script>

<template>
  <div class="collections-panel" v-if="sources && collectionsActive">
    <div class="collection">
      <div class="title">
        <h1>Balance due: {{ convertToDollar(mockOutstandingBalance) }}</h1>
        <p v-if="collectionsActive">
          To continue using Cloaked Pay, please pay your remaining balance.
        </p>

        <p v-else-if="collection?.status === 'pending'">
          Your payment is being processed. Please come back later.
        </p>
      </div>

      <div class="funding-sources" v-if="collectionsActive">
        <div
          class="funding-source"
          v-for="source in sources"
          :key="source.id"
          :class="{ selected: source.id === selectedSource }"
          @click="selectSource(source)"
        >
          <inlineSvg name="bank" />
          <div class="information">
            <h1>{{ source.card_brand }}</h1>
            <p>
              <span>**** {{ source.pan_last_four }}</span>
              <span v-if="source.nickname">• {{ source.nickname }}</span>
            </p>
          </div>

          <span class="default-pill" v-if="source.primary">Default</span>

          <div class="selected-icon"></div>
        </div>
      </div>

      <Button
        fullWidth
        size="xl"
        @click="payCollection()"
        v-if="collectionsActive"
        :disabled="collectionsButtonDisabled"
        :loading="collectionsButtonDisabled"
        >Pay balance in full</Button
      >
    </div>
  </div>
</template>

<style lang="scss" scoped>
.collections-panel {
  width: 400px;
  height: calc(100vh - 60px);
  overflow: auto;
  position: fixed;
  @include custom-scroll-bar();
  transition: all 0.4s ease-in-out;
  @include transform(translateX(0px));
  border-right: 1px solid $color-primary-10;

  .collection {
    padding: 24px;
    border: 1px solid $color-primary-10;
    border-radius: 20px;
    margin: 15px 15px 30px 15px;

    > * {
      margin-top: 16px;

      &:first-child {
        margin-top: 0;
      }
    }

    .title {
      h1 {
        font-size: 24px;
        line-height: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.5px;
        color: $color-primary-100;
      }

      p {
        margin-top: 16px;
        font-size: 16px;
        line-height: 24px;
        color: $color-primary-70;
        letter-spacing: -0.2px;

        strong {
          font-weight: 600;
        }
      }
    }

    .funding-sources {
      margin-top: 24px;

      .funding-source {
        border: 1px solid $color-primary-10;
        border-radius: 16px;
        padding: 16px;
        margin-top: 4px;
        position: relative;
        color: $color-primary-100;

        &:hover {
          background-color: $color-primary-5;
          cursor: pointer;
        }

        &.selected {
          .selected-icon {
            &:after {
              content: "";
              display: block;
              width: 8px;
              height: 8px;
              background-color: $color-primary-100;
              border-radius: 50%;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }

        &:first-child {
          margin-top: 0;
        }

        svg {
          width: 24px;
          height: 24px;
          margin-right: 16px;
          position: absolute;
          top: 50%;
          left: 16px;
          transform: translateY(-50%);
        }

        .information {
          padding-left: 40px;
          padding-right: 40px;

          h1 {
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.1px;
            color: $color-primary-100;
            text-transform: capitalize;
          }

          p {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 4px;

            span {
              display: inline-block;

              &:nth-of-type(2) {
                margin-left: 4px;
              }
            }
          }
        }

        .default-pill {
          position: absolute;
          top: 50%;
          right: 56px;
          transform: translateY(-50%);
          background-color: $color-success;
          color: $white;
          font-size: 10px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          padding: 4px 10px;
          border-radius: 19px;
        }

        .selected-icon {
          position: absolute;
          top: 50%;
          right: 24px;
          transform: translateY(-50%);
          width: 16px;
          height: 16px;
          border-radius: 50%;
          border: 2px solid $color-primary-100;
        }
      }
    }
  }
}
</style>
