<script setup>
import { ref } from "vue";
import DataDeleteService from "@/api/actions/data-delete-service.js";
import AtomInput from "@/components/Atoms/AtomInput.vue";
import AtomTagButton from "@/components/Atoms/AtomTagButton.vue";
import Button from "@/components/Button.vue";
import { email as emailValidation } from "@/scripts/validation";
import SettingsDataRemovalFormWrapper from "@/components/Settings/SettingsDataRemovalFormWrapper.vue";

import { useToast } from "@/hooks";
const toast = useToast();

const props = defineProps({
  fullProfile: {
    type: Object,
  },
});

const emit = defineEmits(["showConfirmationModal", "updateProfile"]);

const inputFieldEmail = ref("");
const emailError = ref(false);

function saveEmail() {
  if (!inputFieldEmail.value || !emailValidation(inputFieldEmail.value)) {
    emailError.value = true;
    return;
  } else {
    emailError.value = false;
  }

  const fullProfileCopy = { ...props.fullProfile };
  fullProfileCopy.email_addresses = [
    ...fullProfileCopy.email_addresses,
    inputFieldEmail.value,
  ];
  return DataDeleteService.updateEnrollmentProfile(fullProfileCopy)
    .then(() => {
      inputFieldEmail.value = "";
      emit("updateProfile", fullProfileCopy);
      toast.success("Email address added successfully");
    })
    .catch(() => {
      toast.error("Failed to add email address");
    });
}
</script>
<template>
  <SettingsDataRemovalFormWrapper>
    <template #header>
      <h1>Email address</h1>
      <Button
        type="primary"
        class="save-button"
        @click="saveEmail"
        :disabled="!inputFieldEmail"
        >Add email address</Button
      >
    </template>
    <AtomInput
      label="Email address"
      placeholder="Enter email address"
      type="email"
      :error="emailError"
      errorMessage="Please enter a valid email address"
      :value="inputFieldEmail"
      @input="inputFieldEmail = $event.target.value"
      @keydown.enter.exact.stop.prevent="saveEmail"
    />
    <template #tags>
      <AtomTagButton
        v-for="(email, idx) in fullProfile?.email_addresses"
        :key="`${idx}-${email}`"
        icon="close-outline"
        textTransform="lowercase"
        @click="
          emit('showConfirmationModal', {
            field: 'email_addresses',
            index: idx,
            data: email,
          })
        "
        >{{ email }}</AtomTagButton
      >
    </template>
  </SettingsDataRemovalFormWrapper>
</template>
