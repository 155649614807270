<script setup>
import UiHeader from "@/components/feature/onboarding-new/UiHeader.vue";
import UiButton from "@/components/feature/onboarding-new/UiButton.vue";
import UiButtonRow from "@/components/feature/onboarding-new/UiButtonRow.vue";
import UiPageWrapper from "@/components/feature/onboarding-new/UiPageWrapper.vue";
import AtomInputInternalLabels from "@/components/Atoms/AtomInputInternalLabels.vue";
import { PH_SCREEN_EVENT_ESIM_ONBOARDING_COMPATIBILITY_SCREEN } from "@/scripts/posthogEvents";
import EsimMobileToggleWidget from "@/components/eSim/EsimMobileToggleWidget.vue";
import ESimService from "@/api/actions/esim-service.js";

import { reactive, ref, nextTick, computed } from "vue";
import { useToast } from "@/hooks";
const toast = useToast();

const props = defineProps({
  deviceType: {
    type: String,
    default: "ios",
  },
  isPaid: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  "next",
  "updateDeviceType",
  "incompatible",
  "updateSimId",
]);

const imeiInput = ref(null);
const eidInput = ref(null);

const state = reactive({
  imei: "",
  imeiInputError: null,

  eid: "",
  eidInputError: null,
  coverageError: null,
  loading: false,
});

const instructions = computed(() => {
  return props.deviceType === "android"
    ? "In settings, tap “About Phone” and scroll down to find your EID and IMEI numbers. If there are multiple, look for a label that says “eSIM” or “SIM Slot 2”."
    : 'In settings, tap "General", then tap “About” and scroll down to find your EID and IMEI numbers.';
});

async function checkDeviceValidity() {
  state.loading = true;
  state.imei = state.imei.replace(/\s+/g, "");
  state.eid = state.eid.replace(/\s+/g, "");
  if (state.imei.length != 15) {
    state.imeiInputError = true;
  } else {
    state.imeiInputError = false;
  }
  if (state.eid.length != 32) {
    state.eidInputError = true;
  } else {
    state.eidInputError = false;
  }
  if (!state.eidInputError && !state.imeiInputError) {
    try {
      let res = await ESimService.createDeviceTmobile(state.imei, state.eid);
      checkCompatibility(res);
    } catch (e) {
      state.loading = false;
      if (e?.response?.status == 503) {
        toast.error(e?.response?.data?.message || "Something went wrong");
      } else if (e?.response?.status == 429) {
        toast.error("You have been rate limited");
      } else {
        checkCompatibility(e?.response);
      }
    }
  }
  state.loading = false;
}

function checkCompatibility(res) {
  const simID = res?.data?.device?.sims[0]?.id;
  emit("updateSimId", simID);
  const esimCompatible = res?.data?.device?.compatibility?.esim;
  const imeiCompatible = res?.data?.device?.compatibility?.compatible_imei;

  if (!simID || !esimCompatible || !imeiCompatible) {
    emit("incompatible");
  } else {
    if (props.isPaid) {
      emit("next", 4);
    } else {
      emit("next", 3);
    }
  }
}

function nextInput() {
  if (state.eid.length !== 32) {
    state.eidInputError = true;
  } else {
    state.eidInputError = false;
    nextTick(() => {
      imeiInput?.value?.$refs?.inputRef?.focus();
    });
  }
}

function handleEIDInput(event) {
  state.eidInputError = false;
  state.eid = event.target.value;
}
function handleIMEIInput(event) {
  state.imeiInputError = false;
  state.imei = event.target.value;
}
</script>
<template>
  <UiPageWrapper
    showLogo
    logo="cloaked-esim-logo"
    :screenEvent="PH_SCREEN_EVENT_ESIM_ONBOARDING_COMPATIBILITY_SCREEN"
    :esimStep="2"
  >
    <UiHeader maxWidth="450px">
      <h1>Check eSIM compatibility</h1>
      <h5>
        Please enter your device EID and IMEI numbers below. You can find these
        numbers by going to your device's settings.
      </h5>
    </UiHeader>
    <EsimMobileToggleWidget
      :deviceType="props.deviceType"
      :instructions="instructions"
      :imgName="
        props.deviceType === 'android' ? 'android-general' : 'ios-general'
      "
      @updateDeviceType="(deviceType) => emit('updateDeviceType', deviceType)"
    />

    <div class="input-fields">
      <AtomInputInternalLabels
        ref="eidInput"
        autofocus
        :isLoading="state.loading"
        :value="state.eid"
        label="EID Number"
        type="text"
        placeholder="Enter EID Number"
        :disabled="state.loading"
        :pattern="/^[0-9]*$/"
        :error="state.eidInputError"
        errorMessage="EID Numbers must be 32 characters"
        @input="handleEIDInput"
        @keydown.enter.prevent="nextInput"
        @keydown.tab.prevent="nextInput"
      />

      <AtomInputInternalLabels
        ref="imeiInput"
        :isLoading="state.loading"
        :value="state.imei"
        label="IMEI Number"
        type="text"
        placeholder="Enter IMEI Number"
        :disabled="state.loading"
        :pattern="/^[0-9]*$/"
        :error="state.imeiInputError"
        errorMessage="IMEI Numbers must be 15 characters"
        @input="handleIMEIInput"
        @keydown.enter="checkDeviceValidity"
      />
    </div>

    <UiButtonRow>
      <UiButton
        gradient
        class="device-check-button"
        @click="checkDeviceValidity"
        :loading="state.loading"
        :disabled="
          state.loading ||
          state.eidInputError ||
          state.imeiInputError ||
          !state.eid?.length ||
          !state.imei?.length
        "
        >{{ state.loading ? "Creating" : "Create Device" }}</UiButton
      >
    </UiButtonRow>
  </UiPageWrapper>
</template>
<style lang="scss" scoped>
.input-fields {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 8px;
  margin-top: 16px;
  padding-bottom: 100px;
  max-width: 450px;
}
</style>
