<script setup>
import inlineSvg from "@/components/InlineSvg.vue";
import Spinner from "@/assets/icons/spinner.svg";

const props = defineProps({
  gradient: {
    type: Boolean,
    default: false,
  },
  imgName: {
    type: String,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  width: {
    type: String,
    default: "100%",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
</script>
<template>
  <div
    tabindex="0"
    class="dd-onboarding-ui-button"
    :class="{ gradient: props.gradient, disabled: props.disabled }"
    :style="{ width: props.width }"
    :disabled="props.disabled"
    role="button"
    @click="
      (e) => {
        if (props.disabled) {
          e.preventDefault();
          e.stopPropagation();
        }
      }
    "
  >
    <Spinner v-if="props.loading" />
    <span v-else class="button-inner-wrapper">
      <slot />
      <inlineSvg v-if="props.imgName" :name="props.imgName" />
    </span>
  </div>
</template>
<style lang="scss" scoped>
.dd-onboarding-ui-button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 54px;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.1px;
  color: $color-primary-0;
  border-radius: 99px;
  border: 1px solid $color-primary-100;
  background: $color-primary-100;
  max-width: 344px;
  flex-shrink: 0;
  transition: all 0.3s ease;
  &.gradient {
    background: $cloaked-gradient;
    color: $white;
    height: 55px;
    border: none;
  }
  .button-inner-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    svg {
      height: 15px;
      width: auto;
    }
  }

  &:hover {
    transform: scale(1.02);
    transition: all 0.3s ease;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    z-index: 2;
  }
}
</style>
