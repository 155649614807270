<script setup>
import {
  watch,
  ref,
  computed,
  nextTick,
  onMounted,
  onBeforeUnmount,
  reactive,
} from "vue";
import store from "@/store";
import { useRoute, useToast } from "@/hooks";
import router from "@/routes/router";
import inlineSvg from "@/components/InlineSvg.vue";
import { CountryAccountIcon } from "@/components";
import { getUserCountry } from "@/scripts/countries/countries";
import { logout } from "@/scripts/actions/auth";
import { vOnClickOutside } from "@vueuse/components";
import IdentityIcon from "@/components/ui/IdentityIcon";
import UiTooltip from "@/components/ui/ui-tooltip";
import InboxService from "@/api/actions/inbox-service";
import { hash } from "@/scripts";
import UpgradeButton from "@/components/Navigation/UpgradeButton.vue";
import IdentityService from "@/api/actions/identity-service";
import { isMobileDevice } from "@/scripts/regex";

const route = useRoute();
const toast = useToast();

const globalSearch = ref("");
const navSearchInputRef = ref(null);
const pageTitle = ref(route?.meta);
const componentKey = ref(0);
const dropdown = ref(false);
const isDarkMode = ref(store.getters["colorScheme/isDarkMode"]);
const target = ref(null);

const state = reactive({
  isThreadStarred: false,
});

onMounted(() => {
  window.addEventListener("star-updated", updateStarred);
});

onBeforeUnmount(() => {
  window.removeEventListener("star-updated", updateStarred);
});

const dashboardVersion = computed(() => {
  return global.ENV.DASHBOARD_VERSION || null;
});

const inboxIdentity = computed(() => {
  if (route?.name?.toLowerCase() === "cloakinbox") {
    const id = hash.decode(route?.params?.id);
    const identity = store.state.localdb.db_cloaks.find((i) => i.id === id);
    return identity;
  }
  return null;
});

const inboxIdentityMuted = computed(() => {
  return inboxIdentity?.value?.muted;
});

const inboxCategory = computed(() => {
  if (route?.name?.toLowerCase() === "categoryinbox") {
    const id = hash.decode(route?.params?.id);
    const category = categories.value.find((i) => i.id === id);
    return category;
  }
  return null;
});

const categoryPageName = computed(() => {
  if (route.name.toLowerCase() === "category") {
    const category = categories.value.find(
      (i) => `${i.id}` === route?.params?.id
    );
    return category?.name;
  }
  return null;
});

const isFavorites = computed(() => {
  return route?.name?.toLowerCase() === "favoritesinbox";
});

const isRecent = computed(() => {
  return route?.name?.toLowerCase() === "recentinbox";
});

const inbox = computed(() => {
  return route?.meta?.nav === "inbox";
});

const showSearchBar = computed(() => {
  const identitySearchPages = ["favorites", "ignored", "all", "category"];
  return (
    (identitySearchPages.includes(route.name.toLowerCase()) || inbox.value) &&
    !showActivityIcons.value
  );
});

const showActivityIcons = computed(() => {
  return route?.meta?.showActivityIcons;
});

const username = computed(() => {
  return (
    store.state.authentication?.username ||
    store.state.authentication?.user?.username
  );
});

const menuIsOpen = computed(() => {
  return store.state.inbox.menu;
});

const subscriptionState = computed(() => {
  return store.state.settings.subscription?.state;
});

const userCountryCode = computed(() =>
  getUserCountry(store.state.authentication?.user)
);

const categories = computed(() => store.getters["getCategories"]);

const navTitle = computed(() => {
  if (isRecent.value) {
    return "Recently Created";
  }
  if (isFavorites.value) {
    return "Favorites";
  }
  if (inboxIdentity?.value) {
    return (
      inboxIdentity?.value?.nickname ||
      inboxIdentity?.value?.name ||
      "(Unnamed)"
    );
  }
  if (inboxCategory?.value) {
    return inboxCategory?.value?.name || "(Unnamed)";
  }
  if (categoryPageName?.value) {
    return categoryPageName.value || "(Unnamed)";
  }

  return pageTitle?.value?.title;
});

watch(
  globalSearch,
  (value) => {
    if (inbox.value) {
      return store.dispatch("inbox/setSearch", value);
    }
    return store.dispatch("setSearch", value);
  },
  { deep: true }
);

watch(
  route,
  () => {
    pageTitle.value = route?.meta;
    globalSearch.value = "";
    store.dispatch("inbox/closeInboxMenu");
    store.dispatch("inbox/setSearch", "");
    store.dispatch("setSearch", "");
    state.isThreadStarred = false;
  },
  { deep: true }
);

watch(
  pageTitle,
  () => {
    componentKey.value += 1;
  },
  { deep: true }
);

function updateStarred(event) {
  state.isThreadStarred = event?.detail?.starred;
  componentKey.value += 1;
}

function activateDropdown() {
  dropdown.value = !dropdown.value;
}

function closeDropdown() {
  dropdown.value = false;
}

function toggleDownloadAppModal() {
  store.dispatch("toggleMobileAppModal", true);
  dropdown.value = false;
}

function toggleInsuranceModal() {
  store.dispatch("toggleInsuranceModal", true);
  dropdown.value = false;
}

function openHelp() {
  dropdown.value = false;
}

function toggleDarkMode() {
  store.dispatch("colorScheme/changeColorTheme");
  dropdown.value = false;

  if (isDarkMode.value) {
    isDarkMode.value = false;
  } else {
    isDarkMode.value = true;
  }
}

function upgradeModal() {
  store.dispatch("subscription/openSubscriptionModal");
  dropdown.value = false;
}

function toggleInbox(event) {
  store.dispatch("inbox/toggleInbox", event);
}

function markThreadUnread() {
  InboxService.markThreadsAsUnread([route?.params?.id])
    .then(async () => {
      toast.success("Marked as unread");
      await store.dispatch("updateActivityCachedData", {
        threadId: route?.params?.id,
        key: "read",
        value: false,
      });
      // NOTE: this is a workaround since I couldnt find a good way
      // to await the cached data update call above
      setTimeout(() => {
        nextTick(handleGoBack);
      }, 500);
    })
    .catch(() => {
      toast.error("Error updating data");
    });
}

function toggleThreadStarred() {
  if (!state.isThreadStarred) {
    return InboxService.markThreadsStarred([route?.params?.id])
      .then(async () => {
        toast.success("Marked as starred");
        await store.dispatch("updateThreadCachedData", {
          threadId: route?.params?.id,
          key: "starred",
          value: true,
        });
        updateStarred({ detail: { starred: true } });
      })
      .catch(() => {
        toast.error("Error updating data");
      });
  }
  return InboxService.markThreadsUnstarred([route?.params?.id])
    .then(async () => {
      toast.success("Removed from starred");
      await store.dispatch("updateThreadCachedData", {
        threadId: route?.params?.id,
        key: "starred",
        value: false,
      });
      updateStarred({ detail: { starred: false } });
    })
    .catch(() => {
      toast.error("Error updating data");
    });
}

function showDeleteModal() {
  return store.dispatch("openModal", {
    header: `Delete messages?`,
    subheader:
      "Any copies of these messages that have been forwarded to your personal email or phone will be unaffected. This cannot be undone.",
    button: {
      text: "Yes, Delete",
      onClick: () => deleteThread(),
      danger: true,
    },
  });
}

function deleteThread() {
  InboxService.bulkDeleteActivity([route?.params?.id])
    .then(async () => {
      toast.success("Deleted");
      await store.dispatch("deleteCacheAllPages", {
        url: "api/v2/cloaked/activity/",
      });
      setTimeout(() => {
        nextTick(handleGoBack);
      }, 500);
    })
    .catch(() => {
      toast.error("Error, delete unsuccessful");
    });
}

function handleGoBack() {
  const prevRouteName = store.getters["navigation/previousRouteName"];
  const allInboxListRoutes = store.getters["navigation/allInboxListRoutes"];
  if (!!prevRouteName && allInboxListRoutes.includes(prevRouteName)) {
    router.back();
  } else {
    router.push({ name: "Inbox" });
  }
}

function toggleMute() {
  const newMuteStatus = !inboxIdentityMuted.value;
  store.dispatch("updateCloaks", [
    {
      ...inboxIdentity.value,
      muted: newMuteStatus,
    },
  ]);
  IdentityService.updateMuteStatus(inboxIdentity.value.id, newMuteStatus)
    .then(() => {
      const message = newMuteStatus
        ? "Notifications silenced"
        : "Notifications enabled";
      toast.success(message);
    })
    .catch(() => {
      const message = newMuteStatus
        ? "Error silencing notifications"
        : "Error enabling notifications";
      toast.error(message);
    });
}
</script>

<template>
  <section v-on-click-outside="() => (dropdown = false)">
    <div class="title" v-if="inbox">
      <div class="icon" v-if="pageTitle.icon || inboxIdentity || inboxCategory">
        <IdentityIcon :identity="inboxIdentity" v-if="inboxIdentity" />
        <inlineSvg name="category_menu" v-else-if="inboxCategory" />
        <inlineSvg v-else :name="pageTitle.icon" :key="componentKey" />
      </div>
      <button
        class="inbox-button"
        @click="toggleInbox"
        id="inbox-button"
        :class="{ 'is-open': menuIsOpen }"
      >
        <span
          :class="{ capitalize: !navTitle?.toLowerCase()?.includes('esim') }"
          >{{ navTitle }}</span
        >
      </button>
      <UiTooltip
        v-if="inboxIdentity"
        :title="
          inboxIdentityMuted
            ? 'Stop silencing notifications'
            : 'Silence notifications from this identity'
        "
        align-x="center"
        class="muted-icon"
        @click="toggleMute"
      >
        <inlineSvg
          :name="inboxIdentityMuted ? 'muted-outline' : 'bell'"
          :key="inboxIdentityMuted ? 'muted-outline' : 'bell'"
        />
      </UiTooltip>
    </div>

    <div class="title activity-icons-row" v-else-if="showActivityIcons">
      <UiTooltip title="" align-x="center">
        <div @click="handleGoBack" class="activity-icon-wrapper">
          <inlineSvg :name="pageTitle.icon" :key="componentKey" />
        </div>
      </UiTooltip>
      <div class="vertical-separator"></div>
      <UiTooltip
        :title="
          state.isThreadStarred ? 'Remove from starred' : 'Mark as starred'
        "
        align-x="center"
      >
        <div
          @click="toggleThreadStarred"
          class="activity-icon-wrapper"
          :class="{ 'color-warning': state.isThreadStarred }"
        >
          <inlineSvg
            :name="state.isThreadStarred ? 'star' : 'star-outline'"
            :key="componentKey"
          />
        </div>
      </UiTooltip>
      <UiTooltip title="Mark as unread" align-x="center">
        <div @click="markThreadUnread" class="activity-icon-wrapper">
          <inlineSvg name="mail-unread" :key="componentKey" />
        </div>
      </UiTooltip>
      <UiTooltip title="Delete all" align-x="center">
        <div @click="showDeleteModal" class="activity-icon-wrapper">
          <inlineSvg name="delete-trash" :key="componentKey" />
        </div>
      </UiTooltip>
    </div>

    <div class="title" v-else>
      <div class="icon" v-if="pageTitle.icon">
        <inlineSvg :name="pageTitle.icon" :key="componentKey" />
      </div>
      <span
        :class="{ capitalize: !navTitle?.toLowerCase()?.includes('esim') }"
        >{{ navTitle }}</span
      >
    </div>

    <div
      class="search-input"
      :class="{ 'has-text': !!globalSearch }"
      v-if="showSearchBar"
    >
      <inlineSvg name="search" class="search-icon" />
      <input
        type="text"
        id="inbox-search"
        ref="navSearchInputRef"
        v-model="globalSearch"
        :placeholder="`Search ${navTitle}`"
        autocomplete="off"
        :class="{ active: globalSearch }"
      />
      <inlineSvg
        name="close-outline"
        class="close-icon"
        v-if="globalSearch"
        @click="globalSearch = ''"
      />
    </div>
    <div class="right-side-group">
      <UpgradeButton
        class="upgrade-button"
        v-if="
          $store.getters['settings/isTrial'] ||
          $store.getters['settings/isCancelled']
        "
      />
      <div class="username-dropdown" ref="target">
        <div class="username" @click="activateDropdown">
          <div class="text">
            <span v-if="username">{{ username }}</span>
          </div>

          <div class="icon" :class="{ active: dropdown }">
            <span>
              <inlineSvg name="user-icon-filled" />
            </span>
          </div>
        </div>

        <div class="dropdown" :class="{ active: dropdown }">
          <router-link
            to="/settings"
            class="user"
            @click="closeDropdown()"
            :class="{
              disabled: subscriptionState === 'CANCELLED',
              'mobile-only': isMobileDevice,
            }"
          >
            <CountryAccountIcon
              :countryInfo="{ countryCode: userCountryCode }"
              :override="{ height: '32px', width: '32px' }"
              class="user-icon"
            />

            <div class="text">
              <span class="name" v-if="username">{{ username }}</span>
              <span class="name" v-else>Cloaked User</span>
              <span class="info" v-if="subscriptionState !== 'CANCELLED'"
                >Edit my information</span
              >
              <span class="info" v-else>Read only account</span>
            </div>
          </router-link>

          <div
            class="button"
            :class="{ 'mobile-only': isMobileDevice }"
            @click="upgradeModal"
            v-if="subscriptionState === 'CANCELLED'"
          >
            <span>Upgrade now</span>
          </div>

          <div class="divider" :class="{ 'mobile-only': isMobileDevice }"></div>

          <router-link
            to="/settings"
            class="item link"
            :class="{ 'mobile-only': isMobileDevice }"
            @click="closeDropdown()"
          >
            <inlineSvg name="cog" />
            <span>Settings</span>
          </router-link>

          <router-link
            to="/import"
            class="item link"
            :class="{ 'mobile-only': isMobileDevice }"
            @click="closeDropdown()"
          >
            <inlineSvg name="key-filled" />
            <span>Import passwords</span>
          </router-link>

          <a
            href="https://www.cloaked.com/cloaked-uncloaked"
            target="_blank"
            class="item download"
            :class="{ 'mobile-only': isMobileDevice }"
          >
            <inlineSvg name="paper" />
            <span>Cloaked: Uncloaked</span>
          </a>

          <router-link
            to="/referrals"
            class="item link"
            :class="{ 'mobile-only': isMobileDevice }"
            @click="closeDropdown()"
          >
            <inlineSvg name="gift" />
            <span>Invite a friend</span>
          </router-link>

          <div
            class="item link"
            :class="{ 'mobile-only': isMobileDevice }"
            @click="toggleInsuranceModal"
            v-if="
              store.getters['settings/isSubscribed'] &&
              store.state.authentication?.user?.flags?.id_theft_protection
            "
          >
            <inlineSvg name="user-shield" />
            <span>Identity theft protection</span>
          </div>

          <div class="divider" :class="{ 'mobile-only': isMobileDevice }"></div>

          <a
            href="https://download.cloaked.app"
            target="_blank"
            class="item download"
            :class="{ 'mobile-only': isMobileDevice }"
            @click="closeDropdown()"
          >
            <inlineSvg name="browser" />
            <span>Download extension</span>
          </a>

          <div class="item download" @click="toggleDownloadAppModal">
            <inlineSvg name="block-active" />
            <span>Download mobile app</span>
          </div>

          <div class="divider"></div>

          <div class="item toggle" @click="toggleDarkMode">
            <inlineSvg name="moon" />
            <span>Dark mode</span>

            <button class="toggle" :class="{ enabled: isDarkMode }">
              <span />
            </button>
          </div>

          <a href="https://help.cloaked.app/hc/en-us" target="_new">
            <div class="item" @click="openHelp">
              <span>Get help or share feedback</span>
            </div>
          </a>

          <div class="item" @click="logout()">
            <span>Log out</span>
          </div>
          <div
            class="item version"
            :class="{ 'mobile-only': isMobileDevice }"
            v-if="!!dashboardVersion"
          >
            <span>Cloaked version {{ dashboardVersion }}</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.mobile-only {
  display: none !important;
}

.inbox-button {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  padding: 4px 22px 4px 6px;
  color: $color-primary-100;
  position: relative;

  &:hover,
  &.is-open {
    background-color: $color-primary-10;
  }

  span {
    &:first-letter {
      text-transform: capitalize;
    }
  }

  &:after {
    content: "";
    position: absolute;
    right: 8px;
    top: 50%;
    @include transform(rotate(45deg));
    transform-origin: center center;
    width: 8px;
    height: 8px;
    border: 2px solid $color-primary-100;
    border-top-width: 0;
    border-left-width: 0;
    border-bottom-right-radius: 2px;
    margin-top: -4px;
  }
}
section {
  position: fixed;
  top: 0;
  right: 0;
  height: $top-bar-height;
  border-bottom: 1px solid $color-primary-10;
  width: 100%;
  background-color: $color-surface;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 12px;
  z-index: 100;

  @media (min-width: 768px) {
    width: calc(100% - 240px);
    padding: 0 20px 0 24px;
  }

  @media (min-width: 768px) {
    .app--visible-banner & {
      top: $ui-banner-height;
    }
  }

  .title {
    display: flex;
    align-items: center;
    flex-grow: 0;
    margin-right: auto;

    .icon {
      margin-right: 8px;
      width: 24px;
      height: 24px;
      background-color: $color-primary-100;
      color: $color-primary-0;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      > svg {
        height: 13px;
        width: auto;
        color: $color-primary-0;
      }
    }

    span {
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.4px;
      color: $color-primary-100;
      max-width: 200px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .search {
    width: 40px;
    height: 40px;
    background-color: $color-primary-5;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-left: 15px;
    margin-right: 15px;
    color: $color-primary-100;
    flex-grow: 0;

    &.with-input {
      label {
        width: 230px;
        padding-left: 20px;
        display: none;
        color: $color-primary-70;
      }
      &:focus-within,
      &.has-text {
        padding-left: 10px;

        label {
          display: none;
        }

        input {
          color: $color-primary-100;
          margin-left: 10px;
          width: 100% !important;

          &::placeholder {
            color: $color-primary-50;
          }
        }
      }
      input {
        background-color: transparent;
        border: none;
        width: 0;
      }
      @media (min-width: 1200px) {
        label {
          display: block;
        }
      }
    }

    @media (min-width: 1200px) {
      position: absolute;
      top: 50%;
      right: clamp(650px, 50%, 1000px);
      width: clamp(100px, 450px, 100% - 750px);
      transform: translate(50%, -50%);
      border-radius: 5px;
      margin-right: 0;
    }

    &:hover {
      cursor: pointer;
      background-color: $color-primary-10;
    }

    body.wallet & {
      @media (min-width: 1200px) {
        width: 450px;
      }
    }

    span {
      display: flex;
      align-items: center;
      color: $color-primary-70;
      font-size: 0;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      position: relative;

      @media (min-width: 1200px) {
        font-size: 12px;
      }

      svg {
        width: 16px;
        height: auto;
        margin-right: 10px;

        @media (max-width: 1199px) {
          position: absolute;
          top: 50%;
          left: 50%;
          @include transform(translate(-50%, -50%));
          margin-right: 0;
        }
      }
    }
  }

  .search-input {
    background-color: $color-primary-5;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    position: relative;
    width: 80%;
    max-width: 325px;
    margin: 0 12px;
    flex-shrink: 1;

    .search-icon,
    .close-icon {
      color: $color-primary-100;
      position: absolute;
      top: 50%;
      display: inline-block;
      width: 15px;
      height: 15px;
      @include transform(translateY(-50%));
    }

    .search-icon {
      left: 10px;
    }

    .close-icon {
      right: 10px;

      &:hover {
        cursor: pointer;
      }
    }

    > input {
      outline: none;
      color: $color-primary-100;
      padding: 10px 10px 10px 40px;
      background-color: $color-primary-5;
      border: 2px solid $color-primary-5;
      border-radius: 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      flex-grow: 1;

      &::placeholder {
        color: $color-primary-70;
      }

      &:focus,
      &.active {
        border-color: $color-primary-10;
        background-color: $color-primary-10;
      }
    }
  }

  .right-side-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 0;
    margin-left: auto;

    .upgrade-button {
      display: none;
      margin-right: 12px;

      @media all and (min-width: $screen-lg) {
        display: flex;
      }
    }

    .username-dropdown {
      position: relative;
      padding: 5px 4px 5px 8px;
      border-radius: 8px;
      cursor: pointer;
      transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);

      &:hover {
        transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
        background-color: $color-primary-10;
      }

      .username {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-primary-100;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.5px;
        cursor: pointer;

        .text {
          margin-right: 12px;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            color: $color-primary-100;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            max-width: 100px;
            display: inline-block;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }

        .icon {
          width: 32px;
          height: 32px;
          background: $color-primary-100;
          color: $color-primary-0;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 20px;
          position: relative;

          &:after {
            content: "";
            position: absolute;
            right: -15px;
            top: 50%;
            @include transform(rotate(45deg));
            transform-origin: center center;
            width: 8px;
            height: 8px;
            border: 2px solid $color-primary-100;
            border-top-width: 0;
            border-left-width: 0;
            border-bottom-right-radius: 2px;
            margin-top: -4px;
          }

          span {
            text-transform: uppercase;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.2px;

            svg {
              width: 12px;
              height: auto;
              margin-right: 0;
            }
          }
        }
      }

      .dropdown {
        position: absolute;
        top: 44px;
        right: 0;
        width: 240px;
        max-height: calc(100vh - 56px);
        background-color: $color-background;
        border-radius: 8px;
        box-shadow: 0px 5px 40px 0px rgba(0, 0, 0, 0.16);
        border-radius: 8px;
        opacity: 0;
        visibility: hidden;
        padding: 12px 0;
        color: $color-primary-100;
        overflow: auto;
        @include custom-scroll-bar();
        transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);

        &.active {
          opacity: 1;
          visibility: visible;
          top: 36px;
        }

        .user {
          display: flex;
          align-items: center;
          padding: 8px 6px;
          margin: 2px 10px;
          border-radius: 8px;
          cursor: pointer;

          transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);

          &:hover {
            transform: scale(1.01) translate3d(0, 0, 0);
            background-color: $color-primary-10;
            transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
          }

          &.disabled {
            pointer-events: none;

            .text {
              &:after {
                display: none;
              }
            }
          }

          .user-icon {
            margin-right: 8px;
          }

          .icon {
            width: 32px;
            height: 32px;
            background-color: $color-primary-100;
            color: $color-primary-0;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 8px;
            position: relative;

            span {
              text-transform: uppercase;
              color: $color-primary-0;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              letter-spacing: -0.2px;

              svg {
                width: 12px;
                height: auto;
                margin-right: 0;
              }
            }
          }

          .text {
            width: calc(100% - 40px);
            padding-right: 30px;
            position: relative;

            &:after {
              content: "";
              position: absolute;
              right: 6px;
              top: 50%;
              @include transform(rotate(-45deg));
              transform-origin: center center;
              width: 8px;
              height: 8px;
              border: 1.5px solid $color-primary-70;
              border-top-width: 0;
              border-left-width: 0;
              border-bottom-right-radius: 2px;
              margin-top: -4px;
            }

            span {
              color: $color-primary-100;
              display: block;
              width: 100%;

              &.name {
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                overflow: hidden;
              }

              &.info {
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              }
            }
          }
        }

        .button {
          margin: 6px 12px 18px 12px;

          span {
            color: $color-primary-100;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.5px;
            width: 100%;
            border: 2px solid $color-primary-100;
            border-radius: 999px;
            padding: 8px 12px;
            display: block;
            text-align: center;
            overflow: hidden;
          }

          &:hover {
            span {
              cursor: pointer;
              background-color: $color-primary-10;
            }
          }
        }

        .divider {
          height: 1px;
          background-color: $color-primary-10;
          margin: 12px 0;
        }

        .item {
          padding: 8px 6px;
          margin: 2px 10px;
          height: 40px;
          display: flex;
          align-items: center;
          cursor: pointer;
          position: relative;
          border-top: 0.5 solid transparent;
          border-bottom: 0.5 solid transparent;
          border-radius: 8px;
          transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
          &.version {
            color: $color-primary-50;
            cursor: default;
            pointer-events: none;
            > span {
              color: $color-primary-50;
              cursor: default;
            }
          }

          &:hover {
            background-color: $color-primary-10;
            border-top: 0.5 solid $color-primary-10;
            border-bottom: 0.5 solid $color-primary-10;
            transform: scale(1.01) translate3d(0, 0, 0);
            transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
            box-shadow: 0px 0px 18px rgba($color-primary-100-light, 0.15);

            @at-root .theme-dark & {
              box-shadow: 0px 0px 18px rgba($color-primary-100-dark, 0.15);
            }
          }

          &.link {
            &:after {
              content: "";
              position: absolute;
              right: 18px;
              top: 50%;
              @include transform(rotate(-45deg));
              transform-origin: center center;
              width: 8px;
              height: 8px;
              border: 1.5px solid $color-primary-70;
              border-top-width: 0;
              border-left-width: 0;
              border-bottom-right-radius: 2px;
              margin-top: -4px;
            }
          }

          &.download {
            &:after {
              content: "";
              position: absolute;
              right: 18px;
              top: 50%;
              @include transform(rotate(-90deg));
              transform-origin: center center;
              width: 8px;
              height: 8px;
              border: 1.5px solid $color-primary-70;
              border-top-width: 0;
              border-left-width: 0;
              border-bottom-right-radius: 2px;
              margin-top: -4px;
            }

            &:before {
              content: "";
              position: absolute;
              right: 18px;
              top: 50%;
              @include transform(rotate(45deg));
              transform-origin: top right;
              width: 1.5px;
              height: 12px;
              margin-top: -3px;
              border-radius: 2px;
              background: $color-primary-70;
            }
          }

          &.toggle {
            button {
              position: absolute;
              right: 12px;
              top: 50%;
              @include transform(translateY(-50%));
              width: 26px;
              height: 16px;
              border-radius: 8px;
              background-color: $color-primary-30;
              border: none;

              span {
                position: absolute;
                left: 2px;
                top: 2px;
                width: 12px;
                height: 12px;
                border-radius: 6px;
                background-color: $color-primary-0;
                @include transition(all 0.2s ease-in-out);
              }

              &.enabled {
                background-color: $color-success;

                span {
                  left: 12px;
                  background-color: $color-primary-10;
                }
              }
            }
          }

          svg {
            width: 24px;
            height: 24px;
            padding: 4px;
            margin-right: 8px;
            color: $color-primary-90;
          }

          span {
            color: $color-primary-100;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
      }
    }
  }
}

.activity-icons-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  .activity-icon-wrapper {
    background-color: transparent;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
    svg {
      width: 16px;
      height: 16px;
      color: $color-primary-100;
    }
    &:hover {
      background-color: $color-primary-10;
      cursor: pointer;
      transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
      svg {
        transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
        transform: scale(1.1) translate3d(0, 0, 0);
      }
    }
    &.color-warning {
      svg {
        color: $color-warning;
      }
    }
  }
  .vertical-separator {
    width: 1px;
    height: 19px;
    background-color: $color-primary-10;
  }
}

.capitalize {
  text-transform: capitalize;
}
.muted-icon {
  color: $color-primary-90;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: $color-primary-10;
  }
}
</style>
