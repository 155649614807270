import store from "@/store";
import { ref, watch } from "vue";
import { toValue } from "@vueuse/core";
import { PAYMENT_PROVIDER } from "@/composables/useSubscriptionPlans";

export const usePaymentIntent = (plan, promoCode, showCountdown) => {
  const isLoadingIntent = ref(false);
  const paymentIntent = ref(null);

  const fetchIntent = async () => {
    paymentIntent.value = null;

    if (toValue(plan)?.provider !== PAYMENT_PROVIDER.STRIPE) {
      return;
    }

    isLoadingIntent.value = true;
    paymentIntent.value = await store
      .dispatch("subscription/fetchIntent", {
        productId: toValue(plan)?.product_id,
        promoCode: promoCode?.value?.code,
      })
      .finally(() => {
        isLoadingIntent.value = false;
      });
  };

  watch(
    () => ({
      productId: toValue(plan)?.product_id,
      code: promoCode?.value?.code,
    }),
    (newValue, oldValue) => {
      if (showCountdown?.value && !newValue.code) {
        return;
      }

      if (
        newValue.productId !== oldValue?.productId ||
        newValue.code !== oldValue?.code
      ) {
        fetchIntent();
      }
    },
    { immediate: true }
  );

  return {
    isLoadingIntent,
    paymentIntent,
  };
};
