import { computed } from "vue";
import store from "@/store";
import { useFormattedPrice } from "@/composables";

const planTypeToDisplayName = {
  individual: "Individual",
  couple: "Couple",
  family: "Family",
};

const getType = (plan) =>
  plan.max_members === 1
    ? "individual"
    : plan.max_members === 2
    ? "couple"
    : "family";

const getDisplayName = (plan) => planTypeToDisplayName[getType(plan)];

const withDisplayInfo = (plans) =>
  plans.map((plan) => {
    const monthlyPrice =
      plan.recurring_interval === "annually" ? plan.price / 12 : plan.price;

    const monthlyPricePerUser = monthlyPrice / plan.max_members;

    return {
      ...plan,
      monthlyPricePerUser,
      type: getType(plan),
      displayName: getDisplayName(plan),
      displayTotalPrice: useFormattedPrice(plan.price),
      displayMonthlyPricePerUser: useFormattedPrice(monthlyPricePerUser),
      displayBillingCycle:
        plan.recurring_interval === "annually" ? "yearly" : "monthly",
    };
  });

const withDiscounts = (plans) =>
  plans.reduce((result, plan, index, originalPlans) => {
    const planToCompareAgainst = originalPlans.find(
      (originalPlan) =>
        originalPlan.type === "individual" &&
        originalPlan.recurring_interval === "monthly"
    );

    const discount =
      plan.type === "individual"
        ? 0
        : Math.ceil(
            (1 -
              plan.monthlyPricePerUser /
                planToCompareAgainst.monthlyPricePerUser) *
              100
          );

    return [...result, { ...plan, discount }];
  }, []);

export const usePlans = () => {
  const allPlans = computed(() =>
    withDiscounts(
      withDisplayInfo(store.getters["subscription/getPlans"] ?? [])
    ).sort((a, b) => a.max_members - b.max_members)
  );

  const isLoadingPlans = computed(() => !allPlans.value?.length);

  const activeSubscription = computed(() =>
    store.getters["settings/isSubscribed"]
      ? store.getters["settings/getSubscription"]
      : null
  );

  const activePlan = computed(() =>
    activeSubscription.value
      ? {
          product_id: activeSubscription.value.product_identifier,
          max_members: activeSubscription.value.max_members,
          recurring_interval: activeSubscription.value.recurring_interval,
          owner: activeSubscription.value.owner,
          provider: activeSubscription.value.store,
          type: getType(activeSubscription.value),
          displayName: getDisplayName(activeSubscription.value),
        }
      : null
  );

  return {
    isLoadingPlans,
    allPlans,
    activePlan,
  };
};
