<script setup>
import { useRoute } from "@/hooks";

import store from "@/store";
import {
  SHOWN_PLUGIN,
  HAS_ONBOARDED_V4,
  HAS_EXITED_DELETE_FLOW,
  DATA_DELETE_REQUESTED,
} from "@/scripts/userFlags";

import User from "./User";
import router from "@/routes/router";
import { constants } from "@/scripts";
import SecretKey from "@/routes/SecretKey";
import { appBoot, logout } from "@/scripts/actions/auth";
import Loading from "@/components/ui/loading.vue";
import CardsServices from "@/api/actions/cards-services";
import { dbMixin, getLatestTimestamp } from "@/mixins/db";
import OnboardingPage from "@/components/feature/onboarding/page/OnboardingPageContainer.vue";
import { isMobileDevice } from "@/scripts/regex";
import FaunaBrokeBanner from "@/components/banners/FaunaBroke.vue";

const route = useRoute();

import {
  reactive,
  onBeforeUnmount,
  nextTick,
  computed,
  watch,
  onMounted,
  onBeforeMount,
  ref,
} from "vue";
import UserService from "@/api/actions/user-service";
import DownloadApp from "@/components/feature/onboarding/DownloadApp.vue";
import DeleteFlow from "@/routes/DataDeletion/Submit/DeleteFlow";
import { getPosthog } from "@/scripts/posthog";
import { usePostHogFeatureFlag } from "@/composables";

const hasLoaded = ref(false);

onBeforeMount(() => {
  getPosthog().then(() => {
    store.dispatch("welcomeUi/loadWelcomeUi");
    store.dispatch("dataDelete/loadFeatureFlags");
  });
});

onMounted(async () => {
  await authCheck();

  await UserService.getFlags();
  window.addEventListener("logout", logoutListen);

  window.addEventListener("storage", (event) => {
    if (event.storageArea != localStorage) return;
    if (event.key === "vuex") {
      if (event.newValue) {
        const local = store.state.authentication.auth?.access_token;
        const newStore = JSON.parse(event.newValue);
        const updated = newStore.authentication.auth?.access_token;
        if (local !== updated && newStore.authentication.auth) {
          store.dispatch("authentication/setAccessToken", {
            oauth: newStore.authentication.auth,
          });
        }
      }
    }
  });

  hasLoaded.value = true;
});

onBeforeUnmount(() => {
  window.removeEventListener("logout", logoutListen);
});

const state = reactive({
  exitDownload: false,
  bootReady: false,
  offset: "",
  mobileDevice: false,
  enablePay: route?.query?.enable_pay === "pay_2024",
});

const subscription = computed(() => {
  return store.getters["settings/getSubscription"];
});

const importStatus = computed(() => {
  return store.state.accountsImporter.importStatus;
});
const overflow = computed(() => {
  return store.state.ui.bodyOverflowHidden;
});
const hasVisibleBanner = computed(() => {
  return store.state.hasVisibleBanner;
});

const dbLoaded = computed(() => {
  return store.state.localdb.dbLoaded;
});

const rightPanelState = computed(() => {
  return store.getters["getRightPanelStatus"];
});

const hasPlugin = computed(() => {
  return store.getters["browser/pluginDetected"];
});

const showDownload = computed(() => {
  return !hasPlugin.value && !shownPlugin.value;
});

const authenticated = computed(() => {
  return store.getters.auth_token;
});

const user = computed(() => {
  return store.state.authentication?.user;
});

const shouldClipBody = computed(() => {
  return store.state.ui.open_dialog;
});

const shownPlugin = computed(() => {
  return store.getters.getFlag(SHOWN_PLUGIN);
});

const shouldGetKeys = computed(() => {
  const userIsV2User =
    store.state.authentication?.user?.encryption_status === 3;
  if (userIsV2User) {
    return false;
  } else if (
    store.state.authentication?.user &&
    store.state.authentication?.user?.flags?.encryption &&
    (!store.state.encryption || !store.state.encryption.secret_key)
  ) {
    return true;
  }
  return false;
});

const userCountry = computed(() => {
  return user?.value?.country_at_registration
    ? user?.value?.country_at_registration
    : user?.value?.current_country;
});

const isSupportedCardCountry = computed(() => {
  return constants.SUPPORTED_CARDS_COUNTRIES.includes(userCountry.value);
});

const canEnablePay = computed(() => {
  return (
    (store.getters["settings/isSubscribed"] ||
      store.getters["settings/isTrial"] ||
      store.getters["settings/isLegacy"]) &&
    isSupportedCardCountry.value
  );
});

watch(
  () => subscription.value,
  (newVal, oldVal) => {
    if (!oldVal && newVal) {
      nextTick(() => showPayModal());
    }
  },
  { immediate: true, deep: true }
);

watch(
  () => rightPanelState.value,
  (value) => {
    if (value === true) {
      document.body.classList.add("active-right-panel");
    } else {
      document.body.classList.remove("active-right-panel");
    }
  }
);

watch(
  () => showDownload.value,
  (value) => {
    if (!value) {
      setTimeout(() => {
        state.exitDownload = true;
      }, 5000);
    }
  }
);

watch(
  () => authenticated.value,
  (value) => {
    if (!value) {
      if (window.$posthog) {
        window.$posthog?.reset();
      }
      logout();
    }
  }
);

watch(
  () => user.value,
  (user) => {
    // If the user doesn't have a uuid, then we leave them anonymous,
    // otherwise we call identify so events are associated with the user.
    if (user.posthog_uuid && window.$posthog) {
      window.$posthog?.identify(
        store?.state?.authentication?.user?.posthog_uuid
      );
    }
  }
);

watch(
  () => shouldClipBody.value,
  (value) => {
    if (value) {
      state.offset = window.scrollY.toString();
    } else {
      const reset_top = parseInt(state.offset, 10);
      state.offset = 0;
      window.scrollTo(0, reset_top);
      setTimeout(() => {
        window.scrollTo(0, reset_top);
      }, 3);
    }
  }
);

watch(
  () => overflow.value,
  (value) => {
    if (value === true) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }
);

function showPayModal() {
  if (state.enablePay) {
    const newQuery = { ...route.query };
    delete newQuery.enable_pay;
    router.replace({
      query: newQuery,
    });
    if (canEnablePay.value) {
      CardsServices.enableSettings({
        enable_pay: "pay_2024",
      }).then(() => {
        if (store.getters["settings/isTrial"]) {
          store.dispatch("openModal", {
            header: "Congratulations you're an early user for Cloaked Pay!",
            subheader:
              "To enable Cloaked Pay, please upgrade with this promo code <b>FAM2024</b> for 80% off the monthly subscription.",
            button: {
              text: "Upgrade now",
              onClick: () =>
                store.dispatch("subscription/openSubscriptionModal", {
                  promoCode: "FAM2024",
                  callback: () => router.push({ name: "Wallet" }),
                }),
            },
          });
        } else if (
          store.getters["settings/isSubscribed"] ||
          store.getters["settings/isLegacy"]
        ) {
          store.dispatch("openModal", {
            header: "Congrats, you got early access to Cloaked Pay",
            button: {
              text: "To Wallet page",
              onClick: () => router.push({ name: "Wallet" }),
            },
          });
        }
      });
    }
  }
}

function logoutListen() {
  state.bootReady = false;
  logout();
}

function handleKey(message) {
  if (message.data.success) {
    const handler = () => {
      window.dispatchEvent(new Event("storage"));
      window.removeEventListener("storage:write", handler);
    };
    window.addEventListener("storage:write", handler);
    setTimeout(() => {
      store.commit("setSecretKey", message.data.secretKey);
      try {
        // NOTE: where does this come from?
        // initiate();
        store.commit("setEncryptionStatus", true);
      } catch {
        store.commit("setEncryptionStatus", false);
      }
    }, 20);
  }
}

async function checkLoaded() {
  state.exitDownload = hasPlugin.value || shownPlugin.value;
  await dbMixin.methods.frontLoadCloaks(true);

  nextTick(() => {
    store.dispatch("init");
  });
}

async function authCheck() {
  await appBoot();
  await checkLoaded();
}

const showNewOnboarding = computed(() => {
  return store.state.authentication?.user?.flags
    ?.show_dd_post_payment_onboarding;
});
const ddOnboardingEnabled = computed(() => {
  return store.getters["dataDelete/getDdOnboardingEnabled"];
});

const shouldOnboard = computed(() => {
  return (
    store.state.authentication?.user?.flags?.show_onboarding_v4 &&
    !store.getters.getFlag(HAS_ONBOARDED_V4) &&
    (ddOnboardingEnabled.value ? !showNewOnboarding.value : true)
  );
});

const shouldSubmitDelete = computed(() => {
  return (
    store.getters.getFlag(DATA_DELETE_REQUESTED) &&
    store.getters["settings/isSubscribed"] &&
    !store.getters.getFlag(HAS_EXITED_DELETE_FLOW)
  );
});

const shouldDownloadApp = computed(
  () =>
    isMobileDevice &&
    store.getters["screen/isSmallScreen"] &&
    route.path !== "/settings/subscription" &&
    !route.path.includes("/invitation/")
);

const { featureFlag } = usePostHogFeatureFlag("cloaked-experiencing-downtime");
const faunaBroke = computed(() => featureFlag.value === true);
</script>

<template>
  <div
    id="app"
    class="app"
    :class="{
      'app--visible-banner': hasVisibleBanner,
    }"
  >
    <FaunaBrokeBanner v-if="faunaBroke" />
    <div v-if="hasLoaded && dbLoaded" class="app-div">
      <SecretKey
        @message="handleKey"
        v-if="shouldGetKeys"
        :hasPlugin="hasPlugin || shownPlugin"
      />
      <DeleteFlow v-else-if="shouldSubmitDelete" />
      <OnboardingPage v-else-if="shouldOnboard" />
      <DownloadApp v-else-if="shouldDownloadApp" />
      <User v-else />
    </div>
    <Loading v-else />
  </div>
</template>

<style lang="scss">
[data-lastpass-icon-root] {
  display: none !important;
}
#app {
  height: 100vh;
  .app-div {
    height: 100%;
    .app-wrapper {
      height: 100%;
      .content-container {
        height: 100%;
        > div {
          height: 100%;
        }
      }
    }
  }
}
.highlight-text {
  font-weight: 600;
  color: $color-info;
}
</style>
