<script setup>
import AppModalClose from "@/components/ui/AppModalClose";
import AppModalTitle from "@/components/ui/AppModalTitle";
import AppModalContent from "@/components/ui/AppModalContent";
import AppModalImage from "@/components/ui/AppModalImage";
import AppModalParagraph from "@/components/ui/AppModalParagraph";
import AppModalFooter from "@/components/ui/AppModalFooter";
import Button from "@/components/Button.vue";
import { UK_NUMBER_SUPPORT_END_DATE_ORDINAL } from "@/scripts/constants";

const props = defineProps({
  number: {
    type: String,
    default: "12",
  },
  title: {
    type: String,
    default: "",
  },
  buttonAction: {
    type: Function,
    default: () => {},
  },
});

const emit = defineEmits(["close"]);
const handleActionButtonClick = () => {
  if (props.buttonAction) {
    props.buttonAction();
  }
  emit("close");
};
</script>
<template>
  <AppModalContent>
    <AppModalClose is-absolute @close="emit('close')" />
    <AppModalImage
      :src="require('@/assets/images/esim-all.png')"
      :alt="props.title"
    />
    <AppModalTitle>
      {{ props.title }}
    </AppModalTitle>
    <AppModalParagraph>
      A change in UK regulatory requirements will impact {{ props.number }} of
      your phone numbers.
    </AppModalParagraph>
    <AppModalParagraph class="urgent-text">
      Cloaked can help, but you must act before
      {{ UK_NUMBER_SUPPORT_END_DATE_ORDINAL }}.
    </AppModalParagraph>
    <AppModalParagraph>
      To prepare for this upcoming change, use the button below.
    </AppModalParagraph>
    <AppModalFooter>
      <Button type="secondary" @click="handleActionButtonClick"
        >Learn more</Button
      >
    </AppModalFooter>
  </AppModalContent>
</template>

<style scoped lang="scss">
.urgent-text {
  text-decoration: underline;
  color: $color-alert;
}
</style>
