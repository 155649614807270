<script setup>
import { computed, defineProps, ref, markRaw } from "vue";
import DataRemovalRequestsRow from "@/routes/DataDeletion/components/RequestRows/DataRemovalRequestsRow.vue";
import store from "@/store";
import moment from "moment/moment";
import ActionRequiredMarkedDoneModal from "@/components/modals/data-delete/ActionRequiredMarkedDoneModal.vue";
import {
  PH_EVENT_DATA_REMOVAL_USER_CLICKED_REVIEW,
  PH_EVENT_DATA_REMOVAL_USER_CLICKED_MARK_DONE,
  PH_EVENT_DATA_REMOVAL_USER_CLICKED_MARK_DONE_CONFIRMATION,
} from "@/scripts/posthogEvents";
import UiActionRow from "@/routes/DataDeletion/UiActionRow.vue";
import { posthogCapture } from "@/scripts/posthog.js";
import AtomSkeletonLoaderRow from "@/components/Atoms/AtomSkeletonLoaderRow.vue";
import DataDeleteService from "@/api/actions/data-delete-service";

const props = defineProps({
  requests: {
    type: Array,
    required: true,
  },
  actionRequiredFamilies: {
    type: Array,
    default: () => [],
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const defaultListSize = 5;

const showAll = ref(false);

const limitedRequests = computed(() => {
  return showAll.value
    ? props.requests
    : props.requests.slice(0, defaultListSize);
});

const removalDate = computed(() => {
  return store.getters["dataDelete/removalLogData"]?.removalDate ?? new Date();
});

const formattedDate = computed(() => {
  return moment(removalDate.value).format("MMMM Do, YYYY");
});

function toggleShowAll() {
  showAll.value = !showAll.value;
}

function toggleOpen(request, forceOpen = false) {
  const requestsCopy = [...props.requests]?.map((req) => {
    if (req.id === request.id) {
      req.opened = forceOpen ? forceOpen : !req.opened;
    } else {
      // Only one should be opened at a time
      req.opened = false;
    }
    return req;
  });
  return store.dispatch("dataDelete/updateRemovalLogBrokerList", requestsCopy);
}

function showMarkDoneConfirmation(brokerFamily) {
  store.dispatch("openModal", {
    customTemplate: {
      template: markRaw(ActionRequiredMarkedDoneModal),
      props: {
        name: brokerFamily?.name,
      },
      events: {
        done: () => markDone(brokerFamily),
        review: () => toggleOpenBrokerFamily(brokerFamily, true),
      },
    },
  });

  posthogCapture(PH_EVENT_DATA_REMOVAL_USER_CLICKED_MARK_DONE, {
    broker_name: brokerFamily.name,
  });
}

function markDone(brokerFamily) {
  DataDeleteService.markBrokerFamilyDone(brokerFamily.id)
    .then(() => {
      return DataDeleteService.getRemovalLog().catch(() => {});
    })
    .catch(() => {});
  posthogCapture(PH_EVENT_DATA_REMOVAL_USER_CLICKED_MARK_DONE_CONFIRMATION, {
    broker_name: brokerFamily.name,
  });
}

function toggleOpenBrokerFamily(brokerFamily, forceOpen = false) {
  const actionRequiredFamiliesCopy = [...props.actionRequiredFamilies]?.map(
    (bf) => {
      if (bf.id === brokerFamily.id) {
        bf.opened = forceOpen ? forceOpen : !bf.opened;
        if (!bf.opened) {
          posthogCapture(PH_EVENT_DATA_REMOVAL_USER_CLICKED_REVIEW);
        }
      } else {
        // Only one should be opened at a time
        bf.opened = false;
      }
      return bf;
    }
  );
  return store.dispatch(
    "dataDelete/setActionRequiredFamilies",
    actionRequiredFamiliesCopy
  );
}
</script>

<template>
  <div class="removal-requests">
    <div class="requests-table">
      <div class="requests-table-header">
        <div class="requests-table-cell">Broker</div>
        <div class="requests-table-cell">Status</div>
        <div class="requests-table-cell long">Removal progress</div>
        <div class="requests-table-cell short">Progress</div>
      </div>
      <AtomSkeletonLoaderRow
        v-if="props.loading"
        :numOfRows="defaultListSize"
        height="73px"
      />
      <div class="requests-table-body" v-else>
        <UiActionRow
          v-for="(brokerFamily, idx) in props.actionRequiredFamilies"
          :key="`${brokerFamily?.name}-${idx}`"
          :name="brokerFamily?.name"
          :count="brokerFamily?.brokers?.length ?? 0"
          :opened="brokerFamily?.opened"
          :brokers="brokerFamily?.brokers"
          :instructions="brokerFamily?.removal_steps"
          :date="formattedDate"
          @done="showMarkDoneConfirmation(brokerFamily)"
          @toggleOpen="toggleOpenBrokerFamily(brokerFamily)"
        />
        <DataRemovalRequestsRow
          v-for="(request, index) in limitedRequests"
          :key="`${index}-${request.broker_name}`"
          :request="request"
          @open="() => toggleOpen(request)"
        />
      </div>
    </div>
    <button
      class="toggle-button"
      @click="toggleShowAll"
      v-if="props.requests.length > defaultListSize"
    >
      {{ showAll ? "Show fewer" : "Show all" }}
    </button>
  </div>
</template>

<style scoped lang="scss">
.removal-requests {
  background-color: $color-surface;
  border-radius: 12px;
  margin-top: 8px;
}

.title {
  font-size: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  color: $color-primary-100;
  font-size: 18px;
  margin-bottom: 8px;
}

.pulsing-svg {
  margin-left: 8px;
}

.description {
  border-bottom: 1px solid $color-primary-10;
}

.description p {
  margin-bottom: 24px;
  font-size: 12px;
  color: $color-primary-70;
}

.summary {
  display: flex;
  justify-content: flex-start;
  margin: 20px 0;
  gap: 16px;
}
.summary-item {
  text-align: left;
  border: 1px solid $color-primary-100;
  padding: 16px;
  border-radius: 14px;
  cursor: pointer;
  color: $color-primary-100;
  font-size: 12px;
  transition: all 0.3s ease;
  .summary-label {
    @include line-clamp(2);
  }

  &.active {
    background-color: $color-primary-100;
    border: 1px solid $color-primary-100;
    color: $color-primary-0;
    transition: all 0.3s ease;
    .summary-value,
    .summary-label {
      color: $color-primary-0;
      transition: all 0.3s ease;
    }
    &:hover {
      transition: all 0.3s ease;
      transform: scale(1.05);
      opacity: 0.7;
      background-color: $color-primary-100;
    }
  }
  &:hover {
    transition: all 0.3s ease;
    transform: scale(1.05);
    background-color: $color-primary-10;
  }
  &.disabled {
    opacity: 0.5;
    transform: none;
    background-color: transparent;
    cursor: not-allowed;
  }
}

.summary-value {
  font-size: 18px;
  font-weight: 600;
  &.all-requests {
    color: $color-brand-3-100;
  }
  &.in-progress-requests {
    color: $color-in-progress;
  }
  &.removed-requests {
    color: $color-success;
  }
  &.no-records-requests {
    color: $color-lt-green;
  }
  &.scanning-requests {
    color: $color-primary-30;
  }
}

.requests-table {
  width: 100%;
  border-collapse: collapse;
  color: $color-primary-100;

  .requests-table-header {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.2px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    .requests-table-cell {
      display: flex;
      flex: 1;
      text-align: left;
      margin-bottom: 16px;

      &.short {
        display: none;
      }
      &.long {
        display: flex;
      }
      @media (max-width: 555px) {
        &.short {
          display: flex;
        }
        &.long {
          display: none;
        }
      }
    }
  }

  .requests-table-body {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.toggle-button {
  width: 100%;
  padding: 10px;
  border: 1px solid $color-primary-100;
  border-radius: 8px;
  background-color: $color-surface;
  cursor: pointer;
  margin-top: 32px;
  color: $color-primary-100;
  transition: all 0.3s ease-in;
  &:hover {
    background-color: $color-primary-100;
    color: $color-primary-0;
    transition: all 0.3s ease-in;
  }
}
</style>
