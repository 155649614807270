<script setup>
import InlineSvg from "@/components/InlineSvg.vue";
import { UiMenu, UiMenuButton } from "@/components";
import { ref, computed } from "vue";

const props = defineProps({
  label: {
    type: String,
    required: false,
    default: null,
  },
  value: {
    type: String,
    required: false,
  },
  placeholder: {
    type: String,
    required: false,
    default: "Select",
  },
  maxHeight: {
    type: String, // ex: '100px'
    required: false,
  },
  options: {
    type: Array,
    required: true,
  },
  dropdownWidth: {
    type: String, // ex: '100px'
    required: false,
  },
  error: {
    type: Boolean,
    default: false,
  },
  errorMessage: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(["select"]);

const dropdownRef = ref(null);

const dropdownWidth = computed(() => {
  if (props.dropdownWidth) {
    return props.dropdownWidth;
  }
  if (dropdownRef?.value?.clientWidth) {
    return `${dropdownRef?.value?.clientWidth}px`;
  }
  return undefined;
});
</script>
<template>
  <div
    ref="dropdownRef"
    class="atom-dropdown-wrapper"
    :class="{ error: props.error }"
  >
    <div v-if="props.label" class="atom-input-wrapper--label">
      {{ props.label }}
    </div>
    <UiMenu
      placement="bottom-end"
      :width="dropdownWidth"
      :offsetAway="8"
      :maxHeight="props.maxHeight"
    >
      <div class="atom-input-wrapper">
        <div v-if="props.value">{{ props.value }}</div>
        <div class="placeholder" v-else>{{ props.placeholder }}</div>
        <InlineSvg name="chevron-down" />
      </div>
      <template #content>
        <UiMenuButton
          v-for="(option, idx) in options"
          :key="`dropdown-${option}-${idx}`"
          :title="option"
          @click="emit('select', option)"
        ></UiMenuButton>
      </template>
    </UiMenu>
    <div
      v-if="props.error && props.errorMessage"
      class="atom-input-wrapper--label error"
    >
      {{ props.errorMessage }}
    </div>
  </div>
</template>
<style scoped lang="scss">
.atom-dropdown-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.1px;
  color: $color-primary-100;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  &.error {
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    color: $color-alert;
    .atom-input-wrapper--label {
      transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      color: $color-alert;
    }
    .atom-input-wrapper {
      transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      border-color: $color-alert;
    }
  }
  .atom-input-wrapper {
    width: 100%;
    padding: 8px 16px;
    font-size: 15px;
    color: $color-primary-100;
    outline: none;
    border-radius: 10px;
    height: $input-height;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: $color-primary-1;
    border: 1px solid $color-primary-30;
    @at-root .theme-dark & {
      background: transparent;
    }
    .placeholder {
      color: $color-primary-30;
    }
  }
}
</style>
