import { computed, onUnmounted, ref, watch } from "vue";

export const TIME_LIMITED_DISCOUNT_PROMO_CODE = "COUNTDOWN20";

const showCountdown = ref(false);
const countdownTime = ref(5 * 60);
let countdownTimer = null;

export const useTimeLimitedDiscount = (
  selectedPlan,
  promoCodeInput,
  validatePromoCode
) => {
  const startCountdown = () => {
    if (countdownTimer) {
      return;
    }
    const unwatch = watch(
      () => selectedPlan.value,
      (plan) => {
        if (plan) {
          promoCodeInput.value = TIME_LIMITED_DISCOUNT_PROMO_CODE;
          validatePromoCode();
        }
      },
      { immediate: true }
    );

    showCountdown.value = true;
    countdownTimer = setInterval(() => {
      if (countdownTime.value > 0) {
        countdownTime.value--;
      } else {
        showCountdown.value = false;
        unwatch();
        promoCodeInput.value = "";
        clearInterval(countdownTimer);
        countdownTimer = null;
      }
    }, 1000);
  };

  onUnmounted(() => {
    clearInterval(countdownTimer);
  });

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes} minute${
      minutes !== 1 ? "s" : ""
    } and ${remainingSeconds} second${remainingSeconds !== 1 ? "s" : ""}`;
  };

  const formattedTime = computed(() => formatTime(countdownTime.value));

  return {
    startCountdown,
    countdownTime,
    showCountdown,
    formattedTime,
  };
};
