<script setup>
import { computed } from "vue";
import store from "@/store";
import AtomIdentityMiniCards from "@/components/Atoms/AtomIdentityMiniCards.vue";

const recentlyUsed = computed(() => {
  if (store.state.localdb?.db_cloaks) {
    return [...store.state.localdb.db_cloaks]
      .sort((a, b) => {
        return new Date(a.updated_at) < new Date(b.updated_at) ? 1 : -1;
      })
      .filter((c) => !c.protected)
      .slice(0, 10);
  }
  return [];
});
</script>
<template>
  <section class="recents" v-if="recentlyUsed.length">
    <h1 class="title">Recent Identities</h1>
    <AtomIdentityMiniCards
      :identities="recentlyUsed"
      posthogEvent="user_clicked_homepagerecentidentity"
      :previewFields="['nickname', 'updated_at']"
    />
  </section>
</template>
<style lang="scss" scoped>
.title {
  font-size: 24px;
  font-style: normal;
  color: $color-primary-100;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.5px;
}
</style>
