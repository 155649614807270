import store from "@/store";
import { computed, onBeforeMount, ref } from "vue";
import { useFormattedPrice } from "@/composables/useFormattedPrice";

export const PAYMENT_PROVIDER = {
  STRIPE: "stripe",
  PAYPAL: "paypal",
};

export const BILLING_CYCLE = {
  MONTHLY: "monthly",
  ANNUAL: "annually",
};

export const useSubscriptionPlans = (
  defaultBillingCycle = null,
  defaultPaymentProvider = null
) => {
  const isLoadingPlans = ref(true);

  onBeforeMount(() => {
    store.dispatch("subscription/init").then(() => {
      isLoadingPlans.value = false;
    });
  });

  const selectedBillingCycle = ref(defaultBillingCycle);
  const selectedPaymentProvider = ref(defaultPaymentProvider);

  const allPlans = computed(() =>
    store.getters["subscription/getPlans"].map((plan) => ({
      ...plan,
      monthlyPrice:
        plan.recurring_interval === BILLING_CYCLE.ANNUAL
          ? useFormattedPrice(plan.price / 12)
          : useFormattedPrice(plan.price),
      totalPrice: useFormattedPrice(plan.price),
    }))
  );

  const fallBackPaymentProvider = PAYMENT_PROVIDER.STRIPE;

  const annualPlan = computed(() =>
    allPlans.value.find(
      (plan) =>
        plan.recurring_interval === BILLING_CYCLE.ANNUAL &&
        plan.provider ===
          (selectedPaymentProvider.value || fallBackPaymentProvider) &&
        plan.max_members === 1
    )
  );

  const monthlyPlan = computed(() =>
    allPlans.value.find(
      (plan) =>
        plan.recurring_interval === BILLING_CYCLE.MONTHLY &&
        plan.provider ===
          (selectedPaymentProvider.value || fallBackPaymentProvider) &&
        plan.max_members === 1
    )
  );

  const selectedPlan = computed(() =>
    allPlans.value.find(
      (plan) =>
        plan.recurring_interval === selectedBillingCycle.value &&
        plan.provider ===
          (selectedPaymentProvider.value || fallBackPaymentProvider)
    )
  );

  return {
    isLoadingPlans,
    selectedBillingCycle,
    selectedPaymentProvider,
    selectedPlan,
    annualPlan,
    monthlyPlan,
  };
};
