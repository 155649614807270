<script setup>
import { UiBanner } from "@/components";
import store from "@/store";

import { computed } from "vue";

const user = computed(() => {
  return store?.state?.authentication?.user;
});
</script>

<template>
  <UiBanner danger class="account-delete-banner">
    <div class="content-text">
      We are experiencing intermittent service disruptions due to issues with
      one of our technology service providers.<br />Our team is actively working
      to resolve these challenges as quickly as possible - rest assured, your
      information remains safe.
    </div>
  </UiBanner>
</template>

<style lang="scss" scoped>
.account-delete-banner {
  position: fixed;
  z-index: 1000;

  .content-text {
    text-align: center;
  }
}
</style>
