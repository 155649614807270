<script setup>
import { onBeforeMount, reactive } from "vue";
import IdentityService from "@/api/actions/identity-service";
import AtomIdentityMiniCards from "@/components/Atoms/AtomIdentityMiniCards.vue";
import AtomPaginationWidget from "@/components/Atoms/AtomPaginationWidget.vue";
import Button from "@/components/Button.vue";
import AtomDivider from "@/components/Atoms/AtomDivider.vue";
import { useToast } from "@/hooks";
import {
  SUPPORT_EMAIL,
  UK_NUMBER_SUPPORT_END_DATE_ORDINAL,
  UK_NUMBER_SUPPORT_END_DATE,
} from "@/scripts/constants";
import router from "@/routes/router";

const toast = useToast();
const state = reactive({
  displayIdentities: [],
  totalIdentitiesCount: 0,
});
const resultsPerPage = 10;
const fetchPageNumberData = async (pageNumber) => {
  try {
    const res = await IdentityService.fetchTwillioIdentitiesPageV2(
      pageNumber,
      resultsPerPage
    );
    /* This is a bandaid, I have no way of filtering out support cloaks from backend */
    state.displayIdentities = res.data.results.filter(
      (identity) => identity.cloaked_phone?.phone_number !== ""
    );
    if (state.displayIdentities.length !== res.data.count) {
      /* If these don't match then we filtered out support cloaks
      Need to make total count match */
      const diff = resultsPerPage - state.displayIdentities.length;
      state.totalIdentitiesCount = res.data.count - diff;
      return;
    }
  } catch (error) {
    toast.error("Failed to fetch identities");
  }
};
onBeforeMount(async () => {
  try {
    const res = await IdentityService.fetchTwillioIdentitiesV2(resultsPerPage);
    if (!res.data.count) {
      router.push({ name: "Home" });
    }
    state.displayIdentities = res.data.results.filter(
      (identity) => identity.cloaked_phone?.phone_number !== ""
    );
    if (state.displayIdentities.length !== res.data.count) {
      /* If these don't match then we filtered out support cloaks
      Need to make total count match */
      const diff = resultsPerPage - state.displayIdentities.length;
      state.totalIdentitiesCount = res.data.count - diff;
      if (state.totalIdentitiesCount === 0) {
        router.push({ name: "Home" });
        return;
      }
      return;
    }
    state.totalIdentitiesCount = res.data.count;
  } catch (error) {
    toast.error("Failed to fetch identities");
  }
});

function contactSupport() {
  window.top.location = `mailto:${SUPPORT_EMAIL}`;
}
</script>
<template>
  <div class="esim-info">
    <h2 class="esim-info__title">
      On {{ UK_NUMBER_SUPPORT_END_DATE_ORDINAL }}, <br />
      <span
        >{{ state.totalIdentitiesCount }} of your phone numbers will be
        deactivated</span
      >
      due to new regulations
    </h2>
    <p>
      <b
        >Beginning {{ UK_NUMBER_SUPPORT_END_DATE }}, Cloaked will no longer be
        able to support UK (+44) numbers.</b
      >
    </p>

    <p>
      Previously, Cloaked generated UK numbers to enable our users to
      communicate securely with websites that block US-based virtual phone
      numbers.
    </p>

    <h3>
      You’ll have to sign in to the following websites/services and replace the
      associated phone numbers.
    </h3>
    <p>
      <b
        >On {{ UK_NUMBER_SUPPORT_END_DATE }}, 2024 the numbers currently
        assigned to any of these identities will no longer function.
      </b>
    </p>
    <p>
      Emails, passwords, and history associated with these Cloaked identities
      will not be affected.
    </p>
    <div
      v-if="state.displayIdentities?.length > 0"
      class="esim-info__pagination-wrapper"
    >
      <AtomIdentityMiniCards
        :identities="state.displayIdentities"
        :previewFields="['nickname', 'cloaked_phone']"
        :previewFieldsColors="{ cloaked_phone: '#F24141' }"
      />
      <div
        v-if="state.totalIdentitiesCount > resultsPerPage"
        class="esim-info__pagination-wrapper"
      >
        <AtomPaginationWidget
          :fetchPageNumberData="fetchPageNumberData"
          :currentItemsCount="parseInt(state.displayIdentities?.length) || 0"
          :totalItemsCount="state.totalIdentitiesCount"
          :resultsPerPage="resultsPerPage"
        />
      </div>
    </div>

    <h3>What are my options?</h3>
    <AtomDivider title="Recommended" color="#00C47D" />
    <h4>
      Option 1: Use a secure Cloaked eSIM number to replace affected identities
    </h4>

    <img
      :src="require('@/assets/images/esim-announce.png')"
      alt="Cloaked eSIM"
    />
    <p>
      A Cloaked eSIM will allow you to continue communicating securely without
      exposing your true phone number. Seamlessly switch between your eSIM
      number and your personal phone number for texts and calls and stay Cloaked
      even outside of the app.
    </p>
    <p>
      <b
        >Cloaked eSIM is coming soon. You’ll be notified when it is
        available.</b
      >
    </p>
    <AtomDivider title="Not secure" />
    <h4>Option 2: Replace affected numbers with your personal phone number</h4>
    <p>
      This option will expose your true phone number, but it will allow for you
      to continue accessing and communicating with these websites and services.
      You’ll have to visit each of these sites to update using your personal
      number.
    </p>

    <h3>Frequently asked questions</h3>
    <details>
      <summary>
        Why isn't Cloaked generating UK (+44) numbers after
        {{ UK_NUMBER_SUPPORT_END_DATE }}, 2024?
      </summary>
      <p>
        As part of a recent UK regulatory update which cuts at the knees of
        personal privacy, +44 UK numbers for certain websites and services will
        not be a selected option within Cloaked after
        {{ UK_NUMBER_SUPPORT_END_DATE }}, 2024. Services that do not accept
        Cloaked's current VoIP-based (Voice over Internet Protocol) numbers will
        for the meantime be unable to use Cloaked Numbers.
      </p>
      <p>
        However, great news! The Cloaked Team is finalizing eSims, as the next
        generation Cloaked Number Generation system, that will provide user
        election between Cloaked's VoIP technology and eSIM-based numbers. This
        update will be rolling out to Cloaked users to ensure continued number
        privacy for all websites, including those which may prevent VoIP-based
        technology.
      </p>
    </details>
    <details>
      <summary>How can I replace my UK (+44) numbers?</summary>
      <p>
        In order to continue to communicate with any affected websites/services
        that previously had a UK number, you’ll have to either replace them with
        a personal phone number or add a Cloaked eSIM to your plan to continue
        communicating securely. You’ll need to log into the affected sites or
        services and update the numbers in your profile.
      </p>
    </details>
    <details>
      <summary>What is Cloaked eSIM?</summary>
      <p>
        Cloaked eSIM is a secure eSIM solution that functions just like your
        device’s physical SIM card. It enables you to send and receive calls and
        texts (including via iMessage) just like a traditional physical SIM.
      </p>
      <p>
        Unlike a physical SIM card, a Cloaked eSIM limits the threat of being
        SIM-swapped, cloned, or spoofed, providing you a higher level of
        security than a traditional SIM card.
      </p>
    </details>

    <h3>Contact Cloaked support</h3>
    <p>
      If you have any questions about this transition, please don’t hesitate to
      contact Cloaked support.
    </p>
    <Button
      type="primary"
      size="md"
      @click="contactSupport"
      style="width: 140px"
      >Contact us</Button
    >
  </div>
</template>
<style lang="scss" scoped>
.esim-info {
  color: $color-primary-100;
  text-align: left;
  padding-bottom: 30px;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 640px;
  gap: 20px;

  &__title {
    font-family: Poppins;
    font-size: 48px;
    font-weight: 600;
    line-height: 50px;
    letter-spacing: -0.5px;
    text-align: left;
    span {
      color: $color-alert;
      text-decoration: underline;
    }
  }
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.2px;
    margin-bottom: 12px;
  }
  b {
    font-weight: 600;
  }

  h3 {
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: -0.5px;
  }

  h4 {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: -0.5px;
  }
  summary {
    display: flex;
  }
  summary::after {
    margin-left: auto;
    display: inline-block;
    content: "+ ";
    transition: 0.2s;
  }
  details[open] > summary::after {
    content: "- ";
  }

  details summary {
    cursor: pointer;
    transition: margin 200ms ease-out;
  }

  details[open] summary {
    margin-bottom: 10px;
  }

  details {
    border-bottom: 1px solid $color-primary-20;
    padding-bottom: 16px;
  }
  &__pagination-wrapper {
    padding: 20px 0;
  }
}
</style>
