<script setup>
import { ref, computed } from "vue";
import InlineSvg from "@/components/InlineSvg";
import Button from "@/components/Button.vue";
import { PAYMENT_PROVIDER } from "@/composables/useSubscriptionPlans";
import {
  usePaymentProviderStripe,
  usePaymentProviderPaypal,
  usePaymentIntent,
  useScrollToElement,
} from "@/composables";
import EsimSubscribeTitle from "@/components/eSim/Subscription/EsimSubscribeTitle.vue";
import EsimSubscribeToggle from "@/components/eSim/Subscription/EsimSubscribeToggle.vue";
import EsimSubscribeCard from "@/components/eSim/Subscription/EsimSubscribeCard.vue";
import EsimSubscribePayment from "@/components/eSim/Subscription/EsimSubscribePayment.vue";
import { useToast } from "@/hooks";
import SubscriptionService from "@/api/settings/subscription-services.js";
const toast = useToast();

const props = defineProps({
  user: {
    type: Object,
    required: true,
  },
  esimPlan: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(["next"]);

const originalPrice = computed(() => {
  if (!!paymentIntent.value?.original_price) {
    return Math.round(
      parseInt(paymentIntent.value?.original_price) / 100
    )?.toFixed(2);
  }
  return 0; // NOTE: 0 is falsy so UI will not display
});

const discountedPrice = computed(() => {
  if (!!paymentIntent.value?.price) {
    return (
      Math.round(parseInt(paymentIntent.value?.price) / 100)?.toFixed(2) || 0
    );
  }
  return 0; // NOTE: 0 is falsy so UI will not display
});
const discountPercentage = computed(() => {
  if (discountedPrice.value < originalPrice.value) {
    const priceDiff = originalPrice.value - discountedPrice.value;
    return Math.round((priceDiff / originalPrice.value) * 100);
  }
  return 0; // NOTE: 0 is falsy so UI will not display
});

const selectedPaymentProvider = ref(PAYMENT_PROVIDER.STRIPE);

const { isLoadingIntent, paymentIntent } = usePaymentIntent(props.esimPlan);

const { stripeRef, stripeError, isProcessingStripePayment, payWithStripe } =
  usePaymentProviderStripe(props.esimPlan, null, paymentIntent);

const { paypalRef, paypalError } = usePaymentProviderPaypal(
  props.esimPlan,
  null,
  props.user
);
const errorMessageRef = ref(null);
useScrollToElement(errorMessageRef);

let addOnFetchCount = 0;
async function getAddOn() {
  const addOns = await SubscriptionService.getAddons();
  const esimAddon = addOns.results.find(
    (addon) => addon?.addon_type === "esim"
  );
  if (esimAddon?.state === "PAID") {
    emit("next");
  } else if (addOnFetchCount < 20) {
    setTimeout(() => {
      addOnFetchCount++;
      getAddOn();
    }, 500);
  }
}

async function handlePurchase() {
  try {
    await payWithStripe();
    if (stripeError.value || paypalError.value) {
      return;
    }
    await getAddOn();
  } catch (err) {
    if (stripeError.value) {
      toast.error(stripeError.value);
    } else {
      toast.error("Error processing eSIM add on");
    }
  }
}
</script>

<template>
  <EsimSubscribePayment
    class="subscribe-payment"
    :class="{
      'subscribe-payment--provider-selected': !!selectedPaymentProvider,
    }"
  >
    <section class="subscribe-payment__section">
      <EsimSubscribeCard class="subscribe-payment__billing-cycle">
        <div class="subscribe-payment__full-width addon-wrapper">
          <div>
            <div class="price-name">Cloaked eSIM (add-on)</div>
            <div class="payment-intent-row">
              <span v-show="!!discountPercentage" class="original-price">
                ${{ originalPrice }}
              </span>
              <span class="discounted-price" v-show="!!discountedPrice">
                ${{ discountedPrice }}/year
              </span>
            </div>
          </div>
          <div class="discount-bubble" v-show="!!discountPercentage">
            Save {{ discountPercentage }}%
          </div>
        </div>

        <footer class="subscribe-payment__section-footer">
          <div class="subscribe-payment__section-footer-col">
            <div class="subscribe-payment__section-footer-row">
              <div class="subscribe-payment__section-footer-header">Total</div>
              <span v-show="!!discountedPrice">
                ${{ discountedPrice }} + tax due today</span
              >
            </div>
            <div class="subscribe-payment__section-footer-footnote">
              Renews annually
            </div>
          </div>
        </footer>
      </EsimSubscribeCard>
    </section>

    <section class="subscribe-payment__section">
      <header class="subscribe-payment__section-header">
        <EsimSubscribeTitle>Add billing details</EsimSubscribeTitle>
        <span class="subscribe-payment__secure-form">
          Secure form
          <InlineSvg name="lock" class="subscribe-payment__secure-form-icon" />
        </span>
      </header>
      <EsimSubscribeCard class="subscribe-payment__payment-type">
        <EsimSubscribeToggle
          v-model="selectedPaymentProvider"
          :name="PAYMENT_PROVIDER.STRIPE"
        >
          <InlineSvg
            name="credit-card-outline"
            class="subscribe-payment__provider-icon"
          />
          <br />
          Card
        </EsimSubscribeToggle>
        <EsimSubscribeToggle
          v-model="selectedPaymentProvider"
          :name="PAYMENT_PROVIDER.PAYPAL"
        >
          <InlineSvg name="paypal" class="subscribe-payment__provider-icon" />
          <br />
          Paypal
        </EsimSubscribeToggle>
        <div
          ref="stripeRef"
          v-show="selectedPaymentProvider === PAYMENT_PROVIDER.STRIPE"
          class="subscribe-payment__stripe-form"
        />
        <p
          class="stripe-text subscribe-payment__full-width"
          v-show="selectedPaymentProvider === PAYMENT_PROVIDER.STRIPE"
        >
          Checkout powered by <InlineSvg name="stripe-logo" />
        </p>
      </EsimSubscribeCard>
    </section>

    <slot />

    <div v-show="selectedPaymentProvider !== PAYMENT_PROVIDER.PAYPAL">
      <Button
        size="2xl"
        fullWidth
        type="cloaked-gradient"
        @click="handlePurchase"
        :disabled="selectedPaymentProvider !== PAYMENT_PROVIDER.STRIPE"
      >
        <template v-if="isProcessingStripePayment">
          Completing purchase
          <InlineSvg name="spinner" key="spinner" />
        </template>
        <template v-else>
          Purchase
          <InlineSvg name="arrow-right" key="arrow " />
        </template>
      </Button>
    </div>
    <div v-show="selectedPaymentProvider === PAYMENT_PROVIDER.PAYPAL">
      <div ref="paypalRef" class="subscribe-payment__subscribe-button-paypal" />
    </div>

    <p
      v-if="stripeError || paypalError"
      ref="errorMessageRef"
      class="subscribe-payment__error"
    >
      {{ stripeError || paypalError }}
    </p>
  </EsimSubscribePayment>
</template>
<style lang="scss" scoped>
.price-name {
  color: $color-primary-100;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.2px;
}
.addon-wrapper {
  border: 3px solid $color-primary-100;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}
.discount-bubble {
  background-color: $color-success;
  color: $white;
  display: flex;
  height: 20px;
  width: 60px;
  padding: 2px 5px;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 21px;
}
.payment-intent-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  .original-price {
    text-decoration: line-through;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: $color-primary-50;
  }
  .discounted-price {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: $color-primary-100;
  }
}

.stripe-text {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  color: $color-primary-100;
  width: 100%;
}
</style>
