<script setup>
import { SettingsParagraph } from "@/components";
import InlineSvg from "@/components/InlineSvg.vue";
import AppCard from "@/components/ui/AppCard.vue";
import store from "@/store";
import { computed } from "vue";
import { copyToClipboard } from "@/scripts/tools";
import { useToast } from "@/hooks";
import { usePlanMembers } from "@/components/feature/subscribe/composables";

const stripeLink = computed(() => {
  const stripeEmail = store.getters["settings/getStripe"]?.email;

  let stripeUrl = global?.ENV?.VUE_APP_STRIPE_MANAGE_URL;

  if (stripeEmail) {
    stripeUrl += `?prefilled_email=${stripeEmail}`;
  }

  return stripeUrl;
});

const { success } = useToast();

const onCopyEmail = () => {
  const email = store.getters["settings/getStripe"]?.email;
  copyToClipboard(email);
  success(`${email} copied to clipboard`);
};

const { planMembers } = usePlanMembers();
</script>

<template>
  <AppCard size="md" class="family-plans-billing-card">
    <SettingsParagraph>
      Billing is managed in Stripe. Use the email below to access your Stripe
      account.
    </SettingsParagraph>
    <AppCard
      type="background"
      class="family-plans-billing-card__email"
      v-if="$store.getters['settings/getStripe']?.email"
      @click="onCopyEmail"
    >
      <div>
        <label class="family-plans-billing-card__email-label">
          Stripe account email
        </label>
        <div class="family-plans-billing-card__email-value">
          {{ $store.getters["settings/getStripe"]?.email }}
        </div>
      </div>
      <InlineSvg name="copy" class="family-plans-billing-card__email-copy" />
    </AppCard>
    <footer class="family-plans-billing-card__actions">
      <a class="family-plans-billing-card__link" @click="$emit('switch-plans')"
        >Switch plans
        <InlineSvg name="switch" class="family-plans-billing-card__icon" />
      </a>
      <a
        class="family-plans-billing-card__link"
        :href="stripeLink"
        target="_blank"
        >Manage Subscription
        <InlineSvg name="link" class="family-plans-billing-card__icon" />
      </a>
      <SettingsParagraph
        class="family-plans-billing-card__note"
        v-if="planMembers.length"
      >
        If you're the subscription owner of a Couple or Family plan and want to
        switch plans, the members under your plan will be removed. They'll need
        to start their own subscription or be re-invited to continue using
        Cloaked.
      </SettingsParagraph>
    </footer>
  </AppCard>
</template>

<style lang="scss">
.family-plans-billing-card {
  &__email {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    cursor: pointer;
    color: $color-primary-100;

    &-label {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    &-value {
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.1px;
      word-break: break-all;
    }

    &-copy {
      width: 26px;
      height: 26px;
      flex-shrink: 0;

      @at-root .family-plans-billing-card__email:hover & {
        opacity: 0.8;
      }

      @at-root .family-plans-billing-card__email:active & {
        transform: scale(0.95);
      }
    }
  }

  &__note {
    font-size: 12px;
    border-top: 1px solid $color-primary-10;
    padding-top: 16px;
  }
}
</style>
