<script setup>
import UiHeader from "@/components/feature/onboarding-new/UiHeader.vue";
import UiButton from "@/components/feature/onboarding-new/UiButton.vue";
import UiButtonRow from "@/components/feature/onboarding-new/UiButtonRow.vue";
import UiPageWrapper from "@/components/feature/onboarding-new/UiPageWrapper.vue";
import EsimMobileToggleWidget from "@/components/eSim/EsimMobileToggleWidget.vue";

import { PH_SCREEN_EVENT_ESIM_ACTIVATING_ESIM_SCREEN } from "@/scripts/posthogEvents";
import { computed } from "vue";

const props = defineProps({
  deviceType: {
    type: String,
    default: "ios",
  },
});

const emit = defineEmits(["next", "updateDeviceType"]);

const instructions = computed(() => {
  return props.deviceType === "ios"
    ? 'In settings, tap "Finish setting up Cellular". Follow the steps on your device to install your eSIM.\n\nTo send and receive iMessages via your Cloaked eSIM number, navigate to Settings > Messages > Send & Receive and ensure your Cloaked eSIM number is enabled.'
    : 'Navigate to Network & internet > SIMs. Cloaked eSIM should appear under "Downloaded SIMs." Follow the steps on your device to install your eSIM.';
});
</script>
<template>
  <UiPageWrapper
    showLogo
    logo="cloaked-esim-logo"
    :screenEvent="PH_SCREEN_EVENT_ESIM_ACTIVATING_ESIM_SCREEN"
    :esimStep="6"
  >
    <UiHeader>
      <h1>Continue to your device's settings</h1>

      <h5>
        Open settings on your device and navigate to the screen below. After you
        complete the setup, return to the Cloaked app to finish installation.
      </h5>
    </UiHeader>
    <EsimMobileToggleWidget
      class="esim-mobile-toggle-widget"
      :deviceType="props.deviceType"
      :imgName="`${props.deviceType}-network`"
      :instructions="instructions"
      @updateDeviceType="(deviceType) => emit('updateDeviceType', deviceType)"
    />

    <UiButtonRow>
      <div class="button-row-col">
        <UiButton gradient @click="emit('next')">Continue</UiButton>
        <a
          class="esim-button"
          href="https://help.cloaked.app/hc/en-us"
          target="_blank"
        >
          I need help with this step.</a
        >
      </div>
    </UiButtonRow>
  </UiPageWrapper>
</template>
<style lang="scss" scoped>
.button-row-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 100%;
  .esim-button {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.2px;
    color: $color-primary-100;
    text-decoration: underline;
    transition: transform 0.3s ease;
    &:hover {
      transform: scale(1.05);
      transition: transform 0.3s ease;
    }
  }
}
.esim-mobile-toggle-widget {
  margin-bottom: 100px;
}
</style>
