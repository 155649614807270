<script setup>
import { reactive, onMounted } from "vue";
import EsimService from "@/api/actions/esim-service.js";
import SubscriptionService from "@/api/settings/subscription-services.js";

import EsimStepZero from "@/components/eSim/EsimStepZero.vue";
import EsimStepOne from "@/components/eSim/EsimStepOne.vue";
import EsimStepTwo from "@/components/eSim/EsimStepTwo.vue";
import EsimStepTwoError from "@/components/eSim/EsimStepTwoError.vue";
import EsimStepThree from "@/components/eSim/EsimStepThree.vue";
import EsimStepFour from "@/components/eSim/EsimStepFour.vue";
import EsimStepFive from "@/components/eSim/EsimStepFive.vue";
import EsimStepSix from "@/components/eSim/EsimStepSix.vue";
import EsimStepSeven from "@/components/eSim/EsimStepSeven.vue";
import EsimStepEight from "@/components/eSim/EsimStepEight.vue";
import EsimStepNine from "@/components/eSim/EsimStepNine.vue";

onMounted(() => {
  EsimService.enableESim();
  Promise.allSettled([SubscriptionService.getAddons(), EsimService.getSims()])
    .then(([addOns, simsRes]) => {
      const esimAddon = addOns?.value?.results.find(
        (addon) => addon?.addon_type === "esim"
      );
      if (esimAddon?.state === "PAID") {
        state.isPaid = true;
      }
      const sims = simsRes?.value?.data?.results;
      const simsWithNumbers = sims?.filter((sim) => sim?.msisdn);
      const hasSimWithNumbers = simsWithNumbers?.length;
      if (hasSimWithNumbers && esimAddon?.state === "PAID") {
        state.newNumber = simsWithNumbers[0]?.msisdn;
        nextESimStep(9);
      } else if (esimAddon?.state === "PAID" && sims.length) {
        state.simId = sims[0]?.id;
        nextESimStep(5);
      }
    })
    .catch(() => {});
});

const state = reactive({
  eSimStep: 0, // change this number to skip steps while testing
  zipCode: "",
  newNumber: "",
  showIncompatibleScreen: false,
  simId: null,
  deviceType: "ios",
  esimSubscriptionIntent: null,
});

function compatibilityRetry() {
  state.showIncompatibleScreen = false;
}

function nextESimStep(skipToStep) {
  if (skipToStep || skipToStep === 0) {
    state.eSimStep = skipToStep;
  } else {
    state.eSimStep = state.eSimStep + 1;
  }
}

function updateNewNumber(number) {
  state.newNumber = number;
}

function updateZipCode(zipCode) {
  state.zipCode = zipCode;
}

function updateDeviceType(deviceType) {
  state.deviceType = deviceType;
}
</script>

<template>
  <EsimStepZero v-if="state.eSimStep === 0" @next="nextESimStep" />
  <EsimStepOne
    v-else-if="state.eSimStep === 1"
    @next="nextESimStep"
    @updateZipCode="updateZipCode"
  />
  <EsimStepTwo
    v-else-if="state.eSimStep === 2 && state.showIncompatibleScreen === false"
    :deviceType="state.deviceType"
    :isPaid="state.isPaid"
    @next="nextESimStep"
    @updateDeviceType="updateDeviceType"
    @incompatible="state.showIncompatibleScreen = true"
    @updateSimId="state.simId = $event"
  />
  <EsimStepTwoError
    v-else-if="state.eSimStep === 2 && state.showIncompatibleScreen === true"
    @retry="compatibilityRetry"
  />

  <EsimStepThree
    v-else-if="state.eSimStep === 3"
    @next="nextESimStep"
    :esimSubscriptionIntent="state.esimSubscriptionIntent"
  />

  <EsimStepFour
    v-else-if="state.eSimStep === 4"
    @next="nextESimStep"
    :isPaid="state.isPaid"
  />
  <EsimStepFive
    v-else-if="state.eSimStep === 5"
    :zipCodeInitialValue="state.zipCode"
    :simId="state.simId"
    @next="nextESimStep"
    @updateNumber="updateNewNumber"
    @updateZipCode="updateZipCode"
  />
  <EsimStepSix
    v-else-if="state.eSimStep === 6"
    @next="nextESimStep"
    :newNumber="state.newNumber"
  />
  <EsimStepSeven
    v-else-if="state.eSimStep === 7"
    :deviceType="state.deviceType"
    @updateDeviceType="updateDeviceType"
    @next="nextESimStep"
  />
  <EsimStepEight
    v-else-if="state.eSimStep === 8"
    :deviceType="state.deviceType"
    @updateDeviceType="updateDeviceType"
    @next="nextESimStep"
  />
  <EsimStepNine v-else-if="state.eSimStep === 9" :newNumber="state.newNumber" />
</template>
