<script setup>
import InlineSvg from "@/components/InlineSvg.vue";
const props = defineProps({
  step: {
    type: Number,
    required: true,
  },
});
</script>
<template>
  <div class="wrapper">
    <div class="item-wrapper" :class="{ active: props.step === 1 }">
      <InlineSvg
        name="check-active"
        v-if="props.step > 1"
        class="header-icon"
      />
      <div v-else class="header-number">1</div>
      Coverage
    </div>
    <div class="item-wrapper" :class="{ active: props.step === 2 }">
      <InlineSvg
        name="check-active"
        v-if="props.step > 2"
        class="header-icon"
      />
      <div v-else class="header-number">2</div>
      Compatibility
    </div>
    <div class="item-wrapper" :class="{ active: props.step === 3 }">
      <InlineSvg
        name="check-active"
        v-if="props.step > 3"
        class="header-icon"
      />
      <div v-else class="header-number">3</div>
      Payment
    </div>
    <div class="item-wrapper" :class="{ active: props.step === 4 }">
      <InlineSvg
        name="check-active"
        v-if="props.step > 4"
        class="header-icon"
      />
      <div v-else class="header-number">4</div>
      Area Code
    </div>
    <div class="item-wrapper" :class="{ active: props.step === 5 }">
      <InlineSvg
        name="check-active"
        v-if="props.step > 5"
        class="header-icon"
      />
      <div v-else class="header-number">5</div>
      Transmission
    </div>
    <div class="item-wrapper" :class="{ active: props.step >= 6 }">
      <InlineSvg
        name="check-active"
        v-if="props.step === 7"
        class="header-icon"
      />
      <div v-else class="header-number">6</div>
      Installation
    </div>
  </div>
</template>
<style scoped lang="scss">
.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 24px;
  color: $color-primary-100;
  .item-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
    opacity: 0.3;
    flex-shrink: 0;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    &.active {
      opacity: 1;
    }

    .header-icon {
      height: 18px;
      width: 18px;
      color: $color-primary-100;
    }
    .header-number {
      flex-shrink: 0;
      height: 18px;
      width: 18px;
      border-radius: 50%;
      background-color: $color-primary-100;
      color: $color-surface;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
