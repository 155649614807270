<script setup>
import { computed, ref, markRaw } from "vue";
import Button from "@/components/Button.vue";
import store from "@/store";
import inlineSvg from "@/components/InlineSvg.vue";
import CardsServices from "@/api/actions/cards-services";
import UserService from "@/api/actions/user-service";
import { useToast } from "@/hooks";
import MultiCardPaymentPanel from "@/components/Wallet/MultiCardPaymentPanel.vue";

const toast = useToast();

const outstandingBalance = computed(() => {
  return store.state.authentication?.user?.outstanding_balance;
});

const sources = computed(() => {
  return store.state.cards?.fundingSources.results;
});

const primarySource = computed(() => {
  return sources?.value.find((source) => source.primary).id;
});

const fundingSource = ref(null);

const selectedSource = computed(() => {
  return fundingSource.value || primarySource.value;
});

function selectSource(source) {
  fundingSource.value = source.id;
}

const buttonDisabled = ref(false);

function convertToDollar(amount) {
  return (amount / 100)
    .toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    })
    .replace(/\.0+$/, "");
}

function payReceivePayments() {
  if (!buttonDisabled.value) {
    buttonDisabled.value = true;
    mockOutstandingBalance.value = 0;
    CardsServices.payAll().then(() => {
      UserService.getUserDetails().then(() => {
        toast.success("Payment successful");
        buttonDisabled.value = false;
        receivePaymentsActive.value = false;
      });
    });
  }
}

const multiCardPaymentPanelActivated = ref(false);

async function openMultiCardModal() {
  multiCardPaymentPanelActivated.value = true;
  receivePaymentsActive.value = false;
  const cards = await store.state.cards.cards;
}

const mockOutstandingBalance = ref(15000);

async function collectionsList() {
  const collections = await store.state.authentication?.user.collections;
  const res = (await JSON.parse(JSON.stringify(collections)).length) > 0;
  return res;
}

async function cardCollections() {
  const collections = await store.state.authentication?.user.card_collections;
  const res = (await collections) != null;
  return res;
}

async function receivePaymentsGuesswork() {
  const user_reputation = await store.state.authentication?.user
    ?.reputation_level;
  const outstanding_balance = await store.state.authentication?.user
    ?.outstanding_balance;

  if (
    outstanding_balance > user_reputation &&
    !collectionsList() &&
    !cardCollections()
  ) {
    receivePaymentsActive.value = true;
  } else {
    receivePaymentsActive.value = false;
  }
}

const receivePaymentsActive = ref(false);

await receivePaymentsGuesswork();
</script>

<template>
  <div v-if="multiCardPaymentPanelActivated">
    <MultiCardPaymentPanel />
  </div>

  <div class="receive-payments-panel" v-if="sources && receivePaymentsActive">
    <div class="receive-payments">
      <div class="title">
        <h1 v-if="receivePaymentsActive">
          Balance due: {{ convertToDollar(mockOutstandingBalance) }}
        </h1>
        <p v-if="receivePaymentsActive">Please pay your remaining balance.</p>

        <p v-else-if="collection?.status === 'pending'">
          Your payment is being processed. Please come back later.
        </p>
      </div>

      <div class="funding-sources" v-if="receivePaymentsActive">
        <div
          class="funding-source"
          v-for="source in sources.filter((s) => s.primary)"
          :key="source.id"
        >
          <inlineSvg name="bank" />
          <div class="information">
            <h1>{{ source.card_brand }}</h1>
            <p>
              <span>**** {{ source.pan_last_four }}</span>
              <span v-if="source.nickname">• {{ source.nickname }}</span>
            </p>
          </div>
          <span class="default-pill">Default</span>
        </div>
      </div>

      <Button
        fullWidth
        size="xl"
        @click="payReceivePayments()"
        v-if="receivePaymentsActive"
        :disabled="buttonDisabled"
        :loading="buttonDisabled"
        >Pay balance in full</Button
      >

      <Button fullWidth @click="openMultiCardModal()" v-if="false"
        >View Details</Button
      >
    </div>
  </div>
</template>

<style lang="scss" scoped>
.receive-payments-panel {
  position: sticky;
  top: 22px;
  z-index: 1;
  background-color: $color-surface;
  padding-top: 10px;
  padding-bottom: 1px;

  .app--visible-banner & {
    top: calc(22px + $ui-banner-height);
  }

  .receive-payments {
    padding: 24px;
    border: 1px solid $color-primary-10;
    border-radius: 20px;
    margin: 15px 15px 30px 15px;

    > * {
      margin-top: 16px;

      &:first-child {
        margin-top: 0;
      }
    }

    .title {
      h1 {
        font-size: 24px;
        line-height: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.5px;
        color: $color-primary-100;
      }

      p {
        margin-top: 16px;
        font-size: 16px;
        line-height: 24px;
        color: $color-primary-70;
        letter-spacing: -0.2px;

        strong {
          font-weight: 600;
        }
      }
    }

    .funding-sources {
      margin-top: 24px;

      .funding-source {
        border: 1px solid $color-primary-10;
        border-radius: 16px;
        padding: 16px;
        margin-top: 4px;
        position: relative;
        color: $color-primary-100;

        &:hover {
          background-color: $color-primary-5;
          cursor: pointer;
        }

        &.selected {
          .selected-icon {
            &:after {
              content: "";
              display: block;
              width: 8px;
              height: 8px;
              background-color: $color-primary-100;
              border-radius: 50%;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }

        &:first-child {
          margin-top: 0;
        }

        svg {
          width: 24px;
          height: 24px;
          margin-right: 16px;
          position: absolute;
          top: 50%;
          left: 16px;
          transform: translateY(-50%);
        }

        .information {
          padding-left: 40px;
          padding-right: 40px;

          h1 {
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.1px;
            color: $color-primary-100;
            text-transform: capitalize;
          }

          p {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 4px;

            span {
              display: inline-block;

              &:nth-of-type(2) {
                margin-left: 4px;
              }
            }
          }
        }

        .default-pill {
          position: absolute;
          top: 50%;
          right: 25px;
          transform: translateY(-50%);
          background-color: $color-success;
          color: $white;
          font-size: 10px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          padding: 4px 10px;
          border-radius: 19px;
        }

        .selected-icon {
          position: absolute;
          top: 50%;
          right: 24px;
          transform: translateY(-50%);
          width: 16px;
          height: 16px;
          border-radius: 50%;
          border: 2px solid $color-primary-100;
        }
      }
    }
  }
}
</style>
