<script setup>
import UiHeader from "@/components/feature/onboarding-new/UiHeader.vue";
import UiButton from "@/components/feature/onboarding-new/UiButton.vue";
import UiButtonRow from "@/components/feature/onboarding-new/UiButtonRow.vue";
import UiPageWrapper from "@/components/feature/onboarding-new/UiPageWrapper.vue";
import InlineSvg from "@/components/InlineSvg";
import AtomInputInternalLabels from "@/components/Atoms/AtomInputInternalLabels.vue";
import { PH_SCREEN_EVENT_ESIM_COMPLETE_ESIM_SCREEN } from "@/scripts/posthogEvents";
import router from "@/routes/router";
import { format } from "@/scripts";
const props = defineProps({
  newNumber: {
    type: String,
    required: true,
  },
});
</script>
<template>
  <UiPageWrapper
    showLogo
    logo="cloaked-esim-logo"
    :screenEvent="PH_SCREEN_EVENT_ESIM_COMPLETE_ESIM_SCREEN"
    :esimStep="7"
  >
    <UiHeader>
      <h1>Your Cloaked eSIM setup is complete!</h1>
      <h5>Your eSIM is now ready to use.</h5>
      <AtomInputInternalLabels
        label="Your eSim Number"
        type="text"
        center
        :placeholder="format.phone_format(props.newNumber)"
        :value="format.phone_format(props.newNumber)"
        :disabled="true"
        readonly
      />
    </UiHeader>
    <InlineSvg name="esim-simcheck" />
    <UiButtonRow>
      <div class="button-row-col">
        <UiButton gradient @click="router.push({ name: 'Home' })"
          >Finish</UiButton
        >
        <div class="esim-button" @click="router.push({ name: 'Esim' })">
          Manage eSIM
        </div>
      </div>
    </UiButtonRow>
  </UiPageWrapper>
</template>
<style lang="scss" scoped>
.button-row-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 100%;
  .esim-button {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.2px;
    color: $color-primary-100;
    cursor: pointer;
    text-decoration: underline;
    transition: transform 0.3s ease;
    &:hover {
      transform: scale(1.05);
      transition: transform 0.3s ease;
    }
  }
}
</style>
