<script setup>
const props = defineProps({
  title: {
    type: String,
    default: "",
  },
  color: {
    type: String,
    default: "",
  },
});
</script>
<template>
  <div class="esim-info__section">
    <div
      class="esim-info__pill"
      :style="props.color ? `background-color: ${props.color};` : ''"
    >
      {{ props.title }}
    </div>
    <div
      class="esim-info__divider"
      :style="props.color ? `background-color: ${props.color};` : ''"
    ></div>
  </div>
</template>
<style lang="scss" scoped>
.esim-info {
  &__section {
    display: flex;
    align-items: center;
  }
  &__pill {
    background-color: $color-primary-100;
    color: $color-primary-0;
    padding: 0px 6px 0px 6px;
    border-radius: 8px;
    white-space: nowrap;
    width: fit-content;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 600;
    padding: 6px 8px;
  }
  &__divider {
    background-color: $color-primary-100;
    color: $color-primary-0;
    height: 2px;
    width: 100%;
    border-radius: 4px;
  }
}
</style>
