import { PDFDocument } from "pdf-lib";
import AuthService from "@/api/actions/auth-service";

import { authDecrypt } from "@/scripts/actions/encryption";
const copyText = async (text) => {
  /* Verify Clipboard API is available & window is secure */
  if (navigator?.clipboard && window?.isSecureContext) {
    return new Promise((resolve, reject) => {
      try {
        navigator?.clipboard?.writeText(text).then(() => {
          resolve(true);
        });
      } catch (err) {
        reject(err);
      }
    });
  } else {
    /* Fallback mobile webviews, ye olde browsers */
    const textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.position = "fixed";
    textArea.style.left = "-999999px";
    textArea.style.top = "-999999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    return new Promise((resolve, reject) => {
      try {
        const successful = document?.execCommand("copy");
        if (successful) {
          resolve(true);
        } else {
          resolve(false);
        }
      } catch (err) {
        reject(err);
      } finally {
        document?.body?.removeChild(textArea);
      }
    });
  }
};

export const copy = async (text, $event) => {
  await copyText(text);
  const div = document.createElement("div");
  div.innerHTML = "Copied";
  div.classList.add("copy-div");
  div.style.left = `${$event.clientX + 5}px`;
  div.style.top = `${
    $event.clientY +
    ($event.currentTarget.scrollTop || document.documentElement.scrollTop) +
    5
  }px`;
  div.addEventListener("mouseout", () => {
    if (div) {
      document.body.removeChild(div);
    }
  });
  document.body.appendChild(div);
  setTimeout(() => {
    if (div) {
      document.body.removeChild(div);
    }
  }, 650);
};

export const copyToClipboard = async (text, formattingOn = true) => {
  let formattedText = text;
  if (formattingOn) {
    formattedText = text?.replace(/\n/g, " ");
  }
  await copyText(formattedText);
};

export const downloadFile = ({
  fileContents = "",
  fileName = "file.txt",
  mimeType = "text/plain",
  createBlob = true,
}) => {
  let data = fileContents;
  const link = document.createElement("a");
  link.setAttribute("download", fileName);
  link.style.display = "none";
  link.rel = "noopener";

  if (createBlob) {
    data = new Blob([fileContents], {
      type: mimeType,
    });
  }

  const blobURL = window.URL.createObjectURL(data);
  link.href = blobURL;

  if (typeof link.download === "undefined") {
    link.setAttribute("target", "_blank");
  }

  link.click();

  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(blobURL);
  }, 100);
};

/* Wait for an element to appear on the DOM, then return it */
export const waitForElmmentOnPage = (selector) => {
  return new Promise((resolve) => {
    if (document.getElementById(selector)) {
      return resolve(document.getElementById(selector));
    }

    const observer = new MutationObserver(() => {
      if (document.getElementById(selector)) {
        resolve(document.getElementById(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
};

export const createPDF = async (key, supportEmail, username = null) => {
  try {
    const res = await fetch("/templates/recovery.pdf");
    const buffer = await res.arrayBuffer();
    const pdfDoc = await PDFDocument.load(buffer);
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];
    firstPage.drawText(key, { x: 57, y: 505, size: 15 });
    firstPage.drawText(username, { x: 57, y: 570, size: 15 });
    firstPage.drawText(`support.${supportEmail}`, { x: 57, y: 250, size: 15 });
    const pdfBytes = await pdfDoc.save();
    downloadFile({
      fileContents: pdfBytes,
      fileName: "recovery-key.pdf",
      mimeType: "application/pdf",
    });
  } catch (e) {
    console.error(e);
  }
};

export const downloadRecoveryKeyAndCreatePdf = (
  supportEmail,
  username = null
) => {
  AuthService.passPhrase()
    .then(({ data }) => {
      const { results } = data;
      if (results && results[0]) {
        authDecrypt(results[0].key).then((key) => {
          createPDF(key, supportEmail, username);
        });
      } else {
        throw "error";
      }
    })
    .catch(() => {
      throw "error";
    });
};

export const supportsWasm = () => {
  try {
    if (
      typeof WebAssembly === "object" &&
      typeof WebAssembly.instantiate === "function"
    ) {
      const module = new WebAssembly.Module(
        Uint8Array.of(0x0, 0x61, 0x73, 0x6d, 0x01, 0x00, 0x00, 0x00)
      );
      if (module instanceof WebAssembly.Module)
        return new WebAssembly.Instance(module) instanceof WebAssembly.Instance;
    }
  } catch (e) {
    return false;
  }
};

export const isInAppBrowser = (() => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (userAgent?.indexOf("FBAN") > -1 || userAgent?.indexOf("FBAV") > -1) {
    return true;
  }
  if (userAgent?.includes("Instagram")) {
    return true;
  }

  if (document?.documentElement?.classList?.contains("in-app-browser")) {
    return true;
  }
  return false;
})();

export default {
  copy,
  copyToClipboard,
  downloadFile,
  waitForElmmentOnPage,
  createPDF,
  downloadRecoveryKeyAndCreatePdf,
  supportsWasm,
  isInAppBrowser,
};
