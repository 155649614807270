<script setup>
import DataDeleteSubscribeBenefits from "@/components/feature/data-delete/atoms/DataDeleteSubscribeBenefits.vue";
import ChoosePlanPayment from "@/components/feature/subscribe/ChoosePlanPayment.vue";
import ChoosePlanPicker from "@/components/feature/subscribe/ChoosePlanPicker.vue";
import { usePostHogFeatureFlag } from "@/composables";

const { featureFlag, hasLoadedFeatureFlag } = usePostHogFeatureFlag(
  "checkout-discount-timer"
);
</script>

<template>
  <div class="choose-plan-page">
    <h1 class="data-delete__title">
      <slot name="title">
        Choose a plan and start your data removal today
      </slot>
    </h1>
    <DataDeleteSubscribeBenefits class="choose-plan-page__benefits" />
    <div class="choose-plan-page__plan">
      <div id="choose-plan--discount-banner" />
      <ChoosePlanPicker />
      <ChoosePlanPayment
        v-if="hasLoadedFeatureFlag"
        :intent="$attrs.intent"
        :user="$attrs.headlessUser"
        :has-discount="featureFlag === '20-off-5-min'"
        @subscribed="$emit('subscribed')"
      />
    </div>
  </div>
</template>

<style lang="scss">
.choose-plan-page {
  padding: 64px 32px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
  max-width: 1400px;

  @media all and (min-width: $screen-xl) {
    margin: auto;
    column-gap: 0;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content 1fr;
  }

  &:before {
    @media all and (min-width: $screen-xl) {
      content: "";
      position: fixed;
      inset: 0 0 0 50%;
      background: rgba(255, 255, 255, 0.05);
      z-index: -1;
    }
  }

  .data-delete__title,
  &__benefits,
  &__plan {
    @media all and (min-width: $screen-xl) {
      padding: 0 clamp(32px, (100vw - $screen-xl) / 2, 110px);
    }
  }

  .data-delete__title {
    opacity: 0;
    animation: appear-bottom-5 0.5s forwards ease-out;

    @media all and (min-width: $screen-xl) {
      text-align: start;
    }
  }

  &__benefits {
    grid-row-start: 2;
    justify-self: end;
    width: 100%;
  }

  &__plan {
    opacity: 0;
    animation: appear-bottom-5 0.5s 0.15s forwards ease-out;

    @media all and (min-width: $screen-xl) {
      grid-row: 1/3;
    }

    .choose-plan-payment__express {
      @at-root .theme-dark & {
        border: 1px solid rgba($white, 0.1);
      }

      @at-root .theme-light & {
        border: 1px solid rgba($black, 0.1);
      }
    }

    .choose-plan-option {
      border: 2px solid transparent;

      @at-root .theme-dark & {
        background: rgba($white, 0.1);
      }

      @at-root .theme-light & {
        background: rgba($black, 0.05);
      }

      &:hover {
        border: 2px solid $color-primary-70;
      }

      &:has(.choose-plan-option__input:checked) {
        border: 2px solid $color-primary-100;

        &:is(.choose-plan-option--individual) {
          @at-root .theme-dark & {
            border-color: $color-info;
          }

          @at-root .theme-light & {
            border-color: $color-info;
          }
        }

        &:is(.choose-plan-option--couple) {
          @at-root .theme-dark & {
            border-color: $color-brand-2-90-light;
          }

          @at-root .theme-light & {
            border-color: $color-brand-2-50-dark;
          }
        }

        &:is(.choose-plan-option--family) {
          @at-root .theme-dark & {
            border-color: $color-brand-3-90-light;
          }

          @at-root .theme-light & {
            border-color: $color-brand-3-70-dark;
          }
        }
      }
    }

    .choose-plan-option-skeleton__skeleton {
      @at-root .theme-dark & {
        @include base-skeleton($color-primary-30, 0.3);
      }

      @at-root .theme-light & {
        @include base-skeleton($color-primary-30, 0.4);
      }
    }

    .choose-plan__cta {
      position: sticky;
      bottom: 16px;
    }
  }
}
</style>
