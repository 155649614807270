<script setup>
import SubscriptionFamilyPlans from "@/routes/modals/preferences/SubscriptionFamilyPlans.vue";
import { onBeforeMount } from "vue";
import { SubscriptionService } from "@/api";

onBeforeMount(() => {
  // NOTE: purposefully querying backend every time in case user makes to stripe
  SubscriptionService.getStripeInfo();
});
</script>

<template>
  <SubscriptionFamilyPlans />
</template>
