<script setup>
import { computed, onMounted } from "vue";

import store from "@/store";
import MenuItem from "./MenuItem.vue";
import MenuBlock from "./MenuBlock.vue";
import GetStartedButton from "@/components/Navigation/NavigationLeftPanel/GetStartedButton";
import EsimService from "@/api/actions/esim-service.js";
import {
  getUserCountry,
  LAUNCHED_COUNTRIES,
} from "@/scripts/countries/countries";

onMounted(() => {
  EsimService.getSims({ number: true });
});

const hasDDScan = computed(() => {
  return (
    store.getters["dataDelete/enrollmentData"]?.enrollmentDataFetched ?? false
  );
});

const showNewOnboarding = computed(() => {
  return store.state.authentication?.user?.flags
    ?.show_dd_post_payment_onboarding;
});

const ddOnboardingEnabled = computed(() => {
  return store.getters["dataDelete/getDdOnboardingEnabled"];
});

const showGetStartedButton = computed(() => {
  return !(ddOnboardingEnabled.value && showNewOnboarding.value);
});

const eSimFeatureFlag = computed(() => {
  const userCountry = getUserCountry(store.state.authentication?.user);
  return (
    LAUNCHED_COUNTRIES.includes(userCountry) &&
    store.state.authentication?.user?.flags?.esim_enabled &&
    store.getters["settings/isSubscribed"]
  );
});

const hasEsimsWithNumbers = computed(() => {
  const simsWithNumbers = store.getters["esim/getEsims"]?.filter(
    (sim) => !!sim?.msisdn
  );
  return !!simsWithNumbers?.length;
});
</script>

<template>
  <MenuBlock title="Home">
    <MenuItem link="/home" name="Home" icon="home" />
    <MenuItem v-if="showGetStartedButton" name="Get Started">
      <GetStartedButton />
    </MenuItem>
    <MenuItem link="/referrals" name="Refer friends" icon="present" />
    <MenuItem
      v-if="hasDDScan"
      link="/data-remove"
      name="Data removal"
      icon="data-delete/remove-data"
    />
    <MenuItem
      link="/esim/get-started"
      name="Setup eSIM"
      icon="esim-page"
      v-if="eSimFeatureFlag && !hasEsimsWithNumbers"
    />
    <MenuItem
      link="/esim"
      name="Manage eSIM"
      icon="esim-page"
      v-if="hasEsimsWithNumbers"
    />
  </MenuBlock>
</template>
