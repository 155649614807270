<script setup>
import InlineSvg from "@/components/InlineSvg.vue";
import store from "@/store";
import { format, tools } from "@/scripts";
import { computed } from "vue";
import router from "@/routes/router";
import { ESIM_STATE_DISPLAY } from "@/scripts/constants";
import { useToast } from "@/hooks";
const toast = useToast();
const esim = computed(() => {
  // NOTE: only show 1 esim for now
  return store.getters["esim/getEsims"][0];
});

function goToEsimPage() {
  router.push({ name: "Esim" });
}

function copyText(text) {
  tools.copyToClipboard(text);
  toast.success("Copied to clipboard");
}
</script>
<template>
  <div class="page-wrapper">
    <div class="header-row">
      <h1 class="title">eSIM Numbers</h1>
      <div class="toggle" @click="goToEsimPage">Manage eSIM</div>
    </div>
    <section class="section-wrapper pointer" @click="copyText(esim.msisdn)">
      <div class="flex-row">
        <div class="flex-col">
          <div class="title flex-row-center-start">
            <span>{{ format.phone_format(esim.msisdn) }}</span
            ><InlineSvg name="fuzzy-green-dot" />
          </div>
          <div class="subtitle">
            Your eSIM is {{ ESIM_STATE_DISPLAY[esim.state] }}.
          </div>
        </div>
        <InlineSvg name="copy" />
      </div>
    </section>
  </div>
</template>
<style scoped lang="scss">
.page-wrapper {
  margin-bottom: 48px;
}
.header-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: $color-primary-100;

  .title {
    font-size: 24px;
    font-style: normal;
    color: $color-primary-100;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.5px;
  }
  .toggle {
    color: $color-primary-100;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.1px;
    text-decoration-line: underline;
    cursor: pointer;
  }
}
.section-wrapper {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 24px;
  border: 1px solid $color-primary-10;
  border-radius: 12px;
  width: 100%;
  margin-top: 16px;
  gap: 14px;
  background-color: $color-surface;
  cursor: pointer;
  transition: all 0.3s ease;
  color: $color-primary-100;
  &:hover {
    transform: scale(1.01);
    transition: all 0.3s ease;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
}
.flex-row-center-start {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.flex-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
}
.title {
  color: $color-primary-100;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.4px;
}

.subtitle {
  color: $color-primary-70;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
</style>
