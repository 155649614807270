import { createRouter, createWebHistory } from "vue-router";
import { computed, nextTick } from "vue";
import {
  getUserCountry,
  LAUNCHED_COUNTRIES,
} from "@/scripts/countries/countries";

import store from "@/store";
import All from "@/routes/your-cloaks/All.vue";
import Favorites from "@/routes/your-cloaks/Favorites.vue";
import Muted from "@/routes/your-cloaks/Muted.vue";
import NumberReuse from "@/routes/NumberReuse.vue";

import { AUTH_V3_ROUTES_ENABLED } from "@/scripts/featureFlags";

import {
  Settings,
  Referral,
  SettingsAccount,
  SettingsForwarding,
  SettingsPersonalInformation,
  SettingsSubscription,
  SettingsPassphrase,
  SettingsCloakedCards,
  Cards,
  Transactions,
  ReferralCode,
  ReferralDenied,
  Shared,
  Trash,
  WalletPage,
  SettingsEmail,
  DataDeletePageGuest,
  DataDeletePageUser,
  CloakedPayLite,
  DataDeleteScanPageGuest,
  AcceptInvitation,
} from "@/routes";

import DataRemoval from "@/routes/DataDeletion/DataRemoval.vue";

import InboxList from "@/components/inbox/InboxList";
import InboxEmail from "@/components/inbox/InboxEmail";
import InboxMessage from "@/components/inbox/InboxMessage";
import InboxDetail from "@/components/inbox/InboxDetail";
import Category from "@/routes/categories/Category";
import Login from "@/routes/guest/Login";
import Forgot from "@/routes/guest/Forgot";
import Register from "@/routes/guest/Register";
import Home from "@/components/home/HomePage";

import Import from "@/routes/import/Import";
import ImportSource from "@/routes/import/ImportSource";
import ImportUpload from "@/routes/import/ImportUpload";
import ImportMapping from "@/routes/import/ImportMapping";
import ImportReview from "@/routes/import/ImportReview";
import ImportReviewEdit from "@/routes/import/ImportReviewEdit";

/* New cloaked-auth version 3 */
import LoginV3 from "@/routes/guest/LoginV3";
import ForgotUsernameV3 from "@/routes/guest/ForgotUsernameV3";
import ForgotUsername from "@/routes/guest/ForgotUsername";
import ForgotPasswordV3 from "@/routes/guest/ForgotPasswordV3";
import RegisterV3 from "@/routes/guest/RegisterV3";
import ResetRecoveryKey from "@/routes/guest/ResetRecoveryKey";
import FreeScanPage from "@/components/feature/data-delete/FreeScanPage.vue";

//eSim
import EsimPage from "@/routes/Pages/EsimPage.vue";
import EsimGetStarted from "@/routes/Pages/EsimGetStarted.vue";
import ESimInfo from "@/components/eSim/ESimInfo.vue";

// New Onboarding Routes
import NewOnboardingGetStarted from "@/components/feature/onboarding-new/NewOnboardingGetStarted";
import NewOnboardingWelcome from "@/components/feature/onboarding-new/NewOnboardingWelcome";
import NewOnboardingBreaches from "@/components/feature/onboarding-new/NewOnboardingBreaches";
import NewOnboardingPasswords from "@/components/feature/onboarding-new/NewOnboardingPasswords";
import NewOnboardingExit from "@/components/feature/onboarding-new/NewOnboardingExit";
// Flow 1
import NewOnboardingHidePhoneEmailMain from "@/components/feature/onboarding-new/NewOnboardingHidePhoneEmailMain";
import { logout } from "@/scripts/actions/auth";
import NewOnboardingInviteMembers from "@/components/feature/onboarding-new/NewOnboardingInviteMembers.vue";

import DataRemovalGraphShared from "@/routes/DataDeletion/components/RequestGraph/DataRemovalGraphShared.vue";
import SubscriptionUpfront from "@/components/feature/subscribe/SubscriptionUpfront.vue";

import SettingsDataRemovalPage from "@/routes/Pages/SettingsDataRemovalPage.vue";

const authenticated = computed(() => {
  return store.getters["authentication/isAuthenticated"];
});

const beforeImportStepEnter = (to, from, next) => {
  if (!store.getters["accountsImporter/getFileName"]) {
    next({ name: "ImportSource" });
  }

  next();
};

const beforeEnterImport = (to, from, next) => {
  store.dispatch("settings/savePrevRouteName", from.name || "Home");
  next();
};

const beforeEnterSettings = (to, from, next) => {
  store.dispatch("settings/savePrevRouteName", from.name || "Home");
  store.dispatch("closeRightPanel");
  return allowOnlyAuthenticatedUsers(to, from, next);
};

const beforeEnterActivity = (to, from, next) => {
  if (authenticated.value) {
    store.dispatch("closeRightPanel");
    return next();
  }
  return next({ name: "login", query: { prevRoute: to.fullPath } });
};

const beforeEnterEsim = (to, from, next) => {
  const userCountry = getUserCountry(store.state.authentication?.user);

  if (
    LAUNCHED_COUNTRIES.includes(userCountry) &&
    store.state.authentication?.user?.flags?.esim_enabled &&
    store.getters["settings/isSubscribed"]
  ) {
    return beforeEnterCloseRightPanel(to, from, next);
  }
  return next({ name: "Home" });
};

const beforeEnterCloseRightPanel = (to, from, next) => {
  store.dispatch("closeRightPanel");
  return allowOnlyAuthenticatedUsers(to, from, next);
};

const beforeEnterCloakedPayLite = (to, from, next) => {
  if (!cloakedPayLite.value) {
    return next({ name: "Home" });
  }
  return next();
};

const allowOnlyGuests = (to, from, next) => {
  if (authenticated.value) {
    if (
      to.query &&
      (to.query.cloaked_client_id ||
        to.query.signup_key ||
        to.query.utm_campaign)
    ) {
      logout();
      return next();
    } else if (to.query?.enable_pay) {
      return next({ path: "/", query: to.query.enable_pay });
    }

    return next({ name: "Home" });
  }
  return next();
};

const allowOnlyAuthenticatedUsers = (to, from, next) => {
  if (authenticated.value) {
    return next();
  }
  return next({ name: "login", query: { prevRoute: to.fullPath } });
};

const ddOnboardingEnabled = computed(() => {
  return store.getters["dataDelete/getDdOnboardingEnabled"];
});

const cloakedPayLite = computed(
  () => store.state.authentication?.user?.flags?.cloaked_pay_lite
);

const allowOnlyFlaggedAndAuthenticatedUsers = (to, from, next) => {
  if (authenticated.value) {
    if (ddOnboardingEnabled.value) {
      // NOTE: and check for dd onboarding feature flag
      return next();
    }
    return next({ name: "Home" });
  }
  return next({ name: "login", query: { prevRoute: to.fullPath } });
};

const isAuthV3Enabled = (to, from, next) => {
  if (!AUTH_V3_ROUTES_ENABLED) {
    return next({ name: "signup" });
  }
  if (authenticated.value) {
    return next({ name: "Home" });
  }
  return next();
};

const isResetRecoveryKeyEnabled = (to, from, next) => {
  if (authenticated.value) {
    return next({ name: "Home" });
  }
  return next();
};

const customEmailEnabled = (to, from, next) => {
  if (!store.state.authentication?.user?.flags?.email_custom_domain) {
    return next({ name: "Home" });
  }
  return next();
};

const beforeEnterCloakDetail = (to, from, next) => {
  const routeName = to.name || "";
  const currentRoute = routeName.toLowerCase();
  const encodedId = currentRoute === "cloak" ? to.params.id : to.params.cloakId;
  const cloakId = hash.decode(encodedId);

  if (cloakId) {
    // NOTE: if cloak is not in list, what do we do?
    store.dispatch("fetchPopulatedData", { id: cloakId }).then((data) => {
      store.dispatch("openCloakDetails", { cloak: data });
    });
  }
  next();
};

const routes = [
  {
    path: "/invitation/:id",
    name: "Accept Invitation",
    component: AcceptInvitation,
    meta: { title: "Accept Invitation" },
  },
  {
    path: "/data-remove-graph",
    name: "DataRemovalGraphShared",
    component: DataRemovalGraphShared,
  },
  {
    path: "/cards",
    name: "Cards",
    component: Cards,
    meta: { title: "Cards" },
    beforeEnter: beforeEnterActivity,
  },
  {
    path: "/wallet",
    name: "Wallet",
    component: WalletPage,
    meta: { title: "Wallet", icon: "credit-card" },
    beforeEnter: beforeEnterActivity,

    children: [
      {
        path: "/wallet/card/:id",
        name: "WalletCard",
        component: WalletPage,
        meta: { title: "Wallet", icon: "credit-card" },
        beforeEnter: beforeEnterActivity,
      },
    ],
  },
  {
    path: "/cloaked-pay-lite",
    name: "CloakedPayLite",
    component: CloakedPayLite,
    meta: { title: "Cloaked Pay Lite", icon: "credit-card" },
    beforeEnter: beforeEnterCloakedPayLite,
  },
  {
    path: "/transactions",
    name: "Transactions",
    component: Transactions,
    meta: { title: "Transactions" },
  },
  {
    path: "/support",
    name: "Support",
    component: All,
    meta: { title: "Recent Activity", support: true },
  },
  {
    path: "/trash",
    name: "Trash",
    component: Trash,
    meta: { title: "Trash", icon: "delete-trash" },
    beforeEnter: beforeEnterCloseRightPanel,
  },
  {
    path: "/onboard*",
    name: "registerOnboard",
    component: Register,
  },
  {
    path: "/auth/referral",
    name: "referralCode",
    component: ReferralCode,
    beforeEnter: allowOnlyGuests,
  },
  {
    path: "/auth/referral-denied",
    name: "referralDenied",
    component: ReferralDenied,
    beforeEnter: allowOnlyGuests,
  },
  /* Legacy Auth V2 routes */
  {
    path: "/auth/v2/login",
    name: "loginv2",
    component: Login,
    props: { version: 2 },
    beforeEnter: allowOnlyGuests,
  },
  {
    path: "/auth/v2/forgot-password",
    name: "forgotv2",
    component: Forgot,
    props: { version: 2 },
    beforeEnter: allowOnlyGuests,
  },
  {
    path: "/auth/v2/signup",
    alias: ["/auth/v2/register", "/auth/v2/sign-up"],
    name: "signupv2",
    component: Register,
    props: { version: 2 },
    beforeEnter: allowOnlyGuests,
  },
  /* End legacy Auth V2 routes */
  {
    path: "/auth/signup",
    alias: ["/auth/register", "/auth/sign-up"],
    name: "signup",
    component: Register,
    beforeEnter: allowOnlyGuests,
  },
  {
    path: "/auth/forgot-password",
    name: "forgot",
    component: Forgot,
    beforeEnter: allowOnlyGuests,
  },
  {
    path: "/auth/forgot-username",
    name: "ForgotUsername",
    component: ForgotUsername,
    beforeEnter: allowOnlyGuests,
  },
  {
    path: "/auth/reset-recovery-key",
    name: "ResetRecoveryKey",
    component: ResetRecoveryKey,
    beforeEnter: isResetRecoveryKeyEnabled,
    children: [
      {
        path: "/auth/reset-recovery-key/:id",
        name: "ResetRecoveryKey",
        component: ResetRecoveryKey,
        beforeEnter: isResetRecoveryKeyEnabled,
      },
    ],
  },
  {
    path: "/auth/login",
    name: "login",
    component: Login,
    beforeEnter: allowOnlyGuests,
  },
  {
    path: "/auth/v3/signup",
    alias: ["/auth/v3/register", "/auth/v3/sign-up"],
    name: "signupv3",
    component: RegisterV3,
    beforeEnter: isAuthV3Enabled,
  },
  {
    path: "/auth/v3/forgot-password",
    name: "ForgotPasswordV3",
    component: ForgotPasswordV3,
    beforeEnter: isAuthV3Enabled,
  },
  {
    path: "/auth/v3/forgot-username",
    name: "ForgotUsernameV3",
    component: ForgotUsernameV3,
    beforeEnter: isAuthV3Enabled,
  },
  {
    path: "/auth/v3/login",
    name: "LoginV3",
    component: LoginV3,
    beforeEnter: isAuthV3Enabled,
  },
  {
    path: "/auth/*",
    name: "auth",
    redirect: { name: "login" },
    beforeEnter: allowOnlyGuests,
  },
  {
    path: "/share/",
    name: "share",
    component: Shared,
    meta: { title: "View your shared identity " },
  },
  {
    path: "/favorites",
    name: "Favorites",
    component: Favorites,
    meta: { title: "Favorites", icon: "favorite-filled" },
    beforeEnter: beforeEnterCloseRightPanel,
  },
  {
    path: "/number-reuse",
    name: "NumberReuse",
    component: NumberReuse,
    meta: { title: "Number Reuse" },
  },
  {
    path: "/ignored",
    name: "Ignored",
    component: Muted,
    meta: { title: "Ignored", icon: "user-block" },
    beforeEnter: beforeEnterCloseRightPanel,
  },
  {
    path: "/start",
    name: "StartCloak",
    props: {
      start: true,
    },
    component: All,
    meta: { title: "Start a Cloak" },
  },
  {
    path: "/settings",
    name: "settings",
    component: Settings,
    meta: { title: "Settings" },
    redirect: { name: "settings.account" },
    beforeEnter: beforeEnterSettings,
    children: [
      {
        path: "account",
        name: "settings.account",
        meta: { title: "Settings", icon: "cog" },
        component: SettingsAccount,
      },
      {
        path: "personal-information",
        name: "settings.personal-information",
        meta: { title: "Settings", icon: "cog" },
        component: SettingsPersonalInformation,
      },
      {
        path: "forwarding",
        name: "settings.forwarding",
        meta: { title: "Settings", icon: "cog" },
        component: SettingsForwarding,
      },
      {
        path: "data-removal",
        name: "settings.dataRemoval",
        meta: { title: "Settings", icon: "cog" },
        component: SettingsDataRemovalPage,
      },
      {
        path: "subscription",
        name: "settings.subscription",
        meta: { title: "Settings", icon: "cog" },
        component: SettingsSubscription,
      },
      {
        path: "recovery",
        name: "settings.recovery",
        meta: { title: "Settings", icon: "cog" },
        component: SettingsPassphrase,
      },
      {
        path: "cloaked-cards",
        name: "settings.cloaked-cards",
        meta: { title: "Settings", icon: "cog" },
        component: SettingsCloakedCards,
      },
      {
        path: "email",
        name: "settings.email",
        meta: { title: "Email" },
        component: SettingsEmail,
        beforeEnter: customEmailEnabled,
      },
    ],
  },
  {
    path: "/inbox",
    alias: "/activities",
    name: "Inbox",
    component: InboxList,
    meta: {
      title: "All Inboxes",
      message: true,
      icon: "inbox-filled",
      nav: "inbox",
    },
    beforeEnter: beforeEnterActivity,
  },
  {
    path: "/inbox/texts",
    alias: ["/inbox/messages", "/activities/texts", "/activities/messages"],
    name: "Texts",
    component: InboxList,
    meta: {
      nav: "inbox",
      title: "Texts",
      message: true,
      icon: "chat",
    },
    beforeEnter: beforeEnterActivity,
  },
  {
    path: "/inbox/texts/:id",
    alias: [
      "/activities/texts/:id",
      "/activities/messages/:id",
      "/inbox/messages/:id",
      "/inbox/calls/:id",
      "/activities/calls/:id",
    ],
    name: "Text",
    component: InboxMessage,
    meta: {
      title: "Inbox",
      message: true,
      icon: "left-arrow",
      showActivityIcons: true,
      inboxDetailPage: true,
    },
    beforeEnter: beforeEnterActivity,
  },
  {
    path: "/inbox/calls",
    alias: "/activities/calls",
    name: "Calls",
    component: InboxList,
    meta: {
      nav: "inbox",
      title: "Calls",
      message: true,
      icon: "phone-filled",
    },
    beforeEnter: beforeEnterActivity,
  },
  {
    path: "/inbox/emails",
    alias: "/activities/emails",
    component: InboxList,
    name: "Emails",
    meta: {
      nav: "inbox",
      title: "Emails",
      message: true,
      icon: "mail",
    },
    beforeEnter: beforeEnterActivity,
  },
  {
    path: "/inbox/emails/:id",
    alias: "/activities/emails/:id",
    name: "Email",
    component: InboxEmail,
    meta: {
      title: "Email",
      message: true,
      icon: "left-arrow",
      showActivityIcons: true,
      inboxDetailPage: true,
    },
    beforeEnter: beforeEnterActivity,
  },
  {
    path: "/inbox/requests",
    alias: "/activities/requests",
    name: "Requests",
    component: InboxList,
    meta: {
      nav: "inbox",
      title: "Requests",
      message: true,
      icon: "requests",
    },
    beforeEnter: beforeEnterActivity,
  },
  {
    path: "/inbox/starred",
    alias: "/activities/starred",
    name: "Starred",
    component: InboxList,
    meta: {
      nav: "inbox",
      title: "Starred",
      message: true,
      icon: "star",
    },
    beforeEnter: beforeEnterActivity,
  },
  {
    path: "/inbox/recent",
    name: "RecentInbox",
    component: InboxList,
    meta: {
      nav: "inbox",
      title: "Recent",
      message: true,
      icon: "clock",
    },
    beforeEnter: beforeEnterActivity,
  },
  {
    path: "/inbox/favorites",
    name: "FavoritesInbox",
    component: InboxList,
    meta: {
      nav: "inbox",
      title: "Favorites",
      message: true,
      icon: "favorite",
    },
    beforeEnter: beforeEnterActivity,
  },
  {
    path: "/inbox/id/:id",
    alias: ["/activities/id/:id", "/id/:id", "/activities/:id"],
    name: "ActivityRouter",
    component: InboxDetail,
    meta: { title: "Inbox", message: true },
    beforeEnter: beforeEnterActivity,
  },
  {
    path: "/cloak/:id/inbox",
    name: "CloakInbox",
    alias: "/cloak/:id/activities",
    component: InboxList,
    meta: {
      title: "Identity Inbox",
      cloak: true,
      nav: "inbox",
    },
    beforeEnter: beforeEnterActivity,
  },
  {
    path: "/category/:id/inbox",
    name: "CategoryInbox",
    component: InboxList,
    meta: { title: "Category Inbox", nav: "inbox" },
    beforeEnter: beforeEnterActivity,
  },
  {
    path: "/category/:id",
    name: "Category",
    component: Category,
    meta: { title: "Categories", icon: "stacked-blocks" },
    beforeEnter: beforeEnterCloseRightPanel,
  },
  {
    path: "/esim/get-started",
    name: "EsimGetStarted",
    component: EsimGetStarted,
    meta: { title: "Esims", icon: "esim-page" },
    beforeEnter: beforeEnterEsim,
  },
  {
    path: "/esim",
    name: "Esim",
    component: EsimPage,
    meta: { title: "Manage eSIM", icon: "esim-page" },
    beforeEnter: beforeEnterCloseRightPanel,
  },
  {
    path: "/esim/removal",
    name: "ESimInfo",
    component: ESimInfo,
    meta: {
      title: "Attention Required",
      icon: "esim-page",
    },
    beforeEnter: beforeEnterActivity,
  },
  {
    path: "/import",
    name: "Import",
    component: Import,
    meta: { title: "Import" },
    redirect: "/import/source",
    beforeEnter: beforeEnterImport,
    children: [
      {
        path: "source",
        name: "ImportSource",
        component: ImportSource,
        meta: { step: 0, title: "Import" },
      },
      {
        path: "upload/:source",
        name: "ImportUpload",
        component: ImportUpload,
        meta: { step: 1, title: "Import" },
      },
      {
        path: "mapping",
        name: "ImportMapping",
        component: ImportMapping,
        meta: { step: 2, title: "Import" },
        beforeEnter: beforeImportStepEnter,
      },
      {
        path: "review",
        name: "ImportReview",
        component: ImportReview,
        meta: { step: 3, title: "Import" },
        beforeEnter: beforeImportStepEnter,
        children: [
          {
            path: "edit/:id",
            name: "ImportReviewIdentityEdit",
            component: ImportReviewEdit,
            meta: { step: 3, title: "Import" },
            beforeEnter: beforeImportStepEnter,
          },
        ],
      },
    ],
  },
  {
    path: "/referrals",
    name: "Referrals",
    component: Referral,
    meta: { title: "Referrals", icon: "gift" },
  },
  {
    path: "/identities",
    name: "All",
    component: All,
    alias: ["/all"],
    meta: { title: "All identities", icon: "blocks" },
  },
  // NOTE: "cloak/:id" route is used by extension
  // adding the id param breaks left nav highlighting functionality
  // which is why its not aliased above
  {
    path: "/cloak/:id",
    name: "Cloak",
    component: All,
    meta: { title: "All identities", icon: "blocks" },
  },
  {
    path: "/subscribe",
    name: "Subscribe",
    component: All,
    meta: { title: "All identities", icon: "blocks", modal: "subscribe" },
    beforeEnter: beforeEnterCloakDetail,
  },
  {
    path: "/data-scan",
    name: "DataDeleteScanPageGuest",
    component: DataDeleteScanPageGuest,
    meta: { title: "Data Scan" },
    beforeEnter: allowOnlyGuests,
  },
  {
    path: "/data-delete",
    name: "DataDeleteGuest",
    component: DataDeletePageGuest,
    meta: { title: "Delete Data" },
    beforeEnter: allowOnlyGuests,
  },
  {
    path: "/onboarding/welcome",
    name: "NewOnboardingWelcome",
    component: NewOnboardingWelcome,
    beforeEnter: allowOnlyFlaggedAndAuthenticatedUsers,
  },
  {
    path: "/onboarding/get-started",
    name: "NewOnboardingGetStarted",
    component: NewOnboardingGetStarted,
    props: true,
    beforeEnter: allowOnlyFlaggedAndAuthenticatedUsers,
  },
  {
    path: "/onboarding/phone-email",
    name: "NewOnboardingPhoneEmail",
    component: NewOnboardingHidePhoneEmailMain,
    beforeEnter: allowOnlyFlaggedAndAuthenticatedUsers,
  },
  {
    path: "/onboarding/breaches",
    name: "NewOnboardingBreaches",
    component: NewOnboardingBreaches,
    beforeEnter: allowOnlyFlaggedAndAuthenticatedUsers,
  },
  {
    path: "/onboarding/passwords",
    name: "NewOnboardingPasswords",
    component: NewOnboardingPasswords,
    beforeEnter: allowOnlyFlaggedAndAuthenticatedUsers,
  },
  {
    path: "/onboarding/exit",
    name: "NewOnboardingExit",
    component: NewOnboardingExit,
    beforeEnter: allowOnlyFlaggedAndAuthenticatedUsers,
  },
  {
    path: "/onboarding/invite",
    name: "NewOnboardingInviteMembers",
    component: NewOnboardingInviteMembers,
    beforeEnter: allowOnlyFlaggedAndAuthenticatedUsers,
  },
  {
    path: "/search-exposures",
    name: "DataDeleteUser",
    component: DataDeletePageUser,
    meta: { title: "Delete Data" },
  },
  {
    path: "/free-scan",
    name: "FreeScan",
    component: FreeScanPage,
    meta: { title: "Free Scan" },
    beforeEnter: allowOnlyGuests,
  },
  {
    path: "/search-exposures",
    name: "DataDeleteUser",
    component: DataDeletePageUser,
    meta: { title: "Delete Data" },
  },
  {
    path: "/data-remove",
    name: "DataRemove",
    component: DataRemoval,
    meta: { title: "Data removal", icon: "data-delete/remove-data" },
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    alias: ["/!**/auth**", "/"],
    meta: { title: "Home", icon: "home" },
    beforeEnter: allowOnlyAuthenticatedUsers,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
    beforeEnter: allowOnlyAuthenticatedUsers,
  },
  {
    path: "/subscribe-now",
    name: "SubcriptionUpFront",
    component: SubscriptionUpfront,
    meta: { title: "Subscribe Now" },
    beforeEnter: allowOnlyGuests,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export const IMPORT_ROUTES = [
  "Import",
  "ImportSource",
  "ImportUpload",
  "ImportMapping",
  "ImportReview",
  "ImportReviewIdentityEdit",
];

router.beforeEach((to, from, next) => {
  const except = ["Cloak"];
  if (except.indexOf(to.name) === -1 && except.indexOf(from.name) === -1) {
    window.scrollTo(0, 0);
  }
  if (store.state.ui.open_dialog) {
    store.commit("unclipBody");
  }
  store.commit("closeCloak");
  if (to.path === "/") {
    const has_enable_pay =
      to.query.enable_pay === "pay_2024" ||
      (from && from?.query?.enable_pay === "pay_2024");
    const persistedQuery = has_enable_pay ? { enable_pay: "pay_2024" } : null;
    // NOTE: i have to leave this navigation guard here otherwise it causes an infinite routing loop
    return next({
      path: "/home",
      name: "Home",
      component: Home,
      meta: { title: "Home", icon: "home" },
      query: persistedQuery,
    });
  }
  next();
});
router.afterEach((to, from) => {
  store.dispatch("navigation/updateNavHistory", from.name);
  nextTick(() => {
    document.title = to.meta.title ? `${to.meta.title} - Cloaked` : "Cloaked";
  });
});

export default router;
