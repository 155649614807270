<script setup>
import store from "@/store";
import {
  UiMenu,
  UiMenuButton,
  UiMenuScrollBody,
  UiMenuSeparator,
} from "@/components";
import inlineSvg from "@/components/InlineSvg";
import { computed, reactive } from "vue";
import { constants } from "@/scripts";
import { useRoute, useToast } from "@/hooks";
import EditCategoryButton from "@/components/categories/EditCategoryButton.vue";
import CategoryService from "@/api/actions/category-service";

const route = useRoute();
const toast = useToast();

const props = defineProps({
  selected: Array,
  identityList: Array,
  ignoreDisplay: String,
  filterOptions: Array,
  viewType: {
    type: String,
    default: constants.VIEW_TYPE.GRID,
  },
});

const emit = defineEmits([
  "select",
  "ignore",
  "unignore",
  "delete",
  "filter",
  "transferList",
  "toggleView",
]);

const sortedOptions = [
  {
    label: "Date created",
    value: "-created_at",
  },
  {
    label: "Alphabetical",
    value: "nickname",
  },
];
const sorted = computed(() => {
  return store.state?.ui?.identitySortType;
});

const sortedLabel = computed(() => {
  return sortedOptions.find((f) => f.value === sorted.value)?.label;
});

const state = reactive({
  /*
  user will only see filter options for types that they have, ie if none of their identities have TOTP fields, they will
  not see one-time passcode as an option
  */
  filtered: props.filterOptions ? props.filterOptions[0] : [],
});

const categories = computed(() => {
  return store.state.categories.custom;
});

const identity = computed(() => {
  if (props.selected.length === 1) {
    return props.identityList.find((f) => f.id === props.selected[0]);
  }
  return null;
});

const currentCategoryId = computed(() => {
  if (identity.value?.categories) {
    return identity.value.categories[0];
  }
  return null;
});

const currentCategoryName = computed(() => {
  if (props.selected.length > 1) {
    return "all categories";
  }
  const currentCat = categories.value.find(
    (cat) => currentCategoryId.value === cat.id
  );
  if (currentCat) {
    return currentCat.name;
  }
  return null;
});

function isInCategory(cat) {
  if (identity.value?.categories) {
    return identity.value.categories.includes(cat.id);
  }
  return false;
}

function moveToCategory(cat) {
  emit("transferList", cat);
}

function toggleAction(action) {
  emit(action);
}

function onSortOptionSelect(opt) {
  store.dispatch("setSortType", opt.value);
}

function onFilterOptionSelect(opt) {
  emit("filter", opt.value);
  state.filtered = opt;
}

function removeIdentityFromCategory() {
  toast.success(
    `Removing identit${props.selected.length > 1 ? "ies" : "y"} from "${
      currentCategoryName.value
    }"...`
  );
  Promise.allSettled(
    props.selected.map((CloakId) => {
      const cloak = props.identityList.find((f) => f.id === CloakId);
      CategoryService.removeCloaksFromCategory(cloak.categories[0], [
        cloak?.id,
      ]);
    })
  )
    .then(() => {
      toast.success(
        `Identit${props.selected.length > 1 ? "ies" : "y"} removed!`
      );
    })
    .catch(() => {
      toast.error(
        `Error removing identit${
          props.selected.length > 1 ? "ies" : "y"
        } from "${currentCategoryName.value}"`
      );
    });
}
</script>

<template>
  <div class="multiselect-filters">
    <div class="select-all">
      <div
        class="filter-item non-menu"
        v-if="selected.length > 0"
        @click="toggleAction('select')"
      >
        <inlineSvg
          name="minus-round-unfilled"
          key="minus-round-filled"
          width="20"
        />
        <p>Deselect all</p>
      </div>
      <div v-else class="filter-item-row">
        <div @click="toggleAction('select')" class="filter-item non-menu">
          <inlineSvg name="select" width="16" key="select-all-identity" />
          <p>Select all</p>
        </div>
        <div v-if="route.name.toLowerCase().includes('category')">
          <EditCategoryButton :categoryId="route.params.id" />
        </div>
      </div>
      <div v-if="selected.length > 0" class="filter-items-group">
        <div class="filter-item">
          <UiMenu
            v-if="selected.length > 0 && categories.length > 0"
            width="240px"
            max-height="295px"
            placement="bottom-start"
            hasContentClickClose
          >
            <div class="filter-item no-padding">
              <inlineSvg name="arrow-right-filter" />
              <p>Move category</p>
            </div>
            <template #content>
              <UiMenuScrollBody>
                <UiMenuButton
                  v-if="currentCategoryName"
                  :title="`Remove from &quot;${currentCategoryName}&quot;`"
                  @click="removeIdentityFromCategory"
                >
                  <template v-slot:icon>
                    <inlineSvg name="minus-outline" />
                  </template>
                </UiMenuButton>
                <UiMenuSeparator
                  v-if="categories.length && currentCategoryName"
                />
                <UiMenuButton
                  v-for="(category, index) in categories"
                  :key="index"
                  :title="category.name"
                  :active="isInCategory(category)"
                  @click="() => moveToCategory(category)"
                  class="multiselect-filters__category-button titlecase"
                >
                  <template v-slot:icon>
                    <inlineSvg
                      name="checkmark_gray"
                      v-if="isInCategory(category)"
                    />
                  </template>
                </UiMenuButton>
              </UiMenuScrollBody>
            </template>
          </UiMenu>
        </div>
        <div
          class="filter-item non-menu"
          @click="
            ignoreDisplay.toLowerCase() === 'ignore'
              ? toggleAction('ignore')
              : toggleAction('unignore')
          "
        >
          <inlineSvg
            :key="ignoreDisplay.toLowerCase()"
            :name="
              ignoreDisplay.toLowerCase() === 'ignore'
                ? 'notification-bell'
                : 'unmuted'
            "
            width="15"
          />
          <p>Ignore</p>
        </div>
        <div class="filter-item non-menu" @click="toggleAction('delete')">
          <inlineSvg name="delete-actionbar" width="15" />
          <p>Delete</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div v-if="props.filterOptions?.length > 0" class="sort">
        <div class="sort-item">
          <UiMenu placement="bottom-start">
            <div class="selected-sort-item">
              <span class="filter-label">Filter: </span>
              <p>{{ state.filtered?.label || "All" }}</p>
              <inlineSvg name="chevron-down" width="13" />
            </div>
            <template #content>
              <UiMenuScrollBody>
                <UiMenuButton
                  v-for="item in filterOptions"
                  :key="item?.value"
                  :title="item?.label"
                  class="titlecase"
                  @click="() => onFilterOptionSelect(item)"
                >
                  <template #icon>
                    <inlineSvg
                      name="checkmark_gray"
                      v-if="state.filtered.value === item.value"
                    />
                  </template>
                </UiMenuButton>
              </UiMenuScrollBody>
            </template>
          </UiMenu>
        </div>
        <div class="sort-item">
          <UiMenu placement="bottom-start">
            <div class="selected-sort-item">
              <span class="filter-label">Sort: </span>
              <p>{{ sortedLabel }}</p>
              <inlineSvg name="chevron-down" width="13" />
            </div>
            <template #content>
              <UiMenuScrollBody>
                <UiMenuButton
                  v-for="item in sortedOptions"
                  :key="item.value"
                  :title="item.label"
                  @click="() => onSortOptionSelect(item)"
                >
                  <template #icon>
                    <inlineSvg
                      name="checkmark_gray"
                      v-if="sorted === item.value"
                    />
                  </template>
                </UiMenuButton>
              </UiMenuScrollBody>
            </template>
          </UiMenu>
        </div>
      </div>
      <div class="separator"></div>
      <div
        class="viewButton"
        :class="{ active: props.viewType === constants.VIEW_TYPE.GRID }"
        @click="emit('toggleView', 'grid')"
      >
        <inlineSvg
          name="blocks"
          :class="{ hidden: props.viewType !== constants.VIEW_TYPE.GRID }"
        />
        <inlineSvg
          name="blocks-outline"
          :class="{ hidden: props.viewType === constants.VIEW_TYPE.GRID }"
        />
        <span class="viewButtonText">Grid View</span>
      </div>
      <div
        class="viewButton"
        :class="{ active: props.viewType === constants.VIEW_TYPE.LIST }"
        @click="emit('toggleView', 'list')"
      >
        <inlineSvg
          name="blocks-rect-filled"
          :class="{ hidden: props.viewType !== constants.VIEW_TYPE.LIST }"
        />
        <inlineSvg
          name="blocks-rect-outline"
          :class="{ hidden: props.viewType === constants.VIEW_TYPE.LIST }"
        />
        <span class="viewButtonText">List View</span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.multiselect-filters {
  height: $ui-toolbar-height;
  display: flex;
  align-items: center;
  justify-content: space-between;
  fill: $color-primary-50;
  position: sticky;
  width: 100%;
  border-bottom: 1px solid $color-primary-10;
  background: $color-surface;
  z-index: 2;
  flex-wrap: wrap;
  top: $top-bar-height;
  padding: 0 24px;

  .viewButton {
    background-color: transparent;
    color: $color-primary-90;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 8px;
    padding: 8px;
    border-radius: 8px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    > svg {
      color: $color-primary-90;
      height: 16px;
      width: 16px;
    }
    transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
    &:hover {
      transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
      background-color: $color-primary-5;
    }
    &.active {
      background-color: $color-primary-10;
      color: $color-primary-100;
      > svg {
        color: $color-primary-100;
        height: 16px;
        width: 16px;
      }
    }

    .hidden {
      display: none;
    }
  }
  .select-all {
    display: flex;
    .filter-items-group {
      display: flex;
    }
    .filter-item {
      display: flex;
      align-items: center;
      column-gap: 8px;
      padding: 4px 10px;
      cursor: pointer;
      border-radius: 8px;
      height: 30px;
      transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
      &:hover {
        transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
        background: $color-primary-10;
      }
      &.non-menu {
        &:hover {
          transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
          transform: scale(1.03);
        }
      }
      &.small-gap {
        column-gap: 2px;
      }
      svg {
        color: $color-accent;
        path {
          color: $color-accent;
        }
      }
      p {
        color: $color-primary-100;
        font-size: 12px;
        font-weight: 500;
      }
      &.no-padding {
        padding: 0;
      }
    }
  }
  .row {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    .sort {
      display: flex;
      align-items: center;
      column-gap: 16px;
      .sort-item {
        font-size: 12px;
        display: flex;
        align-items: center;
        cursor: pointer;
        &:first-of-type {
          margin-left: 10px;
        }
        .ui-menu {
          border-radius: 8px;
          padding: 6px 12px;
          z-index: 99;
          transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
          &:hover {
            transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
            background: $color-primary-10;
          }
        }
        .selected-sort-item {
          display: flex;
          align-items: center;
          span {
            color: $color-primary-50;
          }
          p {
            text-decoration: underline;
            font-weight: 500;
            margin: 0 6px 0 3px;
            color: $color-accent;
          }
          color: $color-accent;
          svg {
            margin-top: 2px;
          }
        }
        .filter-label {
          color: $color-primary-90;
        }
        .selected {
          color: $color-primary-10;
          font-weight: 600;
        }
      }
    }
  }

  .app--visible-banner & {
    top: 100px;
  }

  .text {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 6px 10px;
    border-radius: 8px;
    transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
    &:hover {
      transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
      background: $color-primary-10;
    }
    span {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      display: inline-block;
      margin-left: 8px;
      color: $color-primary-100;
    }
  }

  .buttons {
    display: flex;
    gap: 0px;

    &__button {
      border: none;
      border-radius: 8px;
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      border-radius: 50%;
      background: $color-surface;

      svg {
        max-width: 24px;
        height: auto;
        color: $color-primary-100;
      }

      transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
      &:hover {
        cursor: pointer;
        background-color: $color-primary-30;
        transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
      }

      .toggle-select-all {
        color: $color-primary-100;
      }

      .show-move {
        position: absolute;
        background-color: $color-primary-100;
        padding: 10px;
        z-index: 50;
        min-width: 180px;
        max-width: 180px;
        top: 40px;
        left: 0px;
        box-shadow: -22.9px -8.90123px 26.7037px rgba(1, 2, 24, 0.05),
          13.3518px 12.35px 26.7037px rgba(1, 2, 24, 0.16);
        border-radius: 8px;

        h3 {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          text-align: left;
          padding: 0 5px;
        }
      }
    }
  }
}

.separator {
  width: 1px;
  height: 24px;
  background-color: $color-primary-10;
  margin: 0 12px;
}

.filter-item-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

@media (max-width: 1290px) {
  .viewButtonText {
    display: none;
  }
}

.titlecase {
  text-transform: capitalize;
}
</style>
