<script setup>
import { useAttrs } from "vue";
import AppModalClose from "@/components/ui/AppModalClose";
import AppModalContent from "@/components/ui/AppModalContent";
import UiButton from "@/components/feature/onboarding-new/UiButton.vue";

const attrs = useAttrs();
const props = defineProps({
  topTitle: {
    type: String,
    default: "Ultimate security",
  },
  secondTitle: {
    type: String,
    default: "for calls & texts",
  },
  subtitle: {
    type: String,
    default:
      "Cloaked eSIM is the most secure way to communicate from your existing mobile device.",
  },
  highlightFeatures: {
    type: Array,
    default: () => [],
  },
  buttonLabel: {
    type: String,
    default: "",
  },
  buttonAction: {
    type: Function,
    default: () => {},
  },
});
const emit = defineEmits(["close"]);
</script>

<template>
  <AppModalContent width="1000px">
    <AppModalClose is-absolute @close="emit('close')" />
    <div class="swirl">
      <div class="swirl__left">
        <div>
          <img
            class="swirl__logo"
            :src="require('@/assets/images/esim-header.png')"
          />
          <h2 class="swirl__gradient-text">{{ props.topTitle }}</h2>
          <h2 class="swirl__header">{{ props.secondTitle }}</h2>
          <p class="swirl__subtitle">
            {{ props.subtitle }}
          </p>
          <div class="swirl__features">
            <div class="swirl__feature">
              <img :src="require('@/assets/images/esim-sim-swap.png')" />
              <p>Prevent SIM-swapping and fraud</p>
            </div>
            <div class="swirl__feature">
              <img :src="require('@/assets/images/esim-blue.png')" />
              <p>Blue-bubble (iMessage) compatible</p>
            </div>
            <div class="swirl__feature">
              <img :src="require('@/assets/images/esim-purple.png')" />
              <p>Never share your personal number again</p>
            </div>
          </div>

          <div
            v-if="props.highlightFeatures?.length > 0"
            class="swirl__highlight"
          >
            <template v-for="feature in props.highlightFeatures" :key="feature">
              <div class="swirl__highlight">
                <img :src="require('@/assets/images/esim-highlight.png')" />
                <p>{{ feature }}</p>
              </div>
            </template>
          </div>
          <div v-if="props.buttonLabel" class="swirl__action-btn">
            <UiButton width="275px" gradient @click="props.buttonAction">{{
              props.buttonLabel
            }}</UiButton>
          </div>
        </div>
        <div class="swirl__footer">
          <p class="swirl__tc">
            Cloaked eSIM is subject to phone compatibility and appropriate
            coverage area.
          </p>
          <p class="swirl__tc">
            <a target="_blank" href="https://www.cloaked.app/terms-of-service"
              >See Full Terms.</a
            >
          </p>
        </div>
      </div>
      <div class="swirl__right">
        <img :src="require('@/assets/images/esim-iphone.png')" />
      </div>
    </div>
  </AppModalContent>
</template>
<style scoped lang="scss">
.swirl {
  background: url("@/assets/images/esim-BG.png") no-repeat;
  background-size: cover;
  padding: 40px 40px 40px 40px;
  border-radius: 28px;
  display: flex;
  text-align: left;
  color: $color-primary-0-light;
  width: 1000px;

  &__left {
    display: flex;
    flex-direction: column;
    width: 70%;
    flex-grow: 1;
  }
  &__right {
    display: flex;
    justify-content: right;
    align-items: baseline;
    width: 30%;
    margin-bottom: -42px;
    margin-right: -30px;
    img {
      width: 100%;
      height: 100%;
      max-height: 100%;
      flex-shrink: 0;
      object-fit: cover;
    }
  }

  &__logo {
    width: 335px;
    margin-bottom: 6px;
  }

  &__gradient-text {
    font-size: 63px;
    background: $cloaked-gradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
    line-height: 56px;
  }
  &__header {
    font-size: 63px;
    font-weight: 600;
    line-height: 56px;
  }
  &__subtitle {
    font-size: 20px;
    margin: 20px 0;
  }
  &__features {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
  &__feature {
    display: flex;
    align-items: center;
    font-size: 15px;
    margin-bottom: 14px;
    img {
      margin-right: 11px;
      height: 30px;
      width: 50px;
    }
  }
  &__highlight {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    img {
      flex-shrink: 0;
    }
  }
  &__action-btn {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  &__footer {
    flex-grow: 0;
    margin-top: auto;
  }
  &__tc {
    font-size: 10px;
    color: $color-primary-30-light;
    a {
      text-decoration: underline;
    }
  }
}
</style>
