import api from "@/api/api";
import store from "@/store";
import IdentityService from "@/api/actions/identity-service";

export default class CardsServices {
  static async postCreateAPaymentSource(payload) {
    return await api().post(`/api/v1/card/vendor-flow/`, payload);
  }
  static async postActiveKYC(values) {
    return api().post("/api/v1/kyc/", values);
  }

  static async getPaymentSource() {
    return await api()
      .get(`/api/v1/card/`)
      .then((resp) => {
        store.dispatch("fundingSourcesList", resp.data.results);
      })
      .catch(() => {});
  }

  static async deleteFundingSource(id) {
    return await api().delete(`/api/v1/card/${id}/?force=true`);
  }

  static async deleteCard(identityId, cardId) {
    IdentityService.patchIdentityUpdatedAt(identityId);
    return await api().delete(
      `/api/v1/cloaked/identity/${identityId}/card/${cardId}/delete/`
    );
  }

  static async lockCard(identityId, cardId) {
    IdentityService.patchIdentityUpdatedAt(identityId);
    return await api().post(
      `/api/v1/cloaked/identity/${identityId}/card/${cardId}/lock/`
    );
  }

  static async unlockCard(identityId, cardId) {
    IdentityService.patchIdentityUpdatedAt(identityId);
    return await api().post(
      `/api/v1/cloaked/identity/${identityId}/card/${cardId}/unlock/`
    );
  }

  static async patchUpdateCloakedCardDetails(cardId, payload) {
    return await api().patch(`/api/v1/cloaked/card/${cardId}/`, payload);
  }

  static async patchUpdateCardDetails(id, payload) {
    return await api().patch(`/api/v1/card/${id}/`, payload);
  }

  static async getPrimaryCard() {
    return await api()
      .get(`/api/v1/card/primary/`)
      .then((resp) => {
        store.dispatch("addPrimaryCard", resp.data);
        return resp;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          return;
        }
      });
  }

  static async getCardList() {
    return await api()
      .get(`/api/v1/cloaked/card?page_size=50`)
      .then((response) => {
        store.dispatch("addCardList", response.data);
      });
  }

  static async getCancelCardList() {
    return await api()
      .get(`/api/v1/cloaked/card?deleted=true&page_size=50`)
      .then((response) => {
        store.dispatch("addCardList", response.data);
      });
  }

  static async getGiftCardList() {
    return await api()
      .get(`/api/v1/cloaked/card/gift-cards?page_size=50`)
      .then((response) => {
        store.dispatch("addCardList", response.data);
      });
  }

  static async createIdentityCard(identityId, payload) {
    IdentityService.patchIdentityUpdatedAt(identityId);

    return await api().post(
      `/api/v1/cloaked/identity/${identityId}/card/mastercard/`,
      payload
    );
  }

  static async getTransactions() {
    return await api()
      .get(`/api/v1/cloaked/card/transaction/?page_size=100`)
      .then((response) => {
        store.dispatch("setTransactions", response?.data);
        return response;
      })
      .catch((error) => {
        store.dispatch("setTransactions", { count: 0, results: [] });
        return error;
      });
  }

  static async getGiftCardTransactions(id) {
    return await api()
      .get(`/api/v1/cloaked/card/transaction/gifted-card/?card=${id}`)
      .then((response) => {
        store.dispatch("setTransactions", response?.data);
        return response;
      })
      .catch((error) => {
        store.dispatch("setTransactions", { count: 0, results: [] });
        return error;
      });
  }

  static async getIdentityTransactions(id, canceled) {
    return await api()
      .get(`/api/v1/cloaked/identity/${id}/transaction?canceled=${canceled}`)
      .then((response) => {
        store.dispatch("setTransactions", response?.data);
        return response;
      })
      .catch((error) => {
        store.dispatch("setTransactions", { count: 0, results: [] });
        return error;
      });
  }

  static async getIdentityCards(cloakId) {
    const url = `/api/v1/cloaked/identity/${cloakId}/card/`;
    return api()
      .get(url)
      .then(({ data }) => {
        store.commit("identityCards", data.results);
      });
  }

  static async getSingleIdentityCard(cloakId, cardId) {
    const url = `/api/v1/cloaked/identity/${cloakId}/card/${cardId}`;
    return api()
      .get(url)
      .then((response) => {
        store.commit("currentCard", response.data);
        return response;
      });
  }

  static async getSingleGiftCard(cardId) {
    const url = `/api/v1/cloaked/card/${cardId}/gift-card/details/`;
    return api()
      .get(url)
      .then((response) => {
        store.commit("currentCard", response.data);
        return response;
      });
  }

  static async kycRetreive() {
    return api()
      .get(`/api/v1/kyc/retrieve/`)
      .then((response) => {
        store.commit("cardInformation", response.data);
      });
  }

  static async kycUpdate(payload) {
    return api().patch(`/api/v1/kyc/update/`, payload);
  }

  static async getFundingSources() {
    return await api()
      .get(`/api/v1/card/`)
      .then((response) => {
        store.dispatch("fundingSourcesList", response.data);
      });
  }

  static async enableSettings(payload) {
    return api().post("/api/v1/card/settings/enable/", payload);
  }

  static async getCardSettings() {
    return api()
      .get("/api/v1/card/settings/")
      .then((response) => {
        store.commit("cardSettings", response.data);
      });
  }

  static async addToWaitlist() {
    return api()
      .post("/api/v1/card/waitlist/")
      .then(() => {
        if (store.state.authentication?.user) {
          store.state.authentication.user.card_wait_list = true;
        }
      });
  }

  static async payAll() {
    return api().post("/api/v1/cloaked/card/pay-all/");
  }

  static async payCard(payload) {
    return api().post("/api/v1/cloaked/card/payments/", payload);
  }

  static async getStatements() {
    return api()
      .get("/api/v1/card/report/statement/ ")
      .then((response) => {
        store.state.cards.statements = response.data;
      });
  }

  static async getCardStatement(id) {
    return api().get(`/api/v1/card/report/statement/${id}/`);
  }

  static async exportTransactions() {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split("T")[0];

    return api().get(
      `/api/v1/cloaked/card/transaction/export?start_date=2023-01-01&end_date=${formattedDate}`
    );
  }

  static async patchTransactionNote(id, payload) {
    return api().patch(`/api/v1/cloaked/card/transaction/${id}/`, payload);
  }
}
