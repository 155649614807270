<script setup>
import { ref, markRaw, computed } from "vue";
import store from "@/store";
import inlineSvg from "@/components/InlineSvg.vue";
import CardsServices from "@/api/actions/cards-services";
import UserService from "@/api/actions/user-service";
import { useToast } from "@/hooks";
import MultiCardModal from "@/components/Wallet/MultiCardModal.vue";

const toast = useToast();

const mockOutstandingBalance = ref(61039); // Mock data in cents ($610.39)

const mockCards = ref([
  {
    name: "Postmates",
    balance_due: 10000, // $100.00 in cents
    cloaked_card: 26623, //some fake id
    funding_source: "ssh334h3hk6k4k", //some fake funding source
    icon: "brandLogos/postmates",
  },
  {
    name: "Grubhub",
    balance_due: 24010, // $240.10 in cents
    cloaked_card: 26623, //some fake id
    funding_source: "ssh334h3hk6k4k", //some fake funding source
    icon: "brandLogos/grubhub",
  },
  {
    name: "FaceBook",
    balance_due: 8029, // $80.29 in cents
    cloaked_card: 26623, //some fake id
    funding_source: "ssh334h3hk6k4k", //some fake funding source
    icon: "brandLogos/facebook",
  },
  {
    name: "Target",
    balance_due: 16000, // $160.00 in cents
    cloaked_card: 26623, //some fake id
    funding_source: "ssh334h3hk6k4k", //some fake funding source
    icon: "brandLogos/target",
  },
  {
    name: "Tiktok",
    balance_due: 3000, // $30.00 in cents
    cloaked_card: 26623, //some fake id
    funding_source: "ssh334h3hk6k4k", //some fake funding source
    icon: "brandLogos/tiktok",
  },
]);

const currentCard = ref(null);

function selectCard(card) {
  currentCard.value = card.name;
}

function convertToDollar(amount) {
  return (amount / 100)
    .toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    })
    .replace(/\\.0+$/, "");
}

function payReceivePayments() {
  CardsServices.payAll().then(() => {
    UserService.getUserDetails().then(() => {
      toast.success("Payment successful");
    });
  });
}

function openMultiCardModal(card) {
  store.dispatch("openModal", {
    customTemplate: {
      template: markRaw(MultiCardModal),
      props: {
        show: true,
        card: card,
        outstandingBalance: card.balance_due,
      },
    },
  });
}
</script>

<template>
  <div class="payment-panel">
    <div class="header">
      <h2 class="outstanding-balance">
        Balance due: {{ convertToDollar(mockOutstandingBalance) }}
      </h2>
    </div>
    <div class="card-breakdown">
      <div
        v-for="(card, index) in mockCards"
        :key="index"
        class="card-item"
        @click="selectCard(card)"
      >
        <div class="card-info">
          <div class="card-name">{{ card.name }}</div>
        </div>
        <div class="balance-due">
          {{ convertToDollar(card.balance_due) }}
        </div>
        <div class="arrow-icon" @click="openMultiCardModal(card)">
          <inlineSvg name="chevron-right" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.payment-panel {
  padding: 20px;
}
.header {
  margin-bottom: 20px;
}
.card-breakdown {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.card-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;
  border-radius: 10px;
  background-color: $color-primary-100;
  cursor: pointer;
}
.card-info {
  display: flex;
  align-items: center;
}
.card-icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.card-name {
  font-weight: 500;
  font-size: 16px;
  width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.balance-due {
  font-size: 16px;
  color: #ff3b30;
}
.arrow-icon {
  width: 24px;
  height: 24px;
  margin-left: auto;
}
.outstanding-balance {
  color: $color-primary-100;
}
</style>
