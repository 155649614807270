<script setup>
import UiHeader from "@/components/feature/onboarding-new/UiHeader.vue";
import UiButton from "@/components/feature/onboarding-new/UiButton.vue";
import UiButtonRow from "@/components/feature/onboarding-new/UiButtonRow.vue";
import UiPageWrapper from "@/components/feature/onboarding-new/UiPageWrapper.vue";
import EsimMobileToggleWidget from "@/components/eSim/EsimMobileToggleWidget.vue";

import { PH_SCREEN_EVENT_ESIM_ACTIVATING_ESIM_SCREEN } from "@/scripts/posthogEvents";
import { computed } from "vue";

const emit = defineEmits(["next", "updateDeviceType"]);
const props = defineProps({
  deviceType: {
    type: String,
    default: "ios",
  },
});

const instructions = computed(() => {
  return props.deviceType === "ios"
    ? "Open your device's cellular data settings by navigating to Settings > Cellular > Cellular Data."
    : "Open your device's cellular data settings by navigating to Settings > Network & internet > SIMs, then tap on Cloaked eSIM";
});
</script>
<template>
  <UiPageWrapper
    showLogo
    logo="cloaked-esim-logo"
    :screenEvent="PH_SCREEN_EVENT_ESIM_ACTIVATING_ESIM_SCREEN"
    :esimStep="6"
  >
    <UiHeader>
      <h1>Set cellular data preferences</h1>
      <h5>
        Before continuing, be sure your cellular data is set to your Primary
        cell provider and ensure mobile data is off for your Cloaked eSIM.
      </h5>
    </UiHeader>

    <EsimMobileToggleWidget
      class="esim-mobile-toggle-widget"
      :deviceType="props.deviceType"
      :imgName="`${props.deviceType}-data`"
      :instructions="instructions"
      center
      @updateDeviceType="emit('updateDeviceType')"
    />
    <UiButtonRow>
      <UiButton gradient class="device-check-button" @click="emit('next')"
        >Continue</UiButton
      >
    </UiButtonRow>
  </UiPageWrapper>
</template>
<style scoped lang="scss">
.esim-mobile-toggle-widget {
  margin-bottom: 100px;
}
</style>
