<script setup>
import AtomHomeTitle from "@/components/Atoms/AtomHomePageBanner/AtomHomeTitle.vue";
import AtomHomeBannerBody from "@/components/Atoms/AtomHomePageBanner/AtomHomeBannerBody.vue";
import Button from "@/components/Button.vue";
import router from "@/routes/router";
import { UK_NUMBER_SUPPORT_END_DATE_ORDINAL } from "@/scripts/constants";

const props = defineProps({
  newBadge: {
    type: Boolean,
    default: false,
  },
  ukNumberCount: {
    type: Number,
    default: 0,
  },
});
const handleActionButtonClick = () => {
  router.push({ name: "ESimInfo" });
};
</script>
<template>
  <section>
    <div class="widget-wrapper">
      <AtomHomeTitle>
        <div v-if="props.newBadge" class="bubble">New!</div>
      </AtomHomeTitle>

      <AtomHomeBannerBody image="esim" :rainbowBackground="true">
        <div class="background">
          <h3 class="title-text">
            On {{ UK_NUMBER_SUPPORT_END_DATE_ORDINAL }},
            <span class="urgent-text"
              >{{ props.ukNumberCount }} of your phone numbers will be
              deactivated</span
            >
            due to new regulations.
          </h3>

          <Button
            type="secondary"
            @click="handleActionButtonClick"
            style="background-color: transparent"
            class="button-space"
            >Take action now</Button
          >
        </div>
      </AtomHomeBannerBody>
    </div>
  </section>
</template>
<style scoped lang="scss">
.bubble {
  display: flex;
  padding: 1px 6px;
  align-items: center;
  background-color: $color-success;
  color: $color-primary-100-light;
  border-radius: 41px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.title-text {
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  .urgent-text {
    text-decoration: underline;
    color: $color-alert;
  }
}
.button-space {
  margin-top: 20px;
}
.background {
  max-width: 73%;
}
.widget-wrapper {
  margin-bottom: 20px;
}
</style>
