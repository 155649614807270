import { computed, ref, watch } from "vue";
import { usePlans } from "@/components/feature/subscribe/composables";
import { toValue } from "@vueuse/core/index";
import store from "@/store";
import { usePostHogFeatureFlag, useTimeLimitedDiscount } from "@/composables";

const { showCountdown } = useTimeLimitedDiscount();

const selectedPlanOption = ref(null);

export const usePlanOptions = ({
  intent,
  selectedBillingCycle,
  defaultSelectedOption,
}) => {
  const { allPlans, activePlan } = usePlans();
  const { featureFlag } = usePostHogFeatureFlag("pricing-ui");

  const allOptions = computed(() => [
    ...allPlans.value
      .filter((plan) => plan.provider === "stripe")
      .map((plan) => {
        const paypalPlan =
          allPlans.value.find(
            (paypalPlan) =>
              paypalPlan.provider === "paypal" &&
              paypalPlan.type === plan.type &&
              paypalPlan.recurring_interval === plan.recurring_interval
          ) ?? null;

        function discountPrice(price, discount) {
          const discountedPrice = (price * (100 - discount)) / 100;
          return discountedPrice.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          });
        }

        let planPricing = null;

        // TODO: replace locally calculated discount with an actual discount from payment intent
        if (featureFlag.value === "per-member-per-month-price") {
          planPricing =
            plan.max_members > 1
              ? `${
                  showCountdown.value
                    ? `<s>$${
                        plan.displayMonthlyPricePerUser
                      }</s> ${discountPrice(
                        plan.displayMonthlyPricePerUser,
                        20
                      )}/member per month`
                    : `$${plan.displayMonthlyPricePerUser}/member per month`
                }`
              : `${
                  showCountdown.value
                    ? `<s>$${
                        plan.displayMonthlyPricePerUser
                      }</s> ${discountPrice(
                        plan.displayMonthlyPricePerUser,
                        20
                      )}/per month</s>`
                    : `$${plan.displayMonthlyPricePerUser}/per month`
                }`;
        } else {
          // TODO: replace locally calculated discount with an actual discount from payment intent
          planPricing = showCountdown.value
            ? `<s>$${plan.displayTotalPrice}</s> $${discountPrice(
                plan.displayTotalPrice,
                20
              )}, billed ${plan.displayBillingCycle}`
            : `$${plan.displayTotalPrice}, billed ${plan.displayBillingCycle}`;
        }

        return {
          id: plan.product_id,
          title: plan.displayName,
          afterTitle:
            plan.max_members === 1
              ? "1 member"
              : plan.max_members === 2
              ? "2 members"
              : `up to ${plan.max_members} members`,
          text: planPricing,
          afterText:
            plan.discount &&
            (plan.product_id !== activePlan.value?.product_id ||
              !store.getters["settings/getSubscription"].owner)
              ? `Save ${plan.discount}%`
              : null,
          type: "plan",
          isActive: store.getters["settings/getSubscription"].owner
            ? plan.type === activePlan.value?.type &&
              plan.recurring_interval === activePlan.value?.recurring_interval
            : false,
          stripePlan: plan,
          paypalPlan,
          intents: ["subscribe", "cancel", "resubscribe"],
        };
      }),
    {
      id: "cancel",
      title: "Leave Plan",
      text: "Downgrade to limited plan",
      type: "cancel",
      intents: ["cancel"],
    },
    {
      id: "stay",
      title: "Stay on limited",
      text: "Read-only experience",
      type: "stay",
      intents: ["resubscribe"],
    },
  ]);

  const selectedPlan = computed(() => selectedPlanOption.value?.stripePlan);
  const selectedPaypalPlan = computed(
    () => selectedPlanOption.value?.paypalPlan
  );

  watch(
    () => allOptions.value,
    (newValue) => {
      if (newValue && !selectedPlanOption.value) {
        selectedPlanOption.value =
          newValue?.find(
            (option) =>
              option?.stripePlan?.recurring_interval ===
                toValue(selectedBillingCycle) &&
              option?.stripePlan?.type === defaultSelectedOption
          ) ?? null;
      }
    },
    { immediate: true }
  );

  const billingCycleOptions = computed(() =>
    allOptions.value.filter(
      (option) =>
        (option.stripePlan?.recurring_interval ===
          toValue(selectedBillingCycle) ||
          option.type !== "plan") &&
        option.intents.includes(toValue(intent))
    )
  );

  watch(
    () => selectedBillingCycle.value,
    (newBillingCycle) => {
      const newOption = allOptions.value.find(
        (option) =>
          option.stripePlan?.type ===
            selectedPlanOption.value?.stripePlan?.type &&
          option.stripePlan?.recurring_interval === newBillingCycle
      );

      if (newOption?.isActive) {
        selectedPlanOption.value = allOptions.value.find(
          (option) => option.stripePlan?.recurring_interval === newBillingCycle
        );

        return;
      }

      if (newOption) {
        selectedPlanOption.value = newOption;
      }
    }
  );

  return {
    selectedPlanOption,
    billingCycleOptions,
    selectedPlan,
    selectedPaypalPlan,
  };
};
