<script setup>
import { useRoute } from "@/hooks";
import { reactive, nextTick, computed, watch, onMounted } from "vue";
import store from "@/store";
import MainNav from "@/components/mainNav";
import {
  SHOWN_PLUGIN,
  HAS_COMPLETED_MOBILE_ONBOARDING,
  HAS_SEEN_PLANS_MODAL,
  HAS_SEEN_DD_POST_PAYMENT_ONBOARDING,
  HAS_ACTIVATED_WEB,
} from "@/scripts/userFlags";
import router from "@/routes/router";
import { constants } from "@/scripts";
import { getPosthog } from "@/scripts/posthog";
import { logout } from "@/scripts/actions/auth";
import RightPanel from "@/components/global/rightPanel";
import GetStarted from "@/components/modals/GetStarted";
import AppBanners from "@/components/global/AppBanners";
import CardsServices from "@/api/actions/cards-services";
import { dbMixin, getLatestTimestamp } from "@/mixins/db";
import { useIntervalFn, useWindowFocus } from "@vueuse/core";
import { Modal, VirtualCardCreation } from "@/components";
import { initiateEncryption } from "@/scripts/actions/encryption";
import NavigationTopBar from "@/components/Navigation/NavigationTopBar.vue";
import NavigationLeftPanel from "@/components/Navigation/NavigationLeftPanel.vue";
import MigrationModalFlow from "@/components/modals/migrationFlow/MigrationModalFlow.vue";
import InsuranceModal from "@/components/Navigation/NavigationTopBar/InsuranceModal.vue";
import DownloadMobileModal from "@/components/feature/onboarding/ModalApp";
import ESimAnnouncement from "@/components/modals/eSim/ESimAnnouncement";
import InboxCompose from "@/components/inbox/InboxCompose";
import ModalWelcome from "@/components/feature/onboarding/ModalWelcome.vue";
import UserService from "@/api/actions/user-service";
import { initShareBroadcast } from "@/scripts/messaging/shareBroadcast.js";
import DataDeleteService from "@/api/actions/data-delete-service";
import { IMPORT_STATUS_FINISHED } from "@/store/modules/accounts-importer/shared";
import InboxService from "@/api/actions/inbox-service";
import ChoosePlanModal from "@/components/feature/subscribe/ChoosePlanModal.vue";
import { usePlansModal } from "@/components/feature/subscribe/composables";

const route = useRoute();

const {
  pause: pauseUpdateIdentitiesPolling,
  resume: startUpdateIdentitiesPolling,
} = useIntervalFn(updateIdentities, 30 * 1000, {
  immediate: false,
});
const isTabFocused = useWindowFocus();

const state = reactive({
  exitDownload: false,
  bootReady: false,
  offset: "",
  mobileDevice: false,
  enablePay: route?.query?.enable_pay === "pay_2024",
});

const subscription = computed(() => {
  return store.getters["settings/getSubscription"];
});

const importStatus = computed(() => {
  return store.state.accountsImporter.importStatus;
});
const overflow = computed(() => {
  return store.state.ui.bodyOverflowHidden;
});

const rightPanelState = computed(() => {
  return store.getters["getRightPanelStatus"];
});

const hasPlugin = computed(() => {
  return store.getters["browser/pluginDetected"];
});

const showDownload = computed(() => {
  return !hasPlugin.value && !shownPlugin.value;
});

const authenticated = computed(() => {
  return store.getters.auth_token;
});

const user = computed(() => {
  return store.state.authentication?.user;
});

const cardsEnabled = computed(() => {
  return constants.getShouldShowCards(store.state.authentication?.user);
});

const shouldClipBody = computed(() => {
  return store.state.ui.open_dialog;
});

const shownPlugin = computed(() => {
  return store.getters.getFlag(SHOWN_PLUGIN);
});

const shouldShowEncryptionMigrationModal = computed(() => {
  const userIsV2User =
    store.state.authentication?.user?.encryption_status === 3;
  if (userIsV2User) {
    return false;
  } else if (store.state.authentication?.user?.flags?.migrate_enc_v2) {
    return true;
  }
  return false;
});

const userEncryptionVersion = computed(() => {
  return store.state.authentication?.user?.encryption_status;
});

const userCountry = computed(() => {
  return user?.value?.country_at_registration
    ? user?.value?.country_at_registration
    : user?.value?.current_country;
});

const isSupportedCardCountry = computed(() => {
  return constants.SUPPORTED_CARDS_COUNTRIES.includes(userCountry.value);
});

const canEnablePay = computed(() => {
  return (
    (store.getters["settings/isSubscribed"] ||
      store.getters["settings/isTrial"] ||
      store.getters["settings/isLegacy"]) &&
    isSupportedCardCountry.value
  );
});

const ddOnboardingEnabled = computed(() => {
  return store.getters["dataDelete/getDdOnboardingEnabled"];
});

const showNewOnboarding = computed(() => {
  return store.state.authentication?.user?.flags
    ?.show_dd_post_payment_onboarding;
});

const hasNotSeenNewOnboarding = computed(() => {
  // NOTE: need to make sure flag has been set before checking that its false
  return (
    store.state.flags.newOnboardingFlags !== null &&
    !store.getters.getNewOnboardingFlag(HAS_SEEN_DD_POST_PAYMENT_ONBOARDING)
  );
});

const forceShowDdOnboarding = computed(() => {
  return (
    ddOnboardingEnabled.value &&
    showNewOnboarding.value &&
    hasNotSeenNewOnboarding.value
  );
});

const unwatchForceOnboarding = watch(
  () => forceShowDdOnboarding.value,
  (value) => {
    if (value) {
      router.push({ name: "NewOnboardingWelcome" });
      nextTick(() => {
        unwatchForceOnboarding();
      });
    }
  },
  { immediate: true }
);

watch(
  () => subscription.value,
  (newVal, oldVal) => {
    if (!oldVal && newVal) {
      nextTick(() => showPayModal());
    }
  },
  { immediate: true, deep: true }
);

watch(
  () => rightPanelState.value,
  (value) => {
    if (value === true) {
      document.body.classList.add("active-right-panel");
    } else {
      document.body.classList.remove("active-right-panel");
    }
  },
  { deep: true }
);

watch(
  () => showDownload.value,
  (value) => {
    if (!value) {
      setTimeout(() => {
        state.exitDownload = true;
      }, 5000);
    }
  },
  { deep: true }
);

watch(
  () => authenticated.value,
  (isAuthenticated) => {
    if (!isAuthenticated) {
      if (window.$posthog) {
        window.$posthog?.reset();
      }
      logout();
    }
  },
  { deep: true }
);

watch(
  () => authenticated.value,
  (authToken) => {
    if (authToken) {
      initShareBroadcast();
    }
  },
  { immediate: true }
);

watch(
  () => user.value,
  (user) => {
    // If the user doesn't have a uuid, then we leave them anonymous,
    // otherwise we call identify so events are associated with the user.
    if (user.posthog_uuid) {
      getPosthog().then((posthog) => {
        posthog.identify(store?.state?.authentication?.user?.posthog_uuid);
      });
    }
  },
  { deep: true }
);

watch(
  () => shouldClipBody.value,
  (value) => {
    if (value) {
      state.offset = window.scrollY.toString();
    } else {
      const reset_top = parseInt(state.offset, 10);
      state.offset = 0;
      window.scrollTo(0, reset_top);
      setTimeout(() => {
        window.scrollTo(0, reset_top);
      }, 3);
    }
  },
  { deep: true }
);

watch(
  () => overflow.value,
  (value) => {
    if (value === true) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  },
  { deep: true }
);

watch(
  () => importStatus.value,
  (newValue) => {
    if (newValue === IMPORT_STATUS_FINISHED) {
      updateIdentities();
    }
  },
  { deep: true }
);

watch(
  isTabFocused,
  (isFocused) => {
    if (isFocused) {
      startUpdateIdentitiesPolling();
      updateIdentities();
    } else {
      pauseUpdateIdentitiesPolling();
    }
  },
  { deep: true, immediate: true }
);

function showPayModal() {
  if (state.enablePay) {
    const newQuery = { ...route.query };
    delete newQuery.enable_pay;
    router.replace({
      query: newQuery,
    });
    if (canEnablePay.value) {
      CardsServices.enableSettings({
        enable_pay: "pay_2024",
      }).then(() => {
        if (store.getters["settings/isTrial"]) {
          store.dispatch("openModal", {
            header: "Congratulations you're an early user for Cloaked Pay!",
            subheader:
              "To enable Cloaked Pay, please upgrade with this promo code <b>FAM2024</b> for 80% off the monthly subscription.",
            button: {
              text: "Upgrade now",
              onClick: () =>
                store.dispatch("subscription/openSubscriptionModal", {
                  promoCode: "FAM2024",
                  callback: () => router.push({ name: "Wallet" }),
                }),
            },
          });
        } else if (
          store.getters["settings/isSubscribed"] ||
          store.getters["settings/isLegacy"]
        ) {
          store.dispatch("openModal", {
            header: "Congrats, you got early access to Cloaked Pay",
            button: {
              text: "To Wallet page",
              onClick: () => router.push({ name: "Wallet" }),
            },
          });
        }
      });
    }
  }
}

function prefetchInbox() {
  const allInboxParams = {
    contact_status: "approved",
    muted: false,
    page_size: 20,
    page: 1,
  };
  InboxService.getInbox(allInboxParams);
  const emailsInboxParams = {
    contact_status: "approved",
    muted: false,
    activity_type: "email",
    page_size: 20,
    page: 1,
  };
  InboxService.getInbox(emailsInboxParams);
  const textsInboxParams = {
    contact_status: "approved",
    muted: false,
    activity_type: "message",
    page_size: 20,
    page: 1,
  };
  InboxService.getInbox(textsInboxParams);
  const callsInboxParams = {
    contact_status: "approved",
    muted: false,
    activity_type: "call",
    page_size: 20,
    page: 1,
  };
  InboxService.getInbox(callsInboxParams);
  const requestsInboxParams = {
    contact_status: "unknown",
    muted: false,
    page_size: 20,
    page: 1,
  };
  InboxService.getInbox(requestsInboxParams);
}

async function checkBrowserSupport() {
  // Get the user-agent string
  let userAgentString = navigator.userAgent;

  // Detect Chrome
  let chromeAgent = userAgentString.indexOf("Chrome") > -1;
  let iosDevice = userAgentString.match(/(iPhone|iPod|iPad)/);
  let androidDevice = userAgentString.match(/Android/);

  // NOTE: leaving these commented out so we can easily add them back in if needed
  // Detect Internet Explorer
  // let IExplorerAgent =
  //   userAgentString.indexOf("MSIE") > -1 ||
  //   userAgentString.indexOf("rv:") > -1;

  // Detect Firefox
  // let firefoxAgent = userAgentString.indexOf("Firefox") > -1;

  // Detect Safari
  // let safariAgent = userAgentString.indexOf("Safari") > -1;

  state.mobileDevice = userAgentString.match(
    /(iPhone|iPod|iPad|Android|BlackBerry|IEMobile)/
  );
  if (chromeAgent) {
    store.dispatch("browser/saveBrowserSupported", true);
  } else if (
    iosDevice &&
    state.mobileDevice &&
    route.path !== "/settings/subscription"
  ) {
    window.location.replace("https://apps.apple.com/app/cloaked/id1600487147");
  } else if (
    androidDevice &&
    state.mobileDevice &&
    route.path !== "/settings/subscription"
  ) {
    window.location.replace(
      "https://play.google.com/store/apps/details?id=app.android.cloaked"
    );
  } else {
    store.dispatch("browser/saveBrowserSupported", false);
  }
}

function listenForCloaks() {
  startUpdateIdentitiesPolling();
  window.addEventListener("cloak:identities", updateIdentities);
}
async function updateIdentities() {
  let url = "/api/v1/search/identity/";
  const timestamp = await getLatestTimestamp();
  // NOTE: if no timestamp, then db is empty - fetch all cloaks
  if (timestamp) {
    url += `?updated_at__gt=${timestamp}`;
  }
  dbMixin.methods.frontLoadCloaks(false, url);
}

const payEnabled = computed(() => {
  return user?.value?.cloaked_card_enabled;
});

const kycValidated = computed(() => {
  return store.state.authentication?.user?.cloaked_card_kyc_configured;
});

onMounted(async () => {
  await initiateEncryption();
  await checkBrowserSupport();
  state.bootReady = true;
  listenForCloaks();
  prefetchInbox();
  DataDeleteService.getEnrollmentData();
  UserService.setProductUseFlag({ name: HAS_ACTIVATED_WEB, value: true });

  if (payEnabled.value && kycValidated.value) {
    CardsServices.getFundingSources();
    CardsServices.getCardSettings();
    CardsServices.getCardList();
  }
});

function setVisibleBanner(visible) {
  store.dispatch("setVisibleBanner", visible);
}

const isWelcomeModalOpen = computed({
  get() {
    if (!store.getters["settings/isSubscribed"]) {
      return store.getters["welcomeUi/isWelcomeModalOpen"];
    }
    return false;
  },
  set(value) {
    if (!value && isWelcomeModalOpen.value) {
      UserService.setFlag({
        name: HAS_SEEN_PLANS_MODAL,
        value: true,
      });
    }

    store.commit("welcomeUi/SET_IS_WELCOME_MODAL_OPEN", value);
  },
});

let unwatch;
unwatch = watch(
  () => store.getters["welcomeUi/hasWelcomeModal"] && !state.enablePay,
  (newValue) => {
    if (newValue) {
      isWelcomeModalOpen.value = newValue;
      if (typeof unwatch === "function") {
        setTimeout(() => {
          unwatch();
        }, 2000);
      }
    }
  },
  { immediate: true }
);

const { isPlansModalOpen, plansModalIntent } = usePlansModal();

function openSubscribe() {
  setTimeout(() => {
    store.dispatch("subscription/openSubscriptionModal");
  }, 150);
}
</script>

<template>
  <div class="app-wrapper" v-if="state.bootReady">
    <AppBanners @visible="setVisibleBanner" ref="banner" />
    <MigrationModalFlow
      v-if="shouldShowEncryptionMigrationModal"
      :userEncryptionVersion="userEncryptionVersion"
    />

    <RightPanel />
    <VirtualCardCreation v-if="cardsEnabled" />
    <NavigationLeftPanel />
    <GetStarted />
    <NavigationTopBar />
    <Modal />
    <ModalWelcome
      :value="isWelcomeModalOpen"
      @input="isWelcomeModalOpen = $event"
      :type="'default'"
      :expires="store.getters['welcomeUi/welcomeDiscountExpires']"
      @subscribe="openSubscribe"
    />
    <InsuranceModal />
    <DownloadMobileModal />
    <ESimAnnouncement />
    <InboxCompose />
    <ChoosePlanModal
      :value="isPlansModalOpen"
      @input="isPlansModalOpen = $event"
      :intent="plansModalIntent"
    />

    <div :class="['content-container', { 'hide-overflow': shouldClipBody }]">
      <MainNav :mobileDevice="state.mobileDevice" />
    </div>
  </div>
</template>

<style lang="scss">
[data-lastpass-icon-root] {
  display: none !important;
}
#user-app {
  height: 100vh;
  .app-div {
    height: 100%;
    .app-wrapper {
      height: 100%;
      .content-container {
        height: 100%;
        > div {
          height: 100%;
        }
      }
    }
  }
}
.highlight-text {
  font-weight: 600;
  color: $color-info;
}
</style>
