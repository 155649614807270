<template>
  <div class="subscribe-section-title">
    <slot />
  </div>
</template>

<style lang="scss">
.subscribe-section-title {
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.1px;
}
</style>
