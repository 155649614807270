import { ref, watch, watchEffect } from "vue";
import { SubscriptionService } from "@/api";
import { TIME_LIMITED_DISCOUNT_PROMO_CODE } from "@/composables/useTimeLimitedDiscount";

export const usePromoCode = (plan, isPromoCodeInputVisible) => {
  const isValidatingPromoCode = ref(false);
  const promoCodeInput = ref("");
  const promoCodeError = ref(null);
  const promoCodeOffer = ref(null);

  const validatePromoCode = () => {
    isValidatingPromoCode.value = true;

    if (
      isPromoCodeInputVisible.value &&
      promoCodeInput.value === TIME_LIMITED_DISCOUNT_PROMO_CODE
    ) {
      promoCodeOffer.value = null;
      promoCodeError.value = "Code is invalid or no longer active.";
      isValidatingPromoCode.value = false;
      return;
    }

    SubscriptionService.checkPromoCode(
      plan.value.product_id,
      promoCodeInput.value
    )
      .then(({ terms }) => {
        promoCodeOffer.value = { code: promoCodeInput.value, terms };
        promoCodeError.value = null;
      })
      .catch(() => {
        promoCodeOffer.value = null;
        promoCodeError.value = "Code is invalid or no longer active.";
      })
      .finally(() => {
        isValidatingPromoCode.value = false;
      });
  };

  watch(
    () => promoCodeInput.value,
    (newValue) => {
      if (!newValue) {
        promoCodeOffer.value = null;
        promoCodeError.value = null;
      }
    }
  );

  watchEffect(() => {
    if (plan.value) {
      promoCodeInput.value = "";
    }
  });

  return {
    isValidatingPromoCode,
    promoCodeInput,
    promoCodeOffer,
    promoCodeError,
    validatePromoCode,
  };
};
