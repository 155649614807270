<script setup>
import UiHeader from "@/components/feature/onboarding-new/UiHeader.vue";
import UiButton from "@/components/feature/onboarding-new/UiButton.vue";
import UiButtonRow from "@/components/feature/onboarding-new/UiButtonRow.vue";
import UiPageWrapper from "@/components/feature/onboarding-new/UiPageWrapper.vue";
import InlineSvg from "@/components/InlineSvg";
import { PH_SCREEN_EVENT_ESIM_ONBOARDING_PAYMENT_SUCCESS_SCREEN } from "@/scripts/posthogEvents";

const emit = defineEmits(["next"]);
const props = defineProps({
  isPaid: {
    type: Boolean,
    default: false,
  },
});
</script>
<template>
  <UiPageWrapper
    showLogo
    logo="cloaked-esim-logo"
    :screenEvent="PH_SCREEN_EVENT_ESIM_ONBOARDING_PAYMENT_SUCCESS_SCREEN"
    :esimStep="3"
  >
    <UiHeader>
      <h1 v-if="props.isPaid">Your device is ready for Cloaked eSIM!</h1>
      <h1 v-else>Purchase successful!</h1>
      <h5>
        Congratulations! Now, let's get your eSIM installed on your device.
      </h5>
    </UiHeader>
    <InlineSvg name="esim-greencheck" />
    <UiButtonRow>
      <UiButton gradient @click="emit('next')">Install eSim</UiButton>
    </UiButtonRow>
  </UiPageWrapper>
</template>
<style lang="scss" scoped></style>
