<script setup>
import UiHeader from "@/components/feature/onboarding-new/UiHeader.vue";
import UiButton from "@/components/feature/onboarding-new/UiButton.vue";
import UiButtonRow from "@/components/feature/onboarding-new/UiButtonRow.vue";
import UiPageWrapper from "@/components/feature/onboarding-new/UiPageWrapper.vue";
import ESimService from "@/api/actions/esim-service.js";
import AtomInputInternalLabels from "@/components/Atoms/AtomInputInternalLabels.vue";
import { PH_SCREEN_EVENT_ESIM_ONBOARDING_COVERAGE_SCREEN } from "@/scripts/posthogEvents";
import { useToast } from "@/hooks";
import { reactive } from "vue";

const toast = useToast();
const state = reactive({
  loading: false,
  zipCode: "",
  zipError: false,
});

const emit = defineEmits(["next", "updateZipCode"]);

const checkCoverage = async () => {
  state.loading = true;
  let coverage = null;
  if (state.zipCode?.length !== 5) {
    state.zipError = true;
  } else {
    state.zipError = false;
    coverage = await ESimService.checkCoverage({ zipCode: state.zipCode });
  }
  if (coverage) {
    emit("updateZipCode", state.zipCode);
    emit("next");
  } else if (coverage == false) {
    toast.error("Unfortunately there is no coverage in this area");
  }
  state.loading = false;
};
</script>
<template>
  <UiPageWrapper
    showLogo
    logo="cloaked-esim-logo"
    :screenEvent="PH_SCREEN_EVENT_ESIM_ONBOARDING_COVERAGE_SCREEN"
    :esimStep="1"
  >
    <UiHeader leftAlign maxWidth="450px">
      <h1>Check for cell coverage</h1>
      <h5>
        In order to add a Cloaked eSIM, we first need to check to ensure you're
        located in a covered area within the United States.
      </h5>
      <AtomInputInternalLabels
        autofocus
        :isLoading="state.loading"
        :value="state.zipCode"
        label="Zip Code"
        type="text"
        placeholder="Enter Zip Code"
        :disabled="state.loading"
        :pattern="/^[0-9]*$/"
        :maxlength="5"
        :error="state.zipError"
        errorMessage="Please enter a valid zip code"
        @input="(event) => (state.zipCode = event.target.value)"
        @save="checkCoverage"
        @keydown.enter="checkCoverage"
      />
      <h6>This information is never stored or shared.</h6>
    </UiHeader>

    <UiButtonRow>
      <UiButton
        gradient
        imgName="arrow-long-right"
        class="device-check-button"
        @click="checkCoverage"
        :loading="state.loading"
        :disabled="state.loading || !state.zipCode"
        >{{ state.loading ? "Checking Coverage" : "Continue" }}</UiButton
      >
    </UiButtonRow>
  </UiPageWrapper>
</template>
<style lang="scss" scoped></style>
