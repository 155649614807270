<script setup>
import { useAttrs } from "vue";
import DataDeletePagePlanFamily from "@/components/feature/data-delete/DataDeletePagePlanFamily.vue";

const attrs = useAttrs();
</script>

<template>
  <DataDeletePagePlanFamily v-bind="$attrs">
    <template #title v-if="$route.name === 'SubcriptionUpFront'">
      Choose a plan and get started today
    </template>
  </DataDeletePagePlanFamily>
</template>

<style lang="scss">
@import "@/components/feature/data-delete/atoms/data-delete-sticky";

.data-delete-plan {
  max-width: 800px;
  margin: 0 auto;

  @media all and (min-width: $screen-xl) {
    margin-top: clamp(40px, 5vh, 150px);
  }

  .subscribe-payment__subscribe {
    @extend .data-delete-sticky;
  }
}
</style>
