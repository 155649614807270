<script setup>
import { onMounted, computed, watch, nextTick, ref } from "vue";
import DataDeleteService from "@/api/actions/data-delete-service.js";
import InlineSvg from "@/components/InlineSvg.vue";
import DataRemovalGraphSummarySection from "@/routes/DataDeletion/components/DataRemovalGraphSummarySection.vue";
import DataRemovalRequestsSection from "@/routes/DataDeletion/components/DataRemovalRequestsSection.vue";
import DataRemovalSkeletonLoader from "@/routes/DataDeletion/components/DataRemovalSkeletonLoader.vue";
import store from "@/store";
import DataRemovalFaqSection from "@/routes/DataDeletion/components/DataRemovalFaqSection.vue";
import moment from "moment";
import router from "@/routes/router";

import {
  DATA_REMOVAL_STATUS_DISPLAY,
  DATA_REMOVAL_STATUS,
} from "@/scripts/constants";

import { UiMenu, UiMenuButton } from "@/components";

onMounted(async () => {
  DataDeleteService.getRemovalLog().catch(() => {});
  DataDeleteService.getEnrollmentData().catch(() => {});
  DataDeleteService.getGraphData()
    .then((response) => {
      rawGraphData.value = response?.data;
      if (!response?.data?.graph_data || !response?.data?.graph_data?.length) {
        hideGraph.value = true;
      }
    })
    .catch(() => {
      // only hide graph if no data is saved AND no data was returned
      // this way if second api call failed but first one succeeded, user can still see graph data
      if (rawGraphData.value === null) {
        hideGraph.value = true;
      }
    });
});

const selectedScan = ref(null);
const selectedStatus = ref(null);
const loadingScanData = ref(false);
const fetchingInitialData = ref(true);

const hideGraph = ref(false);

const rawGraphData = ref(store.getters["dataDelete/getGraphData"] || {});

const selectedStatusDisplay = computed(() => {
  if (selectedStatus.value) {
    return DATA_REMOVAL_STATUS_DISPLAY[selectedStatus.value];
  }
  return "All brokers";
});

const brokerList = computed(() => {
  if (selectedScan.value?.id === latestScan.value?.id) {
    return store.getters["dataDelete/removalLogData"]?.brokerList ?? [];
  }
  return store.getters["dataDelete/removalLogDataPastScan"]?.brokerList ?? [];
});

const removalLogFetched = computed(() => {
  return store.getters["dataDelete/removalLogData"]?.removalLogFetched;
});

const enrollmentDataFetched = computed(() => {
  return store.getters["dataDelete/enrollmentData"]?.enrollmentDataFetched;
});

const actionRequiredFamilies = computed(() => {
  if (selectedScan.value?.id !== latestScan.value?.id) {
    return []; // if previous scan, action items will show as in progress
  }
  if (
    selectedStatus.value &&
    selectedStatus.value !== DATA_REMOVAL_STATUS.ACTION_REQUIRED
  ) {
    return [];
  }

  return (
    store.getters["dataDelete/actionRequiredFamilies"]?.sort(
      (a, b) => a.name - b.name
    ) ?? []
  );
});

const actionRequiredItems = computed(() => {
  return brokerList.value.filter(
    (broker) => broker.state === "action_required"
  );
});

const filteredBrokers = computed(() => {
  let filteredList = [...brokerList.value];
  if (selectedStatus.value) {
    filteredList = brokerList.value.filter(
      (broker) => broker.state === selectedStatus.value
    );
  }
  const groupedBrokers = Object.groupBy(filteredList, (broker) => broker.state);
  const fullList = [];

  if (groupedBrokers[DATA_REMOVAL_STATUS.REMOVED]) {
    fullList.push(
      ...groupedBrokers[DATA_REMOVAL_STATUS.REMOVED].sort(
        (a, b) => a.broker_name - b.broker_name
      )
    );
  }
  if (groupedBrokers[DATA_REMOVAL_STATUS.REMOVAL_IN_PROGRESS]) {
    fullList.push(
      ...groupedBrokers[DATA_REMOVAL_STATUS.REMOVAL_IN_PROGRESS].sort(
        (a, b) => a.broker_name - b.broker_name
      )
    );
  }
  if (groupedBrokers[DATA_REMOVAL_STATUS.CONTINUED]) {
    fullList.push(
      ...groupedBrokers[DATA_REMOVAL_STATUS.CONTINUED].sort(
        (a, b) => a.broker_name - b.broker_name
      )
    );
  }
  if (groupedBrokers[DATA_REMOVAL_STATUS.SCANNING]) {
    fullList.push(
      ...groupedBrokers[DATA_REMOVAL_STATUS.SCANNING].sort(
        (a, b) => a.broker_name - b.broker_name
      )
    );
  }
  if (groupedBrokers[DATA_REMOVAL_STATUS.NO_RECORDS_FOUND]) {
    fullList.push(
      ...groupedBrokers[DATA_REMOVAL_STATUS.NO_RECORDS_FOUND].sort(
        (a, b) => a.broker_name - b.broker_name
      )
    );
  }

  return fullList;
});

const pastScans = computed(() => {
  return store.getters["dataDelete/enrollmentData"]?.pastScans ?? [];
});
const latestScan = computed(() => {
  return store.getters["dataDelete/enrollmentData"]?.latestScan;
});

function fetchActionsRequired() {
  DataDeleteService.getActionItems().catch(() => {});
}

function navToDDSettings() {
  router.push({ name: "settings.dataRemoval" });
}

function resetFilters() {
  selectedStatus.value = null;
  selectedScan.value = latestScan.value;
}

function formatScanDate(date) {
  return moment(date).format("MMMM D, YYYY");
}

const unwatchActionsRequired = watch(
  () => removalLogFetched?.value && actionRequiredItems?.value?.length,
  (needToFetchFamilies) => {
    if (
      needToFetchFamilies &&
      actionRequiredItems?.value?.length !==
        actionRequiredFamilies?.value?.length
    ) {
      fetchActionsRequired();
      nextTick(() => unwatchActionsRequired());
    }
  },
  { immediate: true }
);

const unwatchLatestScan = watch(
  () => latestScan.value,
  (newValue, oldValue) => {
    if (newValue && !oldValue) {
      selectedScan.value = latestScan.value;
      nextTick(() => unwatchLatestScan());
    }
  },
  { immediate: true, deep: true }
);

watch(
  () => selectedScan.value?.id,
  (newScanId, oldScanId) => {
    if (newScanId && newScanId !== oldScanId) {
      loadingScanData.value = true;
      DataDeleteService.getPastScan(newScanId)
        .then(() => {
          loadingScanData.value = false;
        })
        .catch(() => {
          loadingScanData.value = false;
        });
    }
  },
  { deep: true, immediate: true }
);

const unwatchInitialDataLoaded = watch(
  () =>
    !!removalLogFetched?.value &&
    (!!actionRequiredItems?.value?.length
      ? !!actionRequiredFamilies?.value?.length
      : true),
  (newVal) => {
    if (newVal) {
      fetchingInitialData.value = false;
      nextTick(() => unwatchInitialDataLoaded());
    }
  },
  { immediate: true, deep: true }
);
</script>

<template>
  <div class="page-wrapper">
    <div class="section-wrapper border">
      <h1 class="title">
        <span> Removal Results </span>
        <span class="subtitle" @click="navToDDSettings"
          ><InlineSvg name="cog" class="icon" /> Settings</span
        >
      </h1>

      <DataRemovalSkeletonLoader v-if="!rawGraphData && !hideGraph" />
      <DataRemovalGraphSummarySection
        v-else-if="!hideGraph"
        :rawGraphData="rawGraphData"
        :enrollmentDataFetched="enrollmentDataFetched"
      />

      <h1 class="title large-margin">
        <div>Brokers</div>
        <div class="submenu">
          <div class="underline" @click="resetFilters">Reset Filters</div>
          <UiMenu
            width="182px"
            placement="bottom-end"
            :offsetAway="8"
            class="dropdown-menu"
          >
            <div class="menu-selector">
              <span>{{
                selectedScan?.date
                  ? formatScanDate(selectedScan?.date)
                  : "Current scan"
              }}</span>
              <InlineSvg name="chevron-down" />
            </div>
            <template #content>
              <UiMenuButton
                title="Current scan"
                @click="selectedScan = latestScan"
              >
                <template #icon>
                  <InlineSvg
                    name="check"
                    v-if="selectedScan?.id === latestScan?.id"
                  />
                </template>
              </UiMenuButton>
              <UiMenuButton
                v-for="scan in pastScans"
                :key="`${scan.id}-${scan.date}`"
                :title="formatScanDate(scan.date)"
                @click="selectedScan = scan"
              >
                <template #icon>
                  <InlineSvg
                    name="check"
                    v-if="selectedScan?.id === scan?.id"
                  />
                </template>
              </UiMenuButton>
            </template>
          </UiMenu>

          <UiMenu
            width="200px"
            placement="bottom-end"
            :offsetAway="8"
            class="dropdown-menu"
          >
            <div class="menu-selector">
              <span>{{ selectedStatusDisplay }}</span>
              <InlineSvg name="chevron-down" />
            </div>
            <template #content>
              <UiMenuButton title="All brokers" @click="selectedStatus = null">
                <template #icon>
                  <InlineSvg name="check" v-if="!selectedStatus" />
                </template>
              </UiMenuButton>
              <UiMenuButton
                v-for="removalStatus in Object.keys(
                  DATA_REMOVAL_STATUS_DISPLAY
                )"
                :key="`${removalStatus}-key`"
                :title="DATA_REMOVAL_STATUS_DISPLAY[removalStatus]"
                @click="selectedStatus = removalStatus"
              >
                <template #icon>
                  <InlineSvg
                    name="check"
                    v-if="selectedStatus === removalStatus"
                  />
                </template>
              </UiMenuButton>
            </template>
          </UiMenu>
        </div>
      </h1>
      <DataRemovalSkeletonLoader v-if="fetchingInitialData" />
      <DataRemovalRequestsSection
        v-else-if="filteredBrokers.length || actionRequiredFamilies.length"
        :requests="filteredBrokers"
        :actionRequiredFamilies="actionRequiredFamilies"
        :loading="loadingScanData"
      />

      <div v-else class="empty-list">
        There are no brokers with status of "{{
          selectedStatusDisplay.toLowerCase()
        }}" for this period.
      </div>
    </div>

    <div class="section-wrapper">
      <h1 class="title">FAQ</h1>
      <DataRemovalFaqSection />
    </div>
  </div>
</template>

<style scoped lang="scss">
.page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  padding: 40px 80px;
  @media (max-width: 1024px) {
    padding: 40px 40px;
  }

  .section-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;

    &.border {
      padding: 24px;
      border-radius: 12px;
      border: 1px solid $color-primary-10;
    }
  }
}
.title {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.5px;
  color: $color-primary-100;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 8px;
  @media (max-width: 610px) {
    flex-direction: column;
    align-items: flex-start;
  }

  &.large-margin {
    margin-top: 38px;
  }

  .icon {
    svg {
      height: 15px;
      width: auto;
    }
  }
  .submenu {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    @media (max-width: 410px) {
      flex-direction: column;
      align-items: flex-start;
      .dropdown-menu {
        width: 100%;
      }
    }
    .dropdown-menu {
      cursor: pointer;
    }
    .menu-selector {
      border-radius: 4px;
      border: 1px solid $color-primary-50;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      padding: 4px 16px;
      transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
      &:hover {
        transform: scale(1.02);
        transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
      }
    }
  }
}

.subtitle {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: $color-primary-100;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
  svg {
    width: 17px;
    height: auto;
  }
  &:hover {
    cursor: pointer;
    transform: scale(1.03);
    transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
  }
}

.box-wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  color: $color-primary-100;
  margin-top: 16px;
  width: 100%;

  .box-title {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.2px;
    color: $color-primary-100;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    justify-content: flex-start;
  }
  h2 {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    justify-content: flex-start;
  }
  h3 {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: $color-primary-70;
  }
  .progress-boxes {
    gap: 12px;
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .data-removal-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 78px;
      width: 100%;
      border: 1px solid $color-primary-10;
      border-radius: 12px;
      .data-removal-value {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.5px;
      }
      .data-removal-label {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
}

.wrapper-boxes {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.error {
  color: $color-alert;
}
.text-link {
  text-decoration-line: underline;
  cursor: pointer;
}

.broker-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 11px;
  .broker-name {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: $color-primary-100;
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
  }

  .status-text {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 125px;
    text-align: right;
  }
  .action-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 13px;
    svg {
      height: 15px;
      width: auto;
    }
  }
}

.scanning {
  color: $color-primary-50;
}
.in-progress {
  color: $color-in-progress;
}
.no-records {
  color: $color-lt-green;
}
.color-success {
  color: $color-success;
}

.color-blue {
  color: $color-brand-3-100;
}

.load-more-button {
  margin-top: 19px;
}

.pulsing-svg {
  animation: pulse-svg 2s infinite ease-in-out;
}

@keyframes pulse-svg {
  0% {
    transform: scale(1.3);
    opacity: 0.9;
  }
  50% {
    transform: scale(1.5);
    opacity: 1;
  }
  100% {
    transform: scale(1.3);
    opacity: 0.9;
  }
}
.help-circle {
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
  filter: brightness(95%);
  cursor: pointer;
  &:hover {
    box-shadow: 0px 5px 5px 0px rgba($color-primary-100-light, 0.1);
    @at-root .theme-dark & {
      box-shadow: 0px 5px 5px 0px rgba($color-primary-100-dark, 0.1);
    }
    filter: brightness(100%);
    transform: scale(1.1) translate3d(0, 0, 0);
    transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
  }
}

.top-border {
  border-top: 1px solid $color-primary-10;
  margin-top: 24px;
}

.underline {
  text-decoration: underline;
}

.empty-list {
  padding: 80px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.1px;
  color: $color-primary-100;
}
</style>
