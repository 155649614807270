<script setup>
import { onMounted, reactive, computed, watch } from "vue";
import moment from "moment";
import _ from "lodash";

import TrashIcon from "@/assets/icons/delete-trash.svg";
import Checkmark from "@/assets/icons/check.svg";
import TrashService from "@/api/actions/trash-service.js";
import UiTooltip from "@/components/ui/ui-tooltip";
import { useToast } from "@/hooks";
import { tools } from "@/scripts";

import {
  ChevronLeft,
  ChevronRight,
  SelectAll,
  DeleteTrashIcon,
  RestoreNew,
  Restore,
  CopyIcon,
  Eye,
  EyeSlash,
} from "@/assets/icons";
import store from "@/store";
import { humanize } from "@/scripts/timestamp_format";

const toast = useToast();
const props = defineProps({
  types: Object,
  type: String,
});

const emits = defineEmits(["setType"]);

const data = reactive({
  trash: [],
  selected: [],
});
const ui = reactive({
  page: 1,
  perPage: 10,
  total: 0,
  type: props.type,
});

watch(
  () => ui.type,
  (type) => {
    emits("setType", type);
  },
  { deep: true }
);

const pages = computed(() => Math.ceil(ui.total / ui.perPage));

const currentSummary = computed(() => {
  let current = (ui.page - 1) * ui.perPage + 1;
  const min = Math.min(ui.total, current + ui.perPage);
  if (current > min) {
    current = min;
  }
  return `${current} - ${min} of ${ui.total}`;
});

const navPage = (direction) => {
  let next = ui.page + direction;
  if (next < 1) {
    next = 1;
  }
  if (next + 1 > pages.value) {
    next = pages.value;
  }
  ui.page = next;
  TrashService.getTrash(ui.page).then((res) => {
    data.trash = res.data.results;
    ui.total = res.data.count;
  });
};
onMounted(() => {
  refresh();
});

function refresh() {
  TrashService.getTrash().then((res) => {
    data.trash = res.data.results;
    data.selected = [];
    ui.total = res.data.count;
  });
}
function toggleSelect(item) {
  if (isSelected(item)) {
    data.selected = data.selected.filter((i) => i.id !== item.id);
  } else {
    const id = _.uniq(data.selected.map((i) => i.identity_id));
    if (id.length === 1 && id[0] === item.identity_id) {
      data.selected.push(item);
    } else {
      data.selected = [item];
    }
  }
}

function isSelected(item) {
  return data.selected.map((i) => i.id).includes(item.id);
}
function canSelect(item) {
  const id = _.uniq(data.selected.map((i) => i.identity_id));
  return id.length === 1 && id[0] === item.identity_id;
}

function dateFormat(date_time) {
  return moment(date_time).format("MM/DD/YYYY");
}

function domainOnly(url) {
  try {
    const parse = new URL(url);
    return parse.hostname;
  } catch (e) {
    return "";
  }
}

const canDelete = computed(() => {
  return data.selected.length > 0;
});
const canRestore = computed(() => {
  let allowRestore = true;
  data.selected.map((i) => {
    if (i.restore_to_previous !== true) {
      allowRestore = false;
    }
  });
  return allowRestore;
});
const canRestoreNew = computed(() => {
  let allowRestore = true;
  data.selected.map((i) => {
    if (i.restore_to_previous === null) {
      allowRestore = false;
    }
  });
  return allowRestore;
});

function deleteSelected() {
  store.dispatch("openModal", {
    header: `Delete selected items?`,
    subheader: "This action cannot be reversed. ",
    button: {
      text: "Yes, delete",
      onClick: () => {
        TrashService.deletePermanently(data.selected.map((i) => i.id))
          .then(() => {
            refresh();
            toast.success("Items deleted");
          })
          .catch(() => {
            toast.error("Could not delete, please try again");
          });
      },
      danger: true,
    },
  });
}

function restoreToIdentity() {
  TrashService.restoreToIdentity(data.selected.map((i) => i.id))
    .then(() => {
      refresh();
      refreshIdentities();
      toast.success("Items restored");
    })
    .catch(() => {
      toast.error("Failed to restore, please try again");
    });
}

function restoreToNewIdentity() {
  TrashService.restoreToNewIdentity(data.selected.map((i) => i.id))
    .then(() => {
      refresh();
      refreshIdentities();
      toast.success("Items restored");
    })
    .catch(() => {
      toast.error("Failed to restore, please try again");
    });
}

function deletesIn(timestamp) {
  const time = moment(timestamp).add("30", "days");
  return humanize(time.format("YYYY-MM-DD HH:mm:ss"));
}

function emptyTrashConfirm() {
  TrashService.emptyTrash()
    .then(() => {
      refresh();
      store.dispatch("closeModal");
      toast.success("Trash emptied");
    })
    .catch(() => {
      toast.error("Failed to delete trash, please try again");
    });
}
function emptyTrash() {
  store.dispatch("openModal", {
    header: `Empty Trash?`,
    subheader:
      "All identities and their associated activities and information will be permanently deleted. This action cannot be reversed. ",
    button: {
      text: "Yes, empty trash",
      onClick: () => emptyTrashConfirm(),
      danger: true,
    },
  });
}

function unselectAll() {
  data.selected = [];
}

function refreshIdentities() {
  setTimeout(() => {
    window.dispatchEvent(new Event("cloak:identities"));
  }, 3000);
}

function toggleShowItem(item) {
  modifyAttribute(item, "show", !item.show);
}

function modifyAttribute(item, attribute, value) {
  const copyItems = [...data.trash];
  const index = copyItems.findIndex((i) => i.id === item.id);
  copyItems[index][attribute] = value;
  data.trash = copyItems;
}

function shouldCopy(item) {
  return ["stored_autofill", "stored_password", "autofill_password"].includes(
    item.object_type
  );
}

function shouldShowItem(item) {
  return !isPasswordObject(item) || item.show;
}
function isPasswordObject(item) {
  return ["stored_password", "autofill_password"].includes(item.object_type);
}
function copyItem(item) {
  tools.copyToClipboard(item.item);
  modifyAttribute(item, "title", "copied!");
  setTimeout(() => {
    modifyAttribute(item, "title", null);
  }, 1500);
}
</script>
<template>
  <div class="trash-identity-container">
    <div v-if="ui.total > 0">
      <div class="trash-info">
        <div class="trash-info-header">
          Items in the Trash will be automatically deleted after 30 days.
        </div>
        <div class="empty-trash-icon">
          <button @click="emptyTrash">
            <span>Empty trash now</span>
          </button>
        </div>
      </div>
      <div class="trash-data-header">
        <div class="actions">
          <div v-if="data.selected.length > 0">
            <button @click="unselectAll" class="select-all-button">
              <SelectAll />
            </button>
            <UiTooltip
              title="Remove the currently selected items"
              position="top"
              align-x="center"
            >
              <button
                class="delete-button"
                :disabled="!canDelete"
                @click="deleteSelected"
              >
                <DeleteTrashIcon />
                Delete permanently
              </button>
            </UiTooltip>
            <UiTooltip
              title="Restore the selected items to their original position"
              position="top"
              align-x="center"
              v-if="canRestore"
            >
              <button @click="restoreToIdentity">
                <Restore />
                Restore to original identity
              </button>
            </UiTooltip>
            <UiTooltip
              title="Cannot restore the selected item to their original identity"
              position="top"
              align-x="center"
              v-else
            >
              <button class="disabled">
                <Restore />
                Restore to original identity
              </button>
            </UiTooltip>
            <UiTooltip
              title="Restores the selected items to a new identity"
              position="top"
              align-x="center"
              v-if="canRestoreNew"
            >
              <button @click="restoreToNewIdentity">
                <RestoreNew />
                Restore to new identity
              </button>
            </UiTooltip>
            <UiTooltip
              title="Selected item cannot be restored to a new identity, you can copy its value instead"
              position="top"
              align-x="center"
              v-else
            >
              <button class="disabled">
                <RestoreNew />
                Restore to new identity
              </button>
            </UiTooltip>
          </div>
        </div>
        <div class="right">
          <div class="trash-type-select" v-if="false">
            <span>View:</span>
            <select :value="ui.type" @input="(event) => (ui.type = event)">
              <option :value="props.types.IDENTITY">Identity</option>
              <option :value="props.types.ACTIVITY">Activity</option>
            </select>
          </div>
          <div class="pagination">
            <button @click="navPage(-1)" v-if="pages > 1">
              <ChevronLeft />
            </button>
            <span>
              {{ currentSummary }}
            </span>
            <button @click="navPage(1)" v-if="pages > 1">
              <ChevronRight />
            </button>
          </div>
        </div>
      </div>
      <div class="data-table" :class="{ selecting: data.selected.length > 0 }">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Item</th>
              <th>Identity</th>
              <th>Deleted On</th>
              <th>URL</th>
              <th>Gone In</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in data.trash"
              :key="index"
              :class="{
                selected: isSelected(item),
                'can-select': canSelect(item),
              }"
            >
              <td>
                <button @click="toggleSelect(item)">
                  <Checkmark />
                </button>
              </td>
              <td>
                <div class="item-value">
                  <input
                    readonly="true"
                    :type="shouldShowItem(item) ? 'text' : 'password'"
                    :value="item.item"
                  />
                  <div class="hover-reveal">
                    <UiTooltip
                      title="Show/Hide value"
                      position="top"
                      align-x="center"
                      v-if="isPasswordObject(item)"
                    >
                      <button @click="toggleShowItem(item)">
                        <Eye v-if="shouldShowItem(item)" />
                        <EyeSlash v-else />
                      </button>
                    </UiTooltip>
                    <UiTooltip
                      v-if="shouldCopy(item)"
                      :title="item.title || 'copy'"
                      position="top"
                      align-x="center"
                    >
                      <button @click="copyItem(item)">
                        <CopyIcon />
                      </button>
                    </UiTooltip>
                  </div>
                </div>
              </td>
              <td class="identity-name">
                <img
                  :src="item.logo"
                  class="identity-logo"
                  v-if="item.logo"
                  width="24"
                  height="24"
                />
                <span>{{ item.identity_name }}</span>
              </td>
              <td>{{ dateFormat(item.created_at) }}</td>
              <td>{{ domainOnly(item.website_url) }}</td>
              <td>{{ deletesIn(item.created_at) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="trash-empty" v-else>
      <div>
        <TrashIcon />
        <h3>There’s nothing here yet.</h3>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
input[type="checkbox"] {
  all: unset;
}
.trash-container {
  height: 100%;
}
.select-all-button {
  display: block !important;
  padding: 0 !important;
  height: 25px;
  svg {
    color: $color-primary-100 !important;
    rect {
      color: $color-primary-0 !important;
    }
    path {
      color: $color-primary-0 !important;
    }
  }
}
.trash-data-header {
  color: $color-primary-100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px;
  .actions {
    > div {
      display: flex;
      gap: 5px;
      button {
        color: $color-primary-100;
        svg {
          color: $color-primary-100;
          fill: $color-primary-100;
          path {
            fill: $color-primary-100;
          }
          svg {
            transform: scale(0.7);
          }
        }
        &.disabled {
          opacity: 0.5;
        }
        &.delete-button {
          &:hover {
            background-color: $color-alert-tint;
            color: $color-alert !important;
            svg {
              path {
                fill: $color-alert !important;
              }
            }
          }
          svg {
            transform: scale(0.6);
          }
        }
      }
    }
  }
  button {
    padding: 2px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border: none;
    border-radius: 8px;
    background-color: transparent;
    cursor: pointer;
    &:disabled {
      svg {
        opacity: 0.3;
      }
    }
    &:hover {
      background-color: $color-primary-5;
    }
  }
  .trash-type-select {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.1px;
    select {
      color: $color-primary-100;
      background-color: transparent;
      border: none;
    }
  }
  .right {
    display: flex;
    gap: 10px;
    span {
      color: #8c8e91;
      font-size: 12px;
    }
  }
}
.trash-info {
  color: $color-primary-100;
  border-bottom: 1px solid $color-primary-10;
  background-color: $color-primary-5;
  padding: 16px 24px;
  align-items: center;
  gap: 10px;
  display: flex;
  justify-content: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.1px;
  .empty-trash-icon {
    button {
      cursor: pointer;
      color: $color-primary-100;
      padding: 0;
      position: relative;
      border: none;
      background-color: transparent;
      display: inline-flex;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.1px;
      text-decoration-line: underline;
    }
  }
}

.data-table {
  color: $color-primary-100;
  padding: 0 24px;
  &.selecting {
    tbody {
      tr {
        opacity: 0.3;
        &.selected,
        &.can-select {
          opacity: 1;
        }
      }
    }
  }
  table {
    width: 100%;
    border-spacing: 12px;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.1px;
    border-collapse: collapse;

    thead {
      font-size: 13px;
      font-weight: 600;
      th {
        text-align: left;
      }
    }
    tbody {
      position: relative;
      tr {
        border-radius: 10px;
        &:first-child {
          &:after {
            background: transparent;
          }
        }
        &:after {
          content: "";
          background: $color-primary-5;
          position: absolute;
          left: 1%;
          height: 1px;
          width: 98%;
        }
        &:hover {
          background-color: $color-primary-5;
          td {
            .item-value {
              .hover-reveal {
                opacity: 1;
              }
            }
          }
        }
        &.selected {
          font-weight: 500;
          &:hover {
            background-color: $color-primary-5;
          }
          td {
            font-weight: 500 !important;
            &:first-child {
              button {
                background-color: $color-primary-100;
                color: $color-primary-0;
                svg {
                  display: block;
                }
              }
            }
            .item-value {
              input[type="text"] {
                font-weight: 500;
              }
            }
          }
        }
        td {
          font-size: 13px;
          .item-value {
            display: flex;
            gap: 10px;
            justify-content: center;
            margin: 0;
            padding: 0 10px 0 0;

            .hover-reveal {
              display: flex;
              opacity: 0;
              gap: 5px;
              justify-content: center;
              align-items: center;
            }
            button {
              color: $color-primary-100;
              background-color: transparent;
              border: none;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 0;
              padding: 0;
            }
            input {
              background-color: transparent;
              border: none;
              color: $color-primary-100;
              height: 100%;
              width: 100%;
              margin: 0;
              padding: 0;
            }
          }
          &.identity-name {
            .identity-logo {
              border-radius: 24px;
              width: 24px;
              height: 24px;
              padding: 0 !important;
              margin-right: 10px;
            }
          }
          padding: 15px 10px;
          &:last-child {
            padding-right: 15px;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
          }
          &:first-child {
            padding-left: 15px;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            button {
              cursor: pointer;
              background-color: transparent;
              border: 1px solid $color-primary-100;
              color: $color-primary-100;
              border-radius: 50px;
              height: 28px;
              width: 28px;
              transform: scale(0.7);
              display: flex;
              justify-content: center;
              align-items: center;
              svg {
                transform: scale(1.2);
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.pagination {
  padding: 24px;
  display: flex;
  justify-content: space-between;
  button {
    cursor: pointer;
    background-color: transparent;
    border: none;
    color: $color-primary-100;
  }
  .left {
    display: flex;
    gap: 18px;
  }
  .right {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: $color-primary-50;
    display: flex;
    gap: 16px;
    align-items: center;
  }
}

.trash-empty {
  color: $color-primary-100;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 200px);
  gap: 10px;
  padding-top: 50%;
  transform: translateY(-50%);
  > div {
    text-align: center;
    max-width: 200px;
    h3 {
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.1px;
    }
    p {
      color: $color-primary-70;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    svg {
      transform: scale(1.2);
    }
  }
}
</style>
